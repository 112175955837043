import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import StyledButton from '../../../../components/Common/StyledButton';
import { StyledEditor } from '../../../../components/Common/StyledEditor';
import { StyledLoading } from '../../../../components/Common/StyledLoading';
import * as CS from '../styles';
import * as S from './styles';
import { ITermsAndConditionsStep } from './types';
import { useTermsAndConditions } from './useTermsAndConditions';

export function TermsAndConditionsStep({
  bidId,
  onCancel,
  nextStep,
  previousStep,
}: ITermsAndConditionsStep) {
  const { t } = useTranslation();
  const {
    html,
    setHTML,
    saveTermsAndConditions,
    isLoading,
    setCountText,
    countText,
    initialValue,
  } = useTermsAndConditions(bidId);

  return (
    <>
      {isLoading ? (
        <StyledLoading height={10} />
      ) : (
        <S.Container>
          <S.Content>
            <StyledEditor
              placeholder={t('pages.newRfq.steps.termsAndConditionsStep.termsPlaceholder')}
              editorName="termsAndConditionsStep"
              maxLength={1300}
              value={initialValue}
              onChangeHTML={setHTML}
              countCharacters={setCountText}
            />
          </S.Content>
        </S.Container>
      )}
      <CS.StepFooter>
        <StyledButton variant="slim" onClick={onCancel}>
          {t('common.cancel')}
        </StyledButton>

        <Flex gap="1rem">
          <StyledButton variant="secondary" onClick={previousStep}>
            {t('common.previous')}
          </StyledButton>
          <StyledButton
            variant="primary"
            onClick={() => {
              saveTermsAndConditions().then(() => {
                nextStep();
              });
            }}
            disabled={!html || countText < 10}
            htmlType="submit"
            data-cy="rfq-terms-next-button"
          >
            {t('common.next')}
          </StyledButton>
        </Flex>
      </CS.StepFooter>
    </>
  );
}
