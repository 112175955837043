import { t } from 'i18next';
import { Button, Flex, Form, Input, InputNumber, Row, Select } from 'antd';
import { useEffect } from 'react';
import StyledButton from '../../../components/Common/StyledButton';
import { useStepOneComponentRegister } from './useStepOneComponentRegister';
import * as S from './styles';
import { StyledLoading } from '../../../components/Common/StyledLoading';
import StyledModal from '../../../components/Common/StyledModal';
import { ContentModalNewComponent } from './ContentModalNewComponent';
import { StepOneProps } from './types';
import { Loading } from '../../../components/Loading';

export function StepOneComponentRegister({
  setRegisterComponentId,
  setStep,
  registerComponentId,
}: StepOneProps) {
  const {
    handleRenderNewComponents,
    handleVerifyIfAddComponentMustBeDisabled,
    handleAddNewComponent,
    handleSaveNewComponent,
    handleGetFiltersData,
    setCategorySelected,
    handleSaveComponents,
    handleVerifYIfCanGoNextStep,
    handleCloseModal,
    handleLoadPreviousInfo,
    handleUpdateComponents,
    handleCancel,
    setProcessTypeSelected,
    validateQualificationCost,
    processTypeSelected,
    visibleModal,
    categorySelected,
    categories,
    cms,
    processType,
    products,
    projects,
    isLoading,
    form,
    formModal,
  } = useStepOneComponentRegister();

  useEffect(() => {
    handleGetFiltersData();
  }, [categorySelected]);

  useEffect(() => {
    registerComponentId && handleLoadPreviousInfo();
  }, []);

  return (
    <>
      <S.ContentForm>
        {isLoading ? (
          <Loading />
        ) : (
          <Form
            form={form}
            layout="vertical"
            onFinish={() => {
              if (registerComponentId) {
                handleUpdateComponents(setRegisterComponentId, setStep, registerComponentId);
              } else {
                handleSaveComponents(setRegisterComponentId, setStep);
              }
            }}
          >
            <Flex vertical gap={20}>
              <S.BasicInformationContainer>
                <Flex wrap gap={16}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: `${t(
                          'pages.requesRegistration.components.stepOne.labels.idQualification'
                        )} ${t('common.validate.isRequired')}`,
                      },
                      {
                        min: 2,
                        message: `${t(
                          'pages.requesRegistration.components.stepOne.labels.idQualification'
                        )} ${t(
                          'pages.requesRegistration.components.stepTwo.errorMessages.minLength',
                          { length: 2 }
                        )}`,
                      },
                    ]}
                    name="qualificationName"
                    label={t('pages.requesRegistration.components.stepOne.labels.idQualification')}
                  >
                    <Input
                      maxLength={70}
                      style={{ minWidth: '16rem' }}
                      placeholder={t(
                        'pages.requesRegistration.components.stepOne.placeHolders.description'
                      )}
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: `${t(
                          'pages.requesRegistration.components.stepOne.labels.processType'
                        )} ${t('common.validate.isRequired')}`,
                      },
                    ]}
                    name="processType"
                    label={t('pages.requesRegistration.components.stepOne.labels.processType')}
                  >
                    <Select
                      style={{ minWidth: '16rem' }}
                      options={processType}
                      onChange={(value) => {
                        setProcessTypeSelected(value);
                      }}
                      placeholder={t('pages.requesRegistration.components.stepOne.labels.select')}
                    />
                  </Form.Item>
                  {processTypeSelected === 'New Component' && (
                    <Form.Item
                      rules={[
                        {
                          validator: validateQualificationCost,
                        },
                      ]}
                      name="qualificationCost"
                      label={t(
                        'pages.requesRegistration.components.stepOne.labels.qualificationCost'
                      )}
                    >
                      <InputNumber
                        precision={2}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        suffix={<S.SuffixIcon />}
                        style={{ minWidth: '11rem' }}
                        placeholder={t(
                          'pages.requesRegistration.components.stepOne.placeHolders.addValue'
                        )}
                      />
                    </Form.Item>
                  )}
                </Flex>
                <S.ContainerSelects>
                  <Form.Item
                    style={{ width: '20%' }}
                    name="cm"
                    rules={[
                      {
                        required: true,
                        message: `${t('pages.requesRegistration.components.stepOne.labels.cm')} ${t(
                          'common.validate.isRequired'
                        )}`,
                      },
                    ]}
                    label={t('pages.requesRegistration.components.stepOne.labels.cm')}
                  >
                    <Select
                      options={cms}
                      placeholder={t('pages.requesRegistration.components.stepOne.labels.select')}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ width: '20%' }}
                    rules={[
                      {
                        required: true,
                        message: `${t(
                          'pages.requesRegistration.components.stepOne.labels.product'
                        )} ${t('common.validate.isRequired')}`,
                      },
                    ]}
                    name="product"
                    label={t('pages.requesRegistration.components.stepOne.labels.product')}
                  >
                    <Select
                      options={products}
                      placeholder={t('pages.requesRegistration.components.stepOne.labels.select')}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ width: '30%' }}
                    name="category"
                    rules={[
                      {
                        required: true,
                        message: `${t(
                          'pages.requesRegistration.components.stepOne.labels.category'
                        )} ${t('common.validate.isRequired')}`,
                      },
                    ]}
                    label={t('pages.requesRegistration.components.stepOne.labels.category')}
                  >
                    <Select
                      showSearch
                      placeholder={t(
                        'pages.requesRegistration.components.stepOne.placeHolders.description'
                      )}
                      options={categories}
                      onChange={(value) => {
                        setCategorySelected(value);
                      }}
                      suffixIcon={<S.StyledIconSearch />}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ width: '30%' }}
                    name="project"
                    rules={[
                      {
                        required: true,
                        message: `${t(
                          'pages.requesRegistration.components.stepOne.labels.project'
                        )} ${t('common.validate.isRequired')}`,
                      },
                    ]}
                    label={t('pages.requesRegistration.components.stepOne.labels.project')}
                  >
                    <Select
                      notFoundContent={<StyledLoading height={4} />}
                      showSearch
                      options={projects}
                      suffixIcon={<S.StyledIconSearch />}
                      placeholder={t('pages.requesRegistration.components.stepOne.labels.select')}
                    />
                  </Form.Item>
                </S.ContainerSelects>
              </S.BasicInformationContainer>
              <S.ContainerCards>
                {handleRenderNewComponents()}
                <StyledButton
                  onClick={handleAddNewComponent}
                  variant="dashed"
                  disabled={handleVerifyIfAddComponentMustBeDisabled()}
                >
                  + {t('pages.newRfq.steps.registerComponents.addComponent')}
                </StyledButton>
              </S.ContainerCards>
              <S.StepFooter>
                <StyledButton variant="slim" onClick={handleCancel}>
                  {t('common.cancel')}
                </StyledButton>

                <StyledButton
                  variant="primary"
                  htmlType="submit"
                  disabled={!handleVerifYIfCanGoNextStep()}
                >
                  {t('common.next')}
                </StyledButton>
              </S.StepFooter>
            </Flex>
          </Form>
        )}
      </S.ContentForm>
      {visibleModal && (
        <StyledModal
          key="modal-newcomponent"
          title={t('pages.requesRegistration.modalNewComponent.title')}
          open={visibleModal}
          onClose={handleCloseModal}
          footer={
            <Row justify="space-between">
              <Button type="link" onClick={handleCloseModal}>
                {t('common.cancel')}
              </Button>
              <Button type="primary" onClick={handleSaveNewComponent}>
                {t('common.save')}
              </Button>
            </Row>
          }
          body={
            <ContentModalNewComponent
              categories={categories}
              cms={cms}
              products={products}
              form={formModal}
            />
          }
          width="64rem"
        />
      )}
    </>
  );
}
