import { Flex, Tooltip } from 'antd';
import { t } from 'i18next';
import React from 'react';
import { addClassification, handleFormatValueFields } from '../../../../helpers/nUtils';
import * as S from './styles';
import { IClassification, impactSavingProps } from './types';

export function useSupplierResponseCard() {
  const renderImpactSaving = ({
    impactSavingNet,
    impactSavingNetPercentage,
    isDeclined,
  }: impactSavingProps): React.ReactNode => {
    return !isDeclined ? (
      <Flex align="center" justify="space-between">
        <S.Span isSemiBold>{t('pages.viewRfq.openCard.impactSaving')}</S.Span>
        <Flex align="center" gap={8}>
          <S.Percentage isBold>
            {impactSavingNetPercentage ? `${impactSavingNetPercentage}%` : '-'}
          </S.Percentage>
          {impactSavingNet ? (
            <Tooltip
              title={
                impactSavingNet
                  ? `$ ${handleFormatValueFields({
                      number: impactSavingNet,
                    })}`
                  : '-'
              }
            >
              <S.ImpactValue value={Number(impactSavingNet) || 0}>
                ${' '}
                {handleFormatValueFields({
                  number: impactSavingNet,
                  minFractional: 1,
                  maxFactional: 2,
                  notation: 'compact',
                })}
              </S.ImpactValue>
            </Tooltip>
          ) : (
            '-'
          )}
        </Flex>
      </Flex>
    ) : null;
  };
  const renderClassification = ({ classification, isDeclined }: IClassification) => {
    if (!isDeclined) {
      return classification ? addClassification(String(classification)) : '-';
    }
    return '0';
  };
  return { renderClassification, renderImpactSaving };
}
