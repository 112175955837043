import { Row } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { HttpStatusCode } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAlert } from '../../../context/AlertContext';
import { getURI } from '../../../helpers/utils';
import { api } from '../../../services/api';
import * as S from './styles';
import { SpecTag } from './styles';
import { ColumnsTax, TaxData } from './types';

export function useModalTax(
  componentType: string,
  period?: string,
  spec?: string,
  componentTab?: 'price' | 'packing'
) {
  const { openAlert } = useAlert();
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<ColumnsTax>();
  const [hasData, setHasData] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const handleFecthData = async () => {
    setLoading(true);
    try {
      const { data, status }: { data: TaxData; status: HttpStatusCode } = await api.get(
        getURI('/taxRateSettings', { componentType, period, spec })
      );
      if (status === 200) {
        setHasData(true);
        setTableData({
          ...data.content[0],
          ipi: data.content[0].taxDto.taxesIpi,
          icms: data.content[0].taxDto.taxesIcms,
          pisCofins: data.content[0].taxDto.taxesPisCofins,
          recoveryIpi: data.content[0].recoveryTaxDto.recoveryTaxesIpi,
          recoveryIcms: data.content[0].recoveryTaxDto.recoveryTaxesIcms,
          recoveryPisCofins: data.content[0].recoveryTaxDto.recoveryTaxesPisCofins,
          RD: data.content[0].recoveryTaxDto.taxesRD,
          period: `${data.content[0].periodFrom} / ${data.content[0].periodTo}`,
          taxes: data.content[0].taxes,
        });
      }
      if (status === 204) {
        setHasData(false);
      }
    } catch (error: any) {
      console.log(error);
      openAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<ColumnsTax> = [
    {
      title: () => {
        return (
          <S.ColumnHeader>{t('pages.componentsGroup.modalTax.table.componentType')}</S.ColumnHeader>
        );
      },
      dataIndex: 'componentType',
      align: 'left',
      render: (_, record) => {
        if (componentType === 'PACKING') {
          const totalTaxes = record.taxes && record.taxes.length - 1;

          return (
            <Row style={{ gap: '1rem' }}>
              {componentType}
              {record.taxes && (
                <Row>
                  <SpecTag color="blue">{record.taxes[0]}</SpecTag>
                  {totalTaxes > 0 && <SpecTag color="blue">+{totalTaxes}</SpecTag>}
                </Row>
              )}
            </Row>
          );
        }
        return <span>{componentType}</span>;
      },
    },
    {
      title: () => {
        return <S.ColumnHeader>{t('pages.componentsGroup.modalTax.table.from')}</S.ColumnHeader>;
      },
      dataIndex: 'from',
      key: 'from',
      align: 'left',
    },
    {
      title: () => {
        return <S.ColumnHeader>{t('pages.componentsGroup.modalTax.table.to')}</S.ColumnHeader>;
      },
      dataIndex: 'to',
      key: 'to',
      align: 'left',
    },
    {
      title: () => {
        return <S.ColumnHeader>{t('pages.componentsGroup.modalTax.table.ipi')}</S.ColumnHeader>;
      },
      dataIndex: 'ipi',
      key: 'ipi',
      align: 'right',
      width: '5rem',
      render: (value: number) => {
        return <S.PercentData>{value}</S.PercentData>;
      },
    },
    {
      title: () => {
        return <S.ColumnHeader>{t('pages.componentsGroup.modalTax.table.icms')}</S.ColumnHeader>;
      },
      dataIndex: 'icms',
      key: 'icms',
      align: 'right',
      width: '5rem',
      render: (value: number) => {
        return <S.PercentData>{value}</S.PercentData>;
      },
    },
    {
      title: () => {
        return (
          <S.ColumnHeader>{t('pages.componentsGroup.modalTax.table.pisCofins')}</S.ColumnHeader>
        );
      },
      dataIndex: 'pisCofins',
      key: 'pisCofins',
      align: 'right',
      width: '6.5rem',
      render: (value: number) => {
        return <S.PercentData>{value}</S.PercentData>;
      },
    },
    {
      title: () => {
        return (
          <S.ContainerHeaderRecovery>
            <S.HeaderRecovery>
              {t('pages.componentsGroup.modalTax.table.recoverable')}
            </S.HeaderRecovery>
            <S.ColumnHeader>{t('pages.componentsGroup.modalTax.table.ipi')}</S.ColumnHeader>
          </S.ContainerHeaderRecovery>
        );
      },
      dataIndex: 'recoveryIpi',
      key: 'recoveryIpi',
      align: 'right',
      width: '6.5rem',
    },
    {
      title: () => {
        return (
          <S.ContainerHeaderRecovery>
            <S.HeaderRecovery>
              {t('pages.componentsGroup.modalTax.table.recoverable')}
            </S.HeaderRecovery>
            <S.ColumnHeader>{t('pages.componentsGroup.modalTax.table.icms')}</S.ColumnHeader>
          </S.ContainerHeaderRecovery>
        );
      },
      dataIndex: 'recoveryIcms',
      key: 'recoveryIcms',
      align: 'right',
      width: '6.5rem',
    },
    {
      title: () => {
        return (
          <S.ContainerHeaderRecovery>
            <S.HeaderRecovery>
              {t('pages.componentsGroup.modalTax.table.recoverable')}
            </S.HeaderRecovery>
            <S.ColumnHeader>{t('pages.componentsGroup.modalTax.table.pisCofins')}</S.ColumnHeader>
          </S.ContainerHeaderRecovery>
        );
      },
      dataIndex: 'recoveryPisCofins',
      key: 'recoveryPisCofins',
      align: 'right',
      width: '6.5rem',
    },
    {
      title: () => {
        return (
          <S.ContainerHeaderRecovery>
            <S.HeaderRecovery>
              {t('pages.componentsGroup.modalTax.table.recoverable')}
            </S.HeaderRecovery>
            <S.ColumnHeader>{t('pages.componentsGroup.modalTax.table.rd')}</S.ColumnHeader>
          </S.ContainerHeaderRecovery>
        );
      },
      dataIndex: 'RD',
      key: 'RD',
      align: 'right',
      width: '6.5rem',
    },
    {
      title: () => {
        return <S.ColumnHeader>{t('pages.componentsGroup.modalTax.table.period')}</S.ColumnHeader>;
      },
      dataIndex: 'period',
      key: 'period',
      align: 'center',
    },
  ];

  return {
    columns,
    tableData,
    loading,
    hasData,
    handleFecthData,
  };
}
