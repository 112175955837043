import { UserOutlined } from '@ant-design/icons';
import { Divider, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { StyledLoading } from '../../components/Common/StyledLoading';
import StyledSelect from '../../components/Common/StyledSelect';
import { QualificationStatus } from '../../components/Qualification/QualificationStatus';
import { getSupplierUserName } from '../../helpers/nUtils';
import * as S from './styles';
import { useQualificationSupplierView } from './useQualificationSupplierView';

export function QualificationSupplierView() {
  const { t } = useTranslation();
  const {
    qualificationData,
    qualificationOption,
    selectedQualification,
    isLoadingQualification,
    isLoadingTrackingNumber,
    isLoadingSubmitFile,
    uploadProgress,
    isLoadingStatus,
    steps,
    handleRenderContent,
    setSelectedQualification,
    saveTrackingNumber,
    saveFiles,
  } = useQualificationSupplierView();

  return (
    <Flex vertical>
      <S.Header>
        <Flex align="center">
          <S.Title>{t('pages.qualificationSupplierView.title')}</S.Title>
          <Divider type="vertical" />
          <S.SubTitle>{selectedQualification}</S.SubTitle>
        </Flex>

        <Flex align="center" justify="center" gap={4}>
          <S.SupplierSpan>{getSupplierUserName()?.supplier || '-'}</S.SupplierSpan>
          <S.UserContainer>
            <UserOutlined />
            <span>{getSupplierUserName()?.user || '-'}</span>
          </S.UserContainer>
        </Flex>
      </S.Header>
      <S.SelectContainer>
        <StyledSelect
          label={t('pages.qualificationSupplierView.qualificationId')}
          options={qualificationOption}
          value={selectedQualification}
          onChange={(value: string) => setSelectedQualification(value)}
          styles={{ width: '12rem' }}
        />
      </S.SelectContainer>

      {isLoadingStatus ? <StyledLoading height={10} /> : <QualificationStatus data={steps} />}

      {isLoadingQualification ? <StyledLoading height={30} /> : handleRenderContent()}
    </Flex>
  );
}
