import {
  DollarOutlined,
  FileSearchOutlined,
  FolderViewOutlined,
  PercentageOutlined,
  TrophyOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Col, Divider, Row, Tour } from 'antd';
import { t } from 'i18next';
import { useHistory } from 'react-router-dom';
import target from '../../assets/target_line.svg';
import { getSupplierUserName } from '../../helpers/nUtils';
import { routesConfig } from '../../routes/config';
import { getPermissions } from '../../services/auth';
import theme from '../../styles/theme';
import CardMenus from './CardMenus';
import * as S from './styles';
import useHome from './useHome';

export function Home() {
  const {
    refRFQSupplier,
    refQualificationSupplier,
    openTour,
    stepsSupplier,
    stepsCM,
    module,
    refFinance,
    refMaterial,
    refPPB,
    refRFQAwards,
    refStrategy,
    saveTour,
    handleSetModule,
    handleRenderCardsPages,
  } = useHome();
  const history = useHistory();
  const permissions = getPermissions();

  return (
    <>
      <Col>
        <Row align="middle" justify="space-between">
          <S.SpanTitlePage>
            {permissions?.includes('homesupplier')
              ? t('pages.homeSupplier.title', { SupplierName: getSupplierUserName()?.user })
              : t('pages.homeCm.title', { CmName: getSupplierUserName()?.user })}
          </S.SpanTitlePage>
          <Row align="middle" style={{ gap: 4 }}>
            <S.SupplierSpan>{getSupplierUserName()?.supplier || '-'}</S.SupplierSpan>
            <S.UserContainer>
              <UserOutlined />
              <span>{getSupplierUserName()?.user || '-'}</span>
            </S.UserContainer>
          </Row>
        </Row>
      </Col>
      <S.ContainerCards>
        {/* Supplier View */}
        <CardMenus
          onClick={() => {
            const path = routesConfig.routes.find(
              (route) => route.route === 'bidsupplierview'
            )?.path;
            if (path) {
              history.push(path);
            }
          }}
          ref={refRFQSupplier}
          title={t('pages.homeSupplier.titleCards.rfqCard')}
          hidden={!permissions?.includes('homesupplier')}
        >
          <TrophyOutlined style={{ color: theme.colorsDesignSystem.text, fontSize: '3.5rem' }} />
        </CardMenus>
        <CardMenus
          onClick={() => {
            history.push('/qualification-supplier-view');
          }}
          ref={refQualificationSupplier}
          title={t('pages.homeSupplier.titleCards.qualificationCard')}
          hidden={!permissions?.includes('homesupplier')}
        >
          <FolderViewOutlined
            style={{ color: theme.colorsDesignSystem.text, fontSize: '3.5rem' }}
          />
        </CardMenus>
        {/* Cm View */}
        <CardMenus
          onClick={() => {
            handleSetModule('Finance');
          }}
          ref={refFinance}
          title={t('pages.homeCm.titleCards.financeCard')}
          hidden={!permissions?.includes('homecm')}
          setCard={module.Finance}
        >
          <DollarOutlined style={{ color: theme.colorsDesignSystem.text, fontSize: '3.5rem' }} />
        </CardMenus>
        <CardMenus
          onClick={() => {
            handleSetModule('Material');
          }}
          ref={refMaterial}
          title={t('pages.homeCm.titleCards.materialCard')}
          hidden={!permissions?.includes('homecm')}
          setCard={module.Material}
        >
          <FileSearchOutlined
            style={{ color: theme.colorsDesignSystem.text, fontSize: '3.5rem' }}
          />
        </CardMenus>
        <CardMenus
          onClick={() => {
            handleSetModule('PPB');
          }}
          ref={refPPB}
          title={t('pages.homeCm.titleCards.ppbCard')}
          hidden={!permissions?.includes('homecm')}
          setCard={module.PPB}
        >
          <img src={target} alt="PPB" height={57} width={57} />
        </CardMenus>
        <CardMenus
          onClick={() => {
            const path = routesConfig.routes.find(
              (route) => route.route === 'quotationfiles'
            )?.path;
            if (path) {
              history.push(path);
            }
          }}
          ref={refRFQAwards}
          title={t('pages.homeCm.titleCards.rfqAwardsCard')}
          hidden={!permissions?.includes('homecm')}
        >
          <TrophyOutlined style={{ color: theme.colorsDesignSystem.text, fontSize: '3.5rem' }} />
        </CardMenus>
        <CardMenus
          onClick={() => {
            handleSetModule('Strategy');
          }}
          ref={refStrategy}
          title={t('pages.homeCm.titleCards.strategyCard')}
          hidden={!permissions?.includes('homecm')}
          setCard={module.Strategy}
        >
          <PercentageOutlined
            style={{ color: theme.colorsDesignSystem.text, fontSize: '3.5rem' }}
          />
        </CardMenus>
      </S.ContainerCards>
      {permissions?.includes('homecm') && (
        <>
          <Divider orientation="left" orientationMargin="0">
            <S.PagesTitle>Pages</S.PagesTitle>
          </Divider>
          <S.ContainerPages>{handleRenderCardsPages()}</S.ContainerPages>
        </>
      )}

      <Tour
        open={openTour}
        onClose={() => saveTour(false)}
        steps={permissions?.includes('homesupplier') ? stepsSupplier : stepsCM}
      />
    </>
  );
}
