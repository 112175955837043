import { Divider, Form, FormInstance } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

export function CancelQualificationModal({ form }: { form: FormInstance }) {
  const { t } = useTranslation();
  return (
    <S.Container>
      <Divider orientation="left" orientationMargin="0">
        <S.Title>
          {t('pages.viewQualification.cancelQualificationMessage.ModalMessage.title')}
        </S.Title>
      </Divider>
      <S.Message
        dangerouslySetInnerHTML={{
          __html: t('pages.viewQualification.cancelQualificationMessage.ModalMessage.message'),
        }}
      />
      <Form form={form} name="cancel-qualification" layout="vertical">
        <Form.Item
          name="reason"
          rules={[
            {
              required: true,
              message: `${t(
                'pages.viewQualification.cancelQualificationMessage.ModalMessage.placeHolder'
              )}`,
            },
          ]}
        >
          <TextArea
            placeholder={t(
              'pages.viewQualification.cancelQualificationMessage.ModalMessage.placeHolder'
            )}
            autoSize={{ minRows: 3, maxRows: 5 }}
            showCount
            maxLength={150}
          />
        </Form.Item>
      </Form>
    </S.Container>
  );
}
