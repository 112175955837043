import { useState } from 'react';
import { t } from 'i18next';
import { Form } from 'antd';
import { useHistory } from 'react-router-dom';
import { RegisterNewComponent } from './RegisterNewComponent';
import { RegisterComponentData } from './RegisterNewComponent/types';
import { StyledSelectOptions } from '../../../components/Common/StyledSelect/types';
import { ModalNewComponent, ContentHeadersType, ComponentsToSend } from './types';
import { formatDataToSelect } from '../../../helpers/nUtils';
import { api } from '../../../services/api';
import { getURI, oneAlert } from '../../../helpers/utils';
import { services } from '../../../services/services';
import { RoutesPath } from '../../../routes/routesPath';

export function useStepOneComponentRegister() {
  const initialState = {
    description: '',
    isEditing: true,
    partNumber: '',
    specification: '',
    supplier: '',
    index: 1,
    qualificationName: '',
  };

  const [components, setComponents] = useState<Array<RegisterComponentData>>([initialState]);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState<Array<StyledSelectOptions>>([]);
  const [processType, setProcessType] = useState<Array<StyledSelectOptions>>([]);
  const [products, setProducts] = useState<Array<StyledSelectOptions>>([]);
  const [projects, setProjects] = useState<Array<StyledSelectOptions>>([]);
  const [cms, setCms] = useState<Array<StyledSelectOptions>>([]);
  const [categorySelected, setCategorySelected] = useState('');
  const [form] = Form.useForm<ContentHeadersType>();
  const [formModal] = Form.useForm<ModalNewComponent>();
  const [visibleModal, setVisibleModal] = useState(false);
  const [processTypeSelected, setProcessTypeSelected] = useState('');
  const history = useHistory();

  const handleDeleteComponent = (component: RegisterComponentData) => {
    setComponents((prevState) => {
      return prevState.filter((comp) => comp.index !== component.index);
    });
  };

  const handleSetComponentIsEditing = (component: RegisterComponentData) => {
    setComponents((prevState) => {
      return prevState.map((comp) =>
        comp.index === component.index ? { ...comp, isEditing: !component.isEditing } : comp
      );
    });
  };

  const handleValidateSinglePartNumber = (partNumber: string): boolean => {
    return components.some((item) => item.partNumber === partNumber);
  };

  const handleSaveComponent = (component: RegisterComponentData) => {
    if (handleValidateSinglePartNumber(component.partNumber)) {
      oneAlert('info', t('pages.requesRegistration.components.stepOne.message.partNumber'));
    } else {
      setComponents((prevState) => {
        return prevState.map((comp) =>
          comp.index === component.index
            ? {
                ...comp,
                description: component.description,
                isEditing: component.isEditing,
                partNumber: component.partNumber,
                specification: component.specification,
                supplier: component.supplier,
              }
            : comp
        );
      });
    }
  };

  const handleAddNewComponent = () => {
    setComponents((prevState) => {
      return [...prevState, { ...initialState, index: prevState.length + 1 }];
    });
  };

  const handleRenderNewComponents = () => {
    return components.map((component) => {
      return (
        <RegisterNewComponent
          deleteItem={handleDeleteComponent}
          editItem={handleSetComponentIsEditing}
          saveItem={handleSaveComponent}
          key={component.index}
          item={component}
          openModalAddNewItem={setVisibleModal}
        />
      );
    });
  };

  //validando se existe ao menos algum componente cadastrado
  const handleVerifYIfCanGoNextStep = () => {
    return components.some((component) => !component.isEditing);
  };

  const handleVerifyIfAddComponentMustBeDisabled = (): boolean => {
    return components.some((component) => !component.partNumber) || components.length === 15;
  };

  const handleSetValuesFromFilters = (data: ContentHeadersType) => {
    setCategories(formatDataToSelect(data.category || [], false));
    setProcessType(formatDataToSelect(data.processType || [], false));
    setProducts(formatDataToSelect(data.product || [], false));
    setProjects(formatDataToSelect(data.project || [], false));
    setCms(formatDataToSelect(data.cm || [], false));
  };

  const handleGetFiltersData = async () => {
    try {
      const { data, status } = await api.get(
        getURI(`${services.qualification}/component/header`, { category: categorySelected })
      );
      status === 200 && handleSetValuesFromFilters(data);
    } catch (error: any) {
      console.log(error);
      oneAlert('error', t('toast.errorOnList'));
    }
  };

  const handleCloseModal = () => {
    setVisibleModal(false);
  };

  const handleSaveNewComponent = async () => {
    const values = await formModal.validateFields();
    try {
      const { status } = await api.post('components', {
        spec: values.specification,
        partType: values.category,
        partNumber: values.partNumber,
        description: values.description,
        local: values.source === 'FOB' ? '0' : '1',
        subComponent: values.subComponents,
        leadTime: values.leadTime,
        cm: values.cm,
      });
      if (status === 201) {
        oneAlert('success', t('toast.successOnSave'));
        setVisibleModal(false);
      }
    } catch (error) {
      setVisibleModal(false);
      console.log(error);
      oneAlert('error', t('toast.errorOnSave'));
    }
  };

  const handleSaveComponents = async (
    setId: React.Dispatch<React.SetStateAction<string>>,
    setNextStep: React.Dispatch<React.SetStateAction<number>>
  ) => {
    try {
      debugger;
      const values = await form.validateFields();
      setIsLoading(true);
      const dataToSend = {
        category: values.category,
        qualificationName: values.qualificationName,
        cm: values.cm,
        processType: values.processType,
        product: values.product,
        project: values.project,
        qualificationCost: values.qualificationCost,
        components: components.map((component) => {
          return {
            description: component.description,
            id: component.index,
            partNumber: component.partNumber,
            specification: component.specification,
            supplier: component.supplier,
          };
        }),
      };

      const { status, data } = await api.post(
        `${services.qualification}/component/register`,
        dataToSend
      );

      if (status === 201) {
        setId(data.id);
        localStorage.setItem('registerComponent', JSON.stringify({ ...dataToSend, id: data.id }));
        setNextStep(1);
        oneAlert('success', t('toast.successOnSave'));
      }
    } catch (error) {
      console.log(error);
      oneAlert('error', t('toast.errorOnSave'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateComponents = async (
    setId: React.Dispatch<React.SetStateAction<string>>,
    setNextStep: React.Dispatch<React.SetStateAction<number>>,
    registerComponentId: string
  ) => {
    try {
      debugger;
      const values = await form.validateFields();
      setIsLoading(true);
      const dataToSend = {
        category: values.category,
        cm: values.cm,
        processType: values.processType,
        product: values.product,
        project: values.project,
        qualificationName: values.qualificationName,
        qualificationCost: values.qualificationCost,
        components: components.map((component) => {
          return {
            description: component.description,
            id: component.index,
            partNumber: component.partNumber,
            specification: component.specification,
            supplier: component.supplier,
          };
        }),
      };

      const { status, data } = await api.put(
        `${services.qualification}/component/${registerComponentId}`,
        dataToSend
      );

      if (status === 201) {
        setId(data.id);
        localStorage.setItem('registerComponent', JSON.stringify({ ...dataToSend, id: data.id }));
        setNextStep(1);
        oneAlert('success', t('toast.successOnSave'));
      }
    } catch (error) {
      console.log(error);
      oneAlert('error', t('toast.errorOnSave'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetDataFromStorage = () => {
    const storedData = localStorage.getItem('registerComponent');
    return storedData ? JSON.parse(storedData) : null;
  };

  const handleLoadPreviousInfo = () => {
    const data = handleGetDataFromStorage();
    debugger;
    setProcessTypeSelected(data.processType);
    form.setFieldsValue({
      category: data.category,
      cm: data.cm,
      processType: data.processType,
      product: data.product,
      project: data.project,
      qualificationName: data.qualificationName,
      qualificationCost: data.qualificationCost,
    });

    setComponents(
      data.components.map((component: ComponentsToSend, index: number) => {
        return {
          description: component.description,
          isEditing: false,
          partNumber: component.partNumber,
          specification: component.specification,
          supplier: component.supplier,
          index: index + 1,
        };
      })
    );
  };

  const handleCancel = () => {
    history.push(RoutesPath.qualification);
  };

  const validateQualificationCost = (_: any, value: number) => {
    if (!value) {
      return Promise.reject(
        new Error(
          `${t('pages.requesRegistration.components.stepOne.labels.qualificationCost')} ${t(
            'common.validate.isRequired'
          )}`
        )
      );
    }
    // Expressão regular para validar até 7 dígitos antes da vírgula e até 2 casas decimais
    const regex = /^\d{1,7}(\.\d{1,2})?$/;
    if (!regex.test(value.toString())) {
      return Promise.reject(
        new Error(
          `${t(
            'pages.requesRegistration.components.stepOne.errorMessages.invalidQualification'
          )} ${t('pages.requesRegistration.components.stepOne.labels.qualificationCost')} `
        )
      );
    }

    return Promise.resolve();
  };

  return {
    handleRenderNewComponents,
    handleVerifyIfAddComponentMustBeDisabled,
    handleDeleteComponent,
    handleAddNewComponent,
    handleSaveComponent,
    handleSetComponentIsEditing,
    handleGetFiltersData,
    setCategorySelected,
    handleCloseModal,
    handleSaveNewComponent,
    handleVerifYIfCanGoNextStep,
    handleSaveComponents,
    handleLoadPreviousInfo,
    handleUpdateComponents,
    handleCancel,
    setProcessTypeSelected,
    validateQualificationCost,
    visibleModal,
    processTypeSelected,
    formModal,
    categorySelected,
    isLoading,
    components,
    categories,
    cms,
    processType,
    products,
    projects,
    form,
  };
}
