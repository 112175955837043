import { handleFormatValueFields } from '../../../../../helpers/nUtils';
import theme from '../../../../../styles/theme';
import { CustomTooltipProps, DataGraphicProps } from '../types';
import * as S from './styles';

export function useGraphic(data: DataGraphicProps) {
  function highlightColor(valor: number, rgbString?: string) {
    if (rgbString) {
      const matches = rgbString?.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/);

      if (!matches) return rgbString;

      let redValue = Number(matches[1]);
      let greenValue = Number(matches[2]);
      let blueValue = Number(matches[3]);

      redValue = Math.min(255, redValue - valor);
      greenValue = Math.min(255, greenValue - valor);
      blueValue = Math.min(255, blueValue - valor);

      return `rgb(${redValue}, ${greenValue}, ${blueValue})`;
    }
  }

  const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
      return (
        <S.TooltipContainer>
          <p>{`${label}`}</p>
          {payload.map((entry, index: number) => {
            const formattedLabel = handleFormatValueFields({ number: entry.value });
            return (
              // eslint-disable-next-line react/no-array-index-key
              <p key={`item-${index}`} style={{ color: entry.color }}>
                {`$${formattedLabel}`}
              </p>
            );
          })}
        </S.TooltipContainer>
      );
    }
    return null;
  };

  const Customized = (props: any) => {
    const { payload, x, y } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={4} textAnchor="end" fill="#666">
          $
          {handleFormatValueFields({
            number: payload.value,
            minFractional: 1,
            maxFactional: 2,
            notation: 'compact',
          })}
        </text>
      </g>
    );
  };

  const verticalCoordinatesGenerator = ({ width, height, xAxis, yAxis }: any) => {
    const numberOfCategories = data.graphic.length;
    let interval = width / numberOfCategories;
    const coordinates = [];

    for (let i = 1; i < numberOfCategories; i++) {
      interval = i === 1 ? interval + 40 : interval - 40;
      coordinates.push(i * interval);
    }

    return coordinates;
  };

  const renderColorfulLegendText = (value: string, entry: any) => {
    return <span style={{ color: theme.colors.inputPlaceHolder }}>{value}</span>;
  };
  return {
    verticalCoordinatesGenerator,
    renderColorfulLegendText,
    CustomTooltip,
    Customized,
    highlightColor,
  };
}
