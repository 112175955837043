import { Form } from 'antd';
import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from '../../../../context/AlertContext';
import { api } from '../../../../services/api';
import { services } from '../../../../services/services';
import { IError, IStep1Form } from '../../types';

export function useRegisterBid(bidId?: string) {
  const { openAlert } = useAlert();
  const { t } = useTranslation();
  const [form] = Form.useForm<IStep1Form>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [showOutdateDateError, setShowOutdateDateError] = useState<boolean>(false);

  const save = async (values: IStep1Form): Promise<AxiosResponse<IStep1Form> | undefined> => {
    try {
      setIsLoadingSubmit(true);
      const body = {
        ...values,
        priceInterval: Number(values.priceInterval) || null,
        sketch: values.sketch || false,
        startDate: values.period ? dayjs(values.period[0]).format('YYYY/MM/DD HH:mm:ss') : null,
        endDate: values.period ? dayjs(values.period[1]).format('YYYY/MM/DD HH:mm:ss') : null,
      };
      if (bidId) {
        const response = await api.put(`${services.rfq}/bid/registerBid/${bidId}`, { ...body });
        return response;
      }
      const response = await api.post(`${services.rfq}/bid/registerBid/`, { ...body });
      return response;
    } catch (err) {
      const error = err as IError;
      openAlert('error', error.errorResponse.messageDetail || t('toast.errorOnSave'));
    } finally {
      setIsLoadingSubmit(false);
    }
  };

  const dateIsValid = (startDate: string) => {
    const formattedStartDate = dayjs(startDate);

    if (formattedStartDate.isBefore(dayjs())) {
      return false;
    }

    return true;
  };

  const getInformations = async (
    setCurrentStep: (step: number) => void,
    setOriginalBidId: (originalBid: string) => void,
    isFirstLoading: boolean,
    setIsFirstLoading: (loading: boolean) => void
  ) => {
    try {
      setIsLoading(true);
      const { data } = await api.get(`${services.rfq}/bid/registerBid/${bidId}`);
      form.setFields([
        {
          name: 'nameBid',
          value: data.step1.nameBid,
        },
        {
          name: 'description',
          value: data.step1.description,
        },
        {
          name: 'priceInterval',
          value: data.step1.priceInterval ? data.step1.priceInterval.toFixed(2) : null,
        },
        {
          name: 'attemptLimit',
          value: data.step1.attemptLimit,
        },
        {
          name: 'period',
          value:
            data.step1.startDate && data.step1.endDate
              ? [
                  data.step1.startDate ? dayjs(data.step1.startDate) : null,
                  data.step1.endDate ? dayjs(data.step1.endDate) : null,
                ]
              : null,
          errors: dateIsValid(data.step1.startDate)
            ? []
            : ["The BID's end date has expired. Please provide a valid date to proceed"],
        },
      ]);

      if (data.originalBidId) {
        return setOriginalBidId(data.originalBidId);
      }

      if (data.step1) {
        if (!dateIsValid(data.step1.startDate)) {
          return;
        }
      }
      if (isFirstLoading) {
        setIsFirstLoading(false);
        if (!data.step1) return setCurrentStep(0);
        if (!data.step2) return setCurrentStep(1);
        if (!data.step3) return setCurrentStep(2);
        if (!data.step4) return setCurrentStep(3);
        if (!data.step5) return setCurrentStep(4);
        if (!data.step6) return setCurrentStep(5);

        setCurrentStep(2);
      }
      return data;
    } catch (err) {
      const error = err as IError;
      openAlert('error', error.errorResponse.messageDetail || t('toast.errorOnList'));
    } finally {
      setIsLoading(false);
    }
  };

  return {
    form,
    save,
    getInformations,
    isLoading,
    isLoadingSubmit,
    dateIsValid,
    showOutdateDateError,
  };
}
