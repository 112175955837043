import { ContainerOutlined, CopyOutlined } from '@ant-design/icons';
import { Flex, Row } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StyledTabs from '../../../../components/Common/StyledTabs';
import { useAlert } from '../../../../context/AlertContext';
import { useFormaters } from '../../../../helpers/useFortmaters';
import { OdmsAssocieted } from '../../types';
import * as S from './styles';
import { OdmDetailsProps } from './types';
import { useODMDetails } from './useODMDetails';

export function ODMDetails({ odms }: OdmDetailsProps) {
  const { openAlert } = useAlert();
  const { t } = useTranslation();
  const { formatPhoneNumber, formatMobilePhoneNumber, formatZipCode } = useFormaters();
  const { tabs, setTabs, handleSetTabs, handleGetOdmSelected } = useODMDetails();
  const [selectedOdm, setSelectedOdm] = useState<OdmsAssocieted>();

  useEffect(() => {
    handleSetTabs(odms || []);
  }, [odms]);

  useEffect(() => {
    setSelectedOdm(handleGetOdmSelected(odms || []));
  }, [tabs]);

  return (
    <S.Container>
      <S.Header>
        <S.Padding>
          <ContainerOutlined />
          {t('pages.qualificationSupplierView.odmDetails.title')}
        </S.Padding>
      </S.Header>
      <S.Content>
        <StyledTabs tabs={tabs} setSelectedTab={setTabs} />
        <Flex gap={18}>
          <S.CardInfos className="small">
            <span> {t('pages.qualificationSupplierView.odmDetails.sampleQuantity')}</span>
            <span className="title">{selectedOdm?.sampleQuantity || '-'}</span>
          </S.CardInfos>
          <S.CardInfos className="normal">
            <span> {t('pages.qualificationSupplierView.odmDetails.sampleRequest')}</span>
            <span className="title">
              {dayjs(selectedOdm?.sampleRequest).format('MM/DD/YYYY') || '-'}
            </span>
          </S.CardInfos>
        </Flex>
        <S.StyledDivider />
        <Row>
          <S.Labels>
            {t('pages.qualificationSupplierView.odmDetails.odmContactInformation')}
          </S.Labels>
        </Row>
        <Row justify="space-between">
          <S.Labels className="subtitle">
            {t('pages.qualificationSupplierView.odmDetails.companyContactPerson')}
          </S.Labels>
          <S.Labels className="values">{selectedOdm?.companyContactPerson || '-'}</S.Labels>
        </Row>
        <Row justify="space-between">
          <Flex justify="center" align="center" gap={8}>
            <S.Labels className="subtitle">
              {t('pages.qualificationSupplierView.odmDetails.email')}
            </S.Labels>
            <CopyOutlined
              className="copySvg"
              onClick={() =>
                selectedOdm?.email &&
                navigator.clipboard.writeText(selectedOdm?.email).then(() => {
                  openAlert('info', t('common.copiedToClipboard'));
                })
              }
            />
          </Flex>
          <S.Labels className="values">{selectedOdm?.email || '-'}</S.Labels>
        </Row>
        <Row justify="space-between">
          <Flex justify="center" align="center" gap={8}>
            <S.Labels className="subtitle">
              {t('pages.qualificationSupplierView.odmDetails.mobileNumber')}
            </S.Labels>
            <CopyOutlined
              className="copySvg"
              onClick={() =>
                selectedOdm?.mobileNumber &&
                navigator.clipboard.writeText(selectedOdm?.mobileNumber).then(() => {
                  openAlert('info', t('common.copiedToClipboard'));
                })
              }
            />
          </Flex>
          <S.Labels className="values">
            {formatMobilePhoneNumber(selectedOdm?.mobileNumber || '-')}
          </S.Labels>
        </Row>
        <Row justify="space-between">
          <Flex justify="center" align="center" gap={8}>
            <S.Labels className="subtitle">
              {t('pages.qualificationSupplierView.odmDetails.phoneNumber')}
            </S.Labels>
            <CopyOutlined
              className="copySvg"
              onClick={() =>
                selectedOdm?.phoneNumber &&
                navigator.clipboard.writeText(selectedOdm?.phoneNumber).then(() => {
                  openAlert('info', t('common.copiedToClipboard'));
                })
              }
            />
          </Flex>
          <S.Labels className="values">
            {formatPhoneNumber(selectedOdm?.phoneNumber || '-')}
          </S.Labels>
        </Row>
        <S.StyledDivider />
        <Row>
          <S.Labels>
            {t('pages.qualificationSupplierView.odmDetails.sampleShippingAddress')}
          </S.Labels>
        </Row>
        <Row justify="space-between">
          <Flex justify="center" align="center" gap={8}>
            <S.Labels className="subtitle">
              {t('pages.qualificationSupplierView.odmDetails.zipCode')}
            </S.Labels>
            <CopyOutlined
              className="copySvg"
              onClick={() =>
                selectedOdm?.zipCode &&
                navigator.clipboard.writeText(selectedOdm?.zipCode).then(() => {
                  openAlert('info', t('common.copiedToClipboard'));
                })
              }
            />
          </Flex>
          <S.Labels className="values">{formatZipCode(selectedOdm?.zipCode || '-')}</S.Labels>
        </Row>
        <Row justify="space-between">
          <S.Labels className="subtitle">
            {t('pages.qualificationSupplierView.odmDetails.country')}
          </S.Labels>
          <S.Labels className="values">{selectedOdm?.country || '-'}</S.Labels>
        </Row>
        <Row justify="space-between">
          <Flex justify="center" align="center" gap={8}>
            <S.Labels className="subtitle">
              {t('pages.qualificationSupplierView.odmDetails.address')}
            </S.Labels>
            <CopyOutlined
              className="copySvg"
              onClick={() =>
                selectedOdm?.address &&
                navigator.clipboard.writeText(selectedOdm?.address).then(() => {
                  openAlert('info', t('common.copiedToClipboard'));
                })
              }
            />
          </Flex>
          <S.Labels className="values">{selectedOdm?.address || '-'}</S.Labels>
        </Row>
        <Row justify="space-between">
          <S.Labels className="subtitle">
            {t('pages.qualificationSupplierView.odmDetails.additionalInformation')}
          </S.Labels>
          <S.Labels className="values">{selectedOdm?.additionalInfo || '-'}</S.Labels>
        </Row>
      </S.Content>
    </S.Container>
  );
}
