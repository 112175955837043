import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChartConfig,
  LegendConfig,
  ThemeGraphic,
} from '../../../../components/Common/BarLineGraphic/types';
import { StyledSelectOptions } from '../../../../components/Common/StyledSelect/types';
import { useAlert } from '../../../../context/AlertContext';
import { getURI } from '../../../../helpers/utils';
import { api } from '../../../../services/api';
import {
  ChartCostTrendData,
  ComponenteData,
  CostTrendData,
  DataHeader,
  GraphicData,
  Params,
  SuppliersData,
} from './types';

export const useSupplierPriceCostTrend = ({
  level,
  project: headerProject,
  date,
}: ComponenteData) => {
  const { openAlert } = useAlert();
  const year = dayjs(date, 'MM/YYYY').format('YYYY');
  const initialParams: Params = {
    project: headerProject || '',
    componentGroup: 'SSD 512',
    specification: '',
    quarter: `${dayjs(date, 'MM/YYYY').quarter()}`,
  };
  const { t } = useTranslation();
  const [supplierPriceData, setSupplierPriceData] = useState<Array<SuppliersData>>([]);
  const [costTrendData, setCostTrendData] = useState<Array<ChartCostTrendData>>([]);
  const [componentGroup, setComponentGroup] = useState('');
  const [spec, setSpec] = useState('');
  const [optionsProjectName, setOptionsProjectName] = useState<Array<StyledSelectOptions>>([]);
  const [optionsComponentGroup, setOptionsComponentGroup] = useState<Array<StyledSelectOptions>>(
    []
  );
  const [optionsSpec, setOptionsSpec] = useState<Array<StyledSelectOptions>>([]);
  const [params, setParam] = useState(initialParams);
  const [years, setYears] = useState<string>('');

  const quarterOptions: Array<StyledSelectOptions> = [
    {
      label: 'Q1',
      value: '1',
    },
    {
      label: 'Q2',
      value: '2',
    },

    {
      label: 'Q3',
      value: '3',
    },
    {
      label: 'Q4',
      value: '4',
    },
  ];
  const themeChart: ThemeGraphic = {
    colorCurrentMonth: '#6CC08B',
    colorOthersMonths: '#6CC08B',
    colorLineChart: '#0C6DFF',
  };
  const labelChart: Array<LegendConfig> = [
    {
      text: 'Average',
      color: '#0C6DFF',
    },
    {
      text: 'Price Book',
      color: '#6CC08B',
    },
  ];
  const configChart: ChartConfig = {
    dataKeyBar: 'barValue',
    dataKeyLine: 'lineValue',
    labelColumn: 'labelColumn',
  };
  const handleCostTrendData = (costData: Array<CostTrendData>): Array<ChartCostTrendData> => {
    const chartDataCostTrend: Array<ChartCostTrendData> = [];
    if (costData.length !== 0) {
      costData.map((chartData: CostTrendData) => {
        chartDataCostTrend.push({
          barValue: chartData.priceBook,
          lineValue: chartData.average,
          labelColumn: chartData.month,
          isBlended: chartData.isBlended,
        });
        return chartDataCostTrend;
      });
    }
    return chartDataCostTrend;
  };

  const handleConvertOptions = (list: Array<any>, type = ''): Array<StyledSelectOptions> => {
    if (list && list.length) {
      const options = list.map((item: string) => {
        return {
          label: item,
          value: item,
        };
      });
      if (type === 'componentGroup' || type === 'projects') {
        return options;
      }
      options.unshift({ label: t('common.viewAll'), value: '' });
      return options;
    }
    return [];
  };

  const updateParams = (field: string, value: string) => {
    setParam((prevState) => {
      switch (field) {
        case 'project':
          return {
            ...prevState,
            [field]: value,
            specification: '',
          };
        case 'componentGroup':
          return {
            ...prevState,
            [field]: value,
            specification: '',
          };
        case 'specification':
          return {
            ...prevState,
            [field]: value,
          };
        case 'quarter':
          return {
            ...prevState,
            [field]: value,
          };

        default:
          return {
            ...prevState,
          };
      }
    });
  };

  const fetchSupplierPriceCostTrendData = async () => {
    try {
      const { data, status }: { data: GraphicData; status: number } = await api.get(
        getURI('dashboard/prices-vs-cost', {
          level: level.toLowerCase(),
          project: headerProject || params.project,
          component_group: params.componentGroup,
          spec: params.specification,
          quarter: `Q${params.quarter}/${year}`,
        })
      );
      if (status === 200) {
        setComponentGroup(data.componentGroup || '');
        setSpec(data.specification || '');
        setSupplierPriceData(data.supplierPrices);
        setCostTrendData(data.costTrend ? handleCostTrendData(data.costTrend) : []);
      }
      if (status === 204) {
        setSupplierPriceData([]);
        setCostTrendData([]);
      }
    } catch (error: any) {
      openAlert('error', error.message || t('toast.errorOnList'));
      console.error(error);
    }
  };

  const fetchHeaderSupplierPriceCostTrend = async () => {
    try {
      const { data, status }: { data: DataHeader; status: number } = await api.get(
        getURI('dashboard/prices-vs-cost/headers', {
          level: level.toLowerCase(),
          component_group: params.componentGroup,
          spec: params.specification,
          quarter: `Q${params.quarter}/${year}`,
        })
      );
      if (status === 200) {
        const projects = handleConvertOptions(data.projects || [], 'projects');
        const components = handleConvertOptions(data.componentGroups, 'componentGroup');
        if (params.project === '' && projects.length > 0) {
          setParam({ ...params, project: projects[0].label });
        }
        if (projects.length === 0) {
          setParam({ ...params, project: '' });
        }
        setOptionsProjectName(projects);
        setOptionsComponentGroup(components);
        setOptionsSpec(handleConvertOptions(data.specs || []));
      }
      if (status === 204) {
        setOptionsProjectName([]);
        setOptionsComponentGroup([]);
        setOptionsSpec([]);
      }
    } catch (error: any) {
      openAlert('error', error.message || t('toast.errorOnList'));
      console.error(error);
    }
  };

  return {
    componentGroup,
    spec,
    supplierPriceData,
    costTrendData,
    themeChart,
    labelChart,
    configChart,
    optionsProjectName,
    optionsComponentGroup,
    optionsSpec,
    quarterOptions,
    params,
    fetchSupplierPriceCostTrendData,
    fetchHeaderSupplierPriceCostTrend,
    updateParams,
  };
};
