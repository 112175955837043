import dayjs, { Dayjs } from 'dayjs';
import { abbreviatedMonths } from '../../../helpers/utils';
import * as S from './styles';

export function useMonths() {
  const handleVerifyIfMonthIsCurrent = (date: dayjs.Dayjs, month: string): boolean => {
    return date.locale('en').format('MMM').toUpperCase() === month.toUpperCase();
  };

  const handleRenderMonths = (
    currentDate: dayjs.Dayjs,
    onClick: (selectedDate: dayjs.Dayjs, filterType: 'DAY' | 'MONTH' | 'YEAR') => void
  ) => {
    return abbreviatedMonths.map((month, index) => (
      <S.MonthItem
        onClick={() => {
          onClick(currentDate.month(index), 'MONTH');
        }}
        key={month}
        isCurrent={handleVerifyIfMonthIsCurrent(currentDate, month)}
      >
        <span>{month}</span>
      </S.MonthItem>
    ));
  };

  return {
    handleRenderMonths,
  };
}
