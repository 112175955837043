import { SyncOutlined } from '@ant-design/icons';
import { App, notification } from 'antd';
import { createContext, useContext, useMemo } from 'react';
import theme from '../../styles/theme';
import { IAlertProvider, NotificationType } from './types';

interface IAlertContect {
  openAlert: (type: NotificationType, message: string) => void;
}

const AlertContext = createContext<IAlertContect>({} as IAlertContect);

function AlertProvider({ children }: IAlertProvider) {
  const [api, contextHolder] = notification.useNotification();

  const handleTheme = (type: NotificationType) => {
    const backGroundColor =
      type === 'error'
        ? theme.colorsDesignSystem.lightRed
        : type === 'info'
        ? theme.colorsDesignSystem.secondLightBlue
        : type === 'success'
        ? theme.colorsDesignSystem.lightPrimary
        : type === 'warning'
        ? theme.colorsDesignSystem.lightYellow
        : theme.colorsDesignSystem.white;

    const alertStyle = {
      width: '40.5rem',
      borderRadius: '0.5rem',
      fontSize: '1rem',
      lineHeight: '1.5rem',
      backgroundColor: backGroundColor,
      boxShadow: `0px 0px 20px 0px ${theme.colorsDesignSystem.placeholderText}`,
    };

    return alertStyle;
  };

  const openAlert = (type: NotificationType, message: string) => {
    if (type === 'loading') {
      return api.open({
        message,
        icon: <SyncOutlined style={{ color: theme.colors.blue, fontSize: '1.5rem' }} />,
        placement: 'top',
        closeIcon: false,
        style: handleTheme(type),
      });
    }

    return api.open({
      message,
      type,
      placement: 'top',
      closeIcon: false,
      style: handleTheme(type),
    });
  };

  const contextValue = useMemo(() => ({ openAlert }), [openAlert]);

  return (
    <App>
      {contextHolder}
      <AlertContext.Provider value={contextValue}>{children}</AlertContext.Provider>
    </App>
  );
}

function useAlert() {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within a AlertProvider');
  }
  return context;
}

export { AlertProvider, useAlert };
