import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import cancelFolder from '../../../assets/cancelFolder.svg';
import * as S from './styles';

export function CancelMessage({
  QualificationID,
  partNumber,
}: {
  QualificationID: string;
  partNumber: string;
}) {
  const { t } = useTranslation();
  return (
    <S.CancelBidContainer>
      <S.CancelBidHeader>
        {t('pages.viewQualification.cancelQualificationMessage.title')}
      </S.CancelBidHeader>
      <S.CancelBidContent>
        <S.CancelImage src={cancelFolder} alt="cancel" />
        <Flex gap={4}>
          <S.CancelMessage
            dangerouslySetInnerHTML={{
              __html: t('pages.viewQualification.cancelQualificationMessage.message', {
                QualificationID,
                partNumber,
              }),
            }}
          />
        </Flex>
        <S.CancelSubMessage
          dangerouslySetInnerHTML={{
            __html: t('pages.viewQualification.cancelQualificationMessage.subMessage'),
          }}
        />

        <S.CancelSubMessage>{t('pages.bidSupplierRFQ.canceBid.subMessage2')}</S.CancelSubMessage>
      </S.CancelBidContent>
    </S.CancelBidContainer>
  );
}
