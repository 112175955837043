import styled from 'styled-components';
import { Divider, Row } from 'antd';
import StyledInput from '../../../components/Common/StyledInput';

export const ContentForm = styled.div`
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  ::-webkit-scrollbar {
    width: 1.125rem;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.625rem 0.625rem ${({ theme }) => theme.colorsDesignSystem.grayBorder};
    border: solid 0.375rem transparent;
    border-radius: 624rem;
  }
  ::-webkit-scrollbar-thumb {
    background: transparent;
    box-shadow: inset 0 0 0.625rem 0.625rem ${({ theme }) => theme.colorsDesignSystem.placeHolder};
    border: solid 0.375rem transparent;
    -webkit-border-radius: 624rem;
    border-radius: 624rem;
  }
`;

export const StepFooter = styled(Row)`
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colorsDesignSystem.border};
  padding-top: 1rem;
  padding-inline: 2rem;
`;

export const ContainerCardsInside = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 1rem;
`;

export const PopUpContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 24rem;
  height: 7rem;
  padding: 1rem;
  gap: 1.5rem;
`;

export const ContainerPopConfirmTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;

  span {
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
  }
`;
