import { Divider } from 'antd';
import styled from 'styled-components';

export const container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1000 !important;
`;

export const Label = styled(Divider)`
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.text};
  margin: 0;
`;

export const logisticContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  gap: 1.5rem;
`;

export const labelsTaxesContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const taxesContainer = styled.div`
  display: flex;
  align-items: start;
  flex-direction: row;
  justify-content: space-between;
`;

export const onlyTaxesContainer = styled.div`
  display: flex;
  width: 40%;
  gap: 1.5rem;
  flex-direction: row;
`;

export const recoveryTaxesContainer = styled.div`
  display: flex;
  width: 60%;
  flex-direction: row;
  gap: 1.5rem;
`;

export const periodContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

export const buttonsContainer = styled.div`
  margin-top: 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.gray_border};
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
