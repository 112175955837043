/* eslint-disable no-undefined */
import { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TrackingTag } from '../../../../components/Qualification/TrackingTag';
import { useAlert } from '../../../../context/AlertContext';
import { TrackingTagEnum } from '../../../../helpers/enums';
import { ISampleTrackingNumber } from '../../types';
import * as S from './styles';

export function useSampleTrackingNumber() {
  const { openAlert } = useAlert();
  const { t } = useTranslation();
  const [isTrackingNumberModalOpen, setIsTrackingNumberModalOpen] = useState<{
    isModalOpen: boolean;
    trackingNumberData: ISampleTrackingNumber | undefined;
  }>({
    isModalOpen: false,
    trackingNumberData: undefined,
  });

  const columns: ColumnsType<ISampleTrackingNumber> = [
    {
      title: t('pages.viewQualification.sampleTrackingNumber.columnsTable.trackingNumber'),
      dataIndex: 'trackingNumber',
      key: 'trackingNumber',
      align: 'left',
      width: '15rem',
      render: (_, record) => {
        return (
          <S.TrackingNumber
            onClick={() =>
              setIsTrackingNumberModalOpen({
                isModalOpen: true,
                trackingNumberData: record,
              })
            }
          >
            {record.trackingNumber}
          </S.TrackingNumber>
        );
      },
    },
    {
      title: t('pages.viewQualification.sampleTrackingNumber.columnsTable.freightForwarder'),
      dataIndex: 'freightForwarder',
      key: 'freightForwarder',
      align: 'left',
      width: '10rem',
    },
    {
      title: t('pages.viewQualification.sampleTrackingNumber.columnsTable.odm'),
      dataIndex: 'odm',
      key: 'odm',
      align: 'left',
      width: '10rem',
    },
    {
      title: t('pages.viewQualification.sampleTrackingNumber.columnsTable.supplier'),
      dataIndex: 'supplier',
      key: 'supplier',
      align: 'left',
      width: '11.5rem',
    },
    {
      title: t('pages.viewQualification.sampleTrackingNumber.columnsTable.dateHour'),
      dataIndex: 'dateHour',
      key: 'dateHour',
      align: 'left',
      width: '13rem',
      sorter: true,
    },
    {
      title: t('pages.viewQualification.sampleTrackingNumber.columnsTable.responsibleSupplier'),
      dataIndex: 'resposibleSupplier',
      key: 'resposibleSupplier',
      align: 'left',
      width: '12rem',
    },
    {
      title: t('pages.viewQualification.sampleTrackingNumber.columnsTable.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: '10rem',
      render: (_, record) => {
        return <TrackingTag status={TrackingTagEnum[record.status]} />;
      },
    },
  ];

  return {
    columns,
    isTrackingNumberModalOpen,
    setIsTrackingNumberModalOpen,
  };
}
