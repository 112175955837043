import { Flex } from 'antd';
import styled from 'styled-components';
import { DetailsTrackingTagEnum, TrackingTagEnum } from '../../../../helpers/enums';
import { FontColorTags } from '../ModalTrackinNumberDetails/styles';

export const Content = styled(Flex)`
  width: 100%;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  border-radius: 0.5rem;
  gap: 2rem;
  align-items: center;

  img {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const TitleContent = styled(Flex)`
  width: 8.5rem;
  gap: 0.5rem;
  align-items: center;
`;

export const Title = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.1155rem;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
`;

export const DescriptionContent = styled(Flex)`
  flex-direction: column;
  text-align: right;
  flex: 1;
  align-items: center;
`;

export const Description = styled.span<{ size?: 'normal' | 'small' }>`
  font-size: ${({ size }) => (size === 'small' ? '0.625rem' : '0.75rem')};
  font-weight: 400;
  line-height: 1.1155rem;
  color: ${({ theme }) => theme.colorsDesignSystem.localColor};
  text-align: left;
  width: 100%;

  .local {
    width: 0.875rem;
    height: 0.875rem;
  }
`;

export const ContainerStep = styled.div<{ status: DetailsTrackingTagEnum }>`
  display: flex;
  flex-direction: column;
  width: 24.75rem;
  padding: 1rem;
  border: 1px solid ${({ status }) => FontColorTags[status]};
  border-radius: 0.5rem;
`;

export const Pipe = styled.div<{ status: DetailsTrackingTagEnum }>`
  display: flex;
  height: 1.5rem;
  width: 1px;
  background-color: ${({ status }) => FontColorTags[status]};
  margin-left: 1.5rem;
`;

export const ContainerFirstLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: space-between;
`;

export const Label = styled.span`
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 132%; /* 0.99rem */
  letter-spacing: -0.0135rem;
  color: ${({ theme }) => theme.colorsDesignSystem.qualificationTagColors.labelsGray};
`;
