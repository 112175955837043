import { Flex, Form, Row } from 'antd';
import { t } from 'i18next';
import TextArea from 'antd/es/input/TextArea';
import { useEffect } from 'react';
import * as S from './styles';
import { CardsSuppliersSendEmailProps } from './types';
import { StyledLoading } from '../../../../components/Common/StyledLoading';
import StyledSelect from '../../../../components/Common/StyledSelect';
import { useSupplierSendEmail } from './useSupplierSendEmail';
import StyledButton from '../../../../components/Common/StyledButton';
import { ContactUsers } from '../../../../services/types';
import { ContactTag } from '../../../NewRFQ/ContentSteps/Send/ContactTag';

export function CardSupplierSendEmail({
  item,
  onEdit,
  addContact,
  removeContact,
  sendContacts,
}: CardsSuppliersSendEmailProps) {
  const {
    fetchContactList,
    handleConvertContactListToStyledSelectOptions,
    setContactSelectedToInvite,
    handleSaveContactUserById,

    contactSelectedToInvite,
    loadingCard,
    loadingContact,
    form,
  } = useSupplierSendEmail();

  useEffect(() => {
    item.isOpen && fetchContactList(item.supplier);
  }, [item.isOpen]);

  useEffect(() => {
    item.id && form.setFieldValue('message', item.message);
  }, []);

  return (
    <S.Container status={item.status}>
      <S.Header>
        <Row style={{ gap: '1.5rem' }} align="middle">
          {item.isOpen ? (
            <S.ArrowUp
              isOpen={item.isOpen}
              onClick={() => {
                onEdit(item);
              }}
            />
          ) : (
            <S.ArrowDown
              isOpen={item.isOpen}
              onClick={() => {
                onEdit(item);
              }}
            />
          )}
          <S.Title>
            <b>{item.index?.toString().padStart(2, '0')} </b>
            {item.status ? (
              item.status === 'WAIT_SEND_EMAIL' ? (
                <S.StatusOK />
              ) : (
                <S.StatusWarning />
              )
            ) : null}
            {item.partNumber || '-'}
            <span className="soft">{`| ${item.supplier}`}</span>
          </S.Title>
        </Row>
      </S.Header>
      {!item.isOpen ? (
        <S.ContainerClosedCardContent>
          <S.Sumary>
            <Flex vertical align="center" style={{ gap: '0.5rem' }}>
              <S.Labels>{t('pages.requesRegistration.components.stepThree.labels.spec')}:</S.Labels>
              <S.Description>{item.specification || '-'}</S.Description>
            </Flex>
          </S.Sumary>
          <S.Sumary>
            <S.Label>
              <Flex vertical align="middle" style={{ gap: '0.5rem' }}>
                <S.Labels>
                  {t('pages.requesRegistration.components.stepThree.labels.description')}:
                </S.Labels>
                <S.Description hasIcon>{item.description || '-'}</S.Description>
              </Flex>
            </S.Label>
          </S.Sumary>
        </S.ContainerClosedCardContent>
      ) : (
        <Form
          form={form}
          onFinish={() => {
            sendContacts({ ...item, message: form.getFieldValue('message') });
          }}
        >
          {loadingCard ? (
            <StyledLoading height={15} />
          ) : (
            <S.ContainerCardContent>
              <S.InvitedMessageContainer>
                <StyledSelect
                  label="Registered Contacts"
                  styles={{ width: '20rem' }}
                  // disable={disableContact}
                  showSearch
                  value={contactSelectedToInvite?.name}
                  options={handleConvertContactListToStyledSelectOptions() || []}
                  onChange={(value: string) => {
                    handleSaveContactUserById(value, item);
                  }}
                />
                {contactSelectedToInvite && (
                  <S.InfoContactConteiner>
                    {loadingContact ? (
                      <StyledLoading height={15} />
                    ) : (
                      <>
                        <S.InputContainer>
                          <S.ContactNameInput
                            label={t('pages.newRfq.steps.send.contactName')}
                            value={contactSelectedToInvite?.name}
                            disabled
                          />
                          <S.EmailInput
                            label={t('pages.newRfq.steps.send.email')}
                            value={contactSelectedToInvite?.email}
                            disabled
                          />
                        </S.InputContainer>
                        <S.StyledButtonContainer>
                          <StyledButton
                            variant="slim"
                            onClick={() => {
                              setContactSelectedToInvite(null);
                            }}
                          >
                            {t('common.cancel')}
                          </StyledButton>
                          <StyledButton
                            variant="primary"
                            onClick={() => {
                              addContact(item, contactSelectedToInvite);
                              setContactSelectedToInvite(null);
                            }}
                          >
                            {t('common.save')}
                          </StyledButton>
                        </S.StyledButtonContainer>
                      </>
                    )}
                  </S.InfoContactConteiner>
                )}
                <S.TagContainer>
                  {item.contacts.map((contact: ContactUsers) => {
                    return (
                      <ContactTag
                        key={contact.id}
                        contact={contact.name}
                        supplier={item.supplier}
                        onDelete={() => {
                          removeContact(item, contact);
                        }}
                      />
                    );
                  })}
                </S.TagContainer>
              </S.InvitedMessageContainer>
              <S.InvitedMessageContainer>
                <S.StyledDivider orientation="left" orientationMargin="0">
                  <S.SubTitleCard>{t('pages.newRfq.steps.send.message')}</S.SubTitleCard>
                </S.StyledDivider>
                <Form.Item
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: t('pages.newRfq.steps.send.messageRequired'),
                    },
                    {
                      min: 5,
                      message: t('pages.requesRegistration.components.stepThree.messages.min'),
                    },
                  ]}
                >
                  <TextArea maxLength={500} style={{ minHeight: '7rem' }} />
                </Form.Item>
              </S.InvitedMessageContainer>
            </S.ContainerCardContent>
          )}
          <Form.Item style={{ margin: '0' }}>
            <S.SaveButtonCardContainer>
              <StyledButton
                variant="primary"
                htmlType="submit"
                disabled={item.contacts.length === 0}
              >
                {t('common.save')}
              </StyledButton>
            </S.SaveButtonCardContainer>
          </Form.Item>
        </Form>
      )}
    </S.Container>
  );
}
