export function useFormaters() {
  const formatPhoneNumber = (phone?: string) => {
    if (phone) {
      phone = phone.replace(/\D/g, '');

      if (phone.length === 14) {
        return phone.replace(/(\d{2})(\d{3})(\d{1})(\d{4})(\d{4})/, '+$1 $2 $3 $4-$5');
      }
      return phone;
    }
  };

  const formatMobilePhoneNumber = (mobilePhone?: string) => {
    if (mobilePhone) {
      mobilePhone = mobilePhone.replace(/\D/g, '');

      if (mobilePhone.length === 14) {
        return mobilePhone.replace(/(\d{2})(\d{3})(\d{1})(\d{4})(\d{4})/, '+$1 $2 $3 $4-$5');
      }
      return mobilePhone;
    }
  };

  const formatZipCode = (zipCode?: string) => {
    if (zipCode) {
      zipCode = zipCode.replace(/\D/g, '');

      if (zipCode.length === 14) {
        return zipCode.replace(/(\d{6})(\d{3})/, '$1-$2');
      }
      return zipCode;
    }
  };

  return {
    formatPhoneNumber,
    formatMobilePhoneNumber,
    formatZipCode,
  };
}
