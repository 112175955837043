import { ReactNode } from 'react';
import { AlertProvider } from './AlertContext';
import { CmsProvider } from './CmsContext';
import { NotificationsProvider } from './notification';
import { YearsScenariosContextProvider } from './YearsScenariosContext';

type Props = {
  children: ReactNode;
};

export const AppProviders = ({ children }: Props) => {
  return (
    <AlertProvider>
      <NotificationsProvider>
        <CmsProvider>
          <YearsScenariosContextProvider>{children}</YearsScenariosContextProvider>
        </CmsProvider>
      </NotificationsProvider>
    </AlertProvider>
  );
};
