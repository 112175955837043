import { Form } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from '../../context/AlertContext';
import {
  filterBaseScenarios,
  filterUserScenarios,
  getFilterURL,
  getSortOrder,
  removeEmptyFilter,
} from '../../helpers/utils';
import { api } from '../../services/api';

const initialParams = {
  order: '',
  orderBy: '',
  page: 0,
  limit: 100,
  name: '',
  targetYear: null,
};

export function useScenarios() {
  const { openAlert } = useAlert();
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [dataUser, setDataUser] = useState([]);
  const [dataBase, setDataBase] = useState([]);
  const [params, setParams] = useState(initialParams);
  const [filterFormValues, setFilterFormValues] = useState({
    name: params.name,
    targetYear: params.targetYear,
  });

  const fetchScenarios = async () => {
    const queryParams = getFilterURL(removeEmptyFilter(params));
    setIsLoading(true);

    try {
      const {
        data: { content, totalElements, totalPages },
      } = await api.get(`scenarios?${queryParams}`);

      const BaseScenarios = filterBaseScenarios(content);

      setDataUser(filterUserScenarios(content));
      setDataBase(BaseScenarios);
    } catch (error) {
      console.log('Errorfetch Scenario', error);
    }

    setIsLoading(false);
  };

  const handleChangeTable = (pagination, filters, sorter) => {
    if (dataUser.length === 0) return;
    setParams({
      ...params,
      order: sorter.columnKey,
      orderBy: getSortOrder(sorter.order),
      page: pagination.current - 1,
      limit: pagination.pageSize,
    });
  };

  const copyScenario = async (id) => {
    try {
      await api.post(`scenarios/${id}/copy`);
      await fetchScenarios();
      openAlert('success', t('toast.successOnSave'));
    } catch (error) {
      openAlert('error', t('toast.errorOnSave'));
    }
  };

  const deleteScenario = async (id) => {
    setIsLoading(true);
    try {
      await api.delete(`scenarios/${id}`);
      await fetchScenarios();
      openAlert('success', t('toast.successOnDelete'));
    } catch (error) {
      openAlert('error', t('toast.errorOnDelete'));
    }
    setIsLoading(false);
  };

  const searchFilter = async (newFilters) => {
    setParams({
      ...initialParams,
      ...newFilters,
    });
  };

  const clearFilter = async () => {
    //setFilterFormValues({});
    setParams({
      ...params,
      name: null,
      targetYear: null,
    });
    //await fetchScenarios();
  };

  return {
    isLoading,
    setIsLoading,
    dataUser,
    setDataUser,
    dataBase,
    setDataBase,
    fetchScenarios,
    handleChangeTable,
    copyScenario,
    deleteScenario,
    clearFilter,
    searchFilter,
    form,
    params,
    filterFormValues,
    setFilterFormValues,
  };
}
