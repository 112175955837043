import styled from 'styled-components';
import { Calendar, DatePicker, Row } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

export const Container = styled.main`
  display: flex;
  flex-direction: row;
  margin: -24px -40px;
`;

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 18rem;
  min-height: 95vh;
  background-color: ${({ theme }) => theme.colorsDesignSystem.text};
  border-top: 6px solid ${({ theme }) => theme.colorsDesignSystem.primary};
  padding: 1.5rem 1.5rem 0 2.5rem;
  gap: 5.18rem;
`;
export const Title = styled.span`
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
`;

export const ContainerRight = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1.5rem;
  padding-left: 1.3rem;
  padding-right: 1.3rem;
`;

export const ContainerFilters = styled(Row)`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: flex-end;

  padding-top: 1.5rem;
`;

export const ContainerSchedule = styled.div`
  display: flex;

  .ant-picker-calendar th {
    text-align: center !important;
    font-size: 1rem;
    font-weight: 700;
  }

  .ant-picker-calendar {
    background: transparent !important; /* Remove o fundo do calendário */
  }

  .ant-picker-panel {
    background: transparent !important; /* Remove o fundo do painel */
  }

  .ant-picker-calendar-header {
    background: transparent !important; /* Remove o fundo do cabeçalho */
  }

  .ant-picker-body {
    background: transparent !important; /* Remove o fundo da área dos dias */
  }

  .ant-picker-calendar .ant-picker-cell {
    padding: 4px !important; /* Ajusta o espaçamento interno das células */
  }

  .ant-picker-calendar .ant-picker-cell-inner {
    margin: 4px !important; /* Ajusta o espaçamento externo entre os dias */
  }
`;

export const ContainerDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 23rem;
  padding: 1.44rem 1.56rem 1rem 2rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.scheduleDetails};
`;

export const Badge = styled.div<{ plus?: boolean }>`
  width: ${({ plus }) => (plus ? '1.5rem' : '1.43rem')};
  height: ${({ plus }) => (plus ? '1.5rem' : '1.25rem')};
  border-radius: 50%;
  background-color: ${({ theme, plus }) =>
    plus ? theme.colorsDesignSystem.primary : theme.colors.orange};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const LabelsRightContent = styled.span<{ size: 'small' | 'medium' | 'large' }>`
  font-size: ${({ size }) =>
    size === 'large' ? '2.25rem' : size === 'medium' ? '1.25rem' : '1rem'};
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  font-weight: ${({ size }) => (size === 'small' ? '400' : '600')};
`;

export const ContainerCards = styled.div`
  margin-top: 4.13rem;
  display: flex;
  flex-direction: column;
`;

export const ArrowDown = styled(DownOutlined)`
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
`;

export const ArrowUp = styled(UpOutlined)`
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
`;

export const ContainerSeparateCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const RowDelayedCards = styled(Row)`
  margin-top: 3.75rem;
  margin-bottom: 1.5rem;
`;

export const StyledDatePicker = styled(DatePicker)`
  visibility: hidden;
  margin-bottom: 0.5rem;
`;

export const DatePickerButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;

  span {
    color: white;
    font-size: 2.25rem;
    font-weight: 600;
    line-height: 1.5rem;
  }
`;

export const StyledCalendar = styled(Calendar)`
  &.ant-picker-calendar th {
    text-align: center !important;
    font-size: 0.863rem;
    font-weight: 400;
    color: white;
    line-height: 1.72669rem;
  }

  &.ant-picker-calendar {
    background: transparent !important; /* Remove o fundo do calendário */
    width: 11.1rem !important;
    height: 13.4rem !important;
  }

  .ant-picker-panel {
    background: transparent !important; /* Remove o fundo do painel */
  }

  &.ant-picker-calendar-header {
    background: transparent !important; /* Remove o fundo do cabeçalho */
  }

  &.ant-picker-body {
    background: transparent !important; /* Remove o fundo da área dos dias */
  }

  &.ant-picker-calendar .ant-picker-cell {
    padding: 4px !important; /* Ajusta o espaçamento interno das células */
  }

  &.ant-picker-calendar .ant-picker-cell-inner {
    margin: 4px !important; /* Ajusta o espaçamento externo entre os dias */
  }
`;

export const TitleCurrentMonth = styled.span`
  font-size: 2.25rem;
  color: white;
  font-weight: 600;
  line-height: 1.5rem;
`;

export const FiltersTagContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.375rem;
`;
