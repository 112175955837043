import { Empty } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import ExpandableCard from '../../../../components/Common/ExpandableCard';
import StyledSelect from '../../../../components/Common/StyledSelect';
import theme from '../../../../styles/theme';
import HeaderCard from '../HeaderCards';
import { FinancialMetricsCardProps } from './types';
import { useFiananciaMetricsCard } from './useFinancialMetrics';

const Y_AXIS_MARGIN_BOTTOM = 0.5;
const Y_AXIS_MARGIN_TOP = 0.3;

export function FinancialMetricsCard({
  componentName,
  data,
  filterValue,
  changeFilter,
}: FinancialMetricsCardProps) {
  const { handleDate, CustomTooltip, handleRenderLegend, handleFormatPreviousYear } =
    useFiananciaMetricsCard(filterValue);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  const options = [
    { value: 'year', label: 'Year' },
    { value: 'previousMonths', label: 'Previous Months' },
  ];

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const { t } = useTranslation();

  return (
    <ExpandableCard
      isOpen
      headerCard={
        <HeaderCard
          title={t('pages.financePage.financialMetrics.title')}
          componentName={componentName}
          forMonth="  For Month (USD) "
          description={data?.description || ''}
          year={handleFormatPreviousYear(data, filterValue)}
          FilterValue={filterValue}
          filters={
            <StyledSelect
              options={options}
              value={filterValue ? 'year' : 'previousMonths'}
              onChange={(value: string) => {
                changeFilter(value === 'year');
              }}
              styles={{ minWidth: '9rem' }}
            />
          }
        />
      }
      onExpandableContent={() => true}
    >
      {data ? (
        <LineChart
          width={screenWidth - 120}
          height={250}
          data={data?.financialData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            padding={{ left: 45, right: 45 }}
            tickFormatter={(tick) =>
              filterValue
                ? handleDate(tick, 'MMM', true) || ''
                : handleDate(tick, 'MMM YY', true) || ''
            }
          />
          <YAxis
            domain={([dataMin, dataMax]) => {
              const min = Math.floor(dataMin - Y_AXIS_MARGIN_BOTTOM);
              const max = Math.ceil(dataMax + Y_AXIS_MARGIN_TOP);
              return [min, max];
            }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend align="right" content={handleRenderLegend} />
          <Line
            type="monotone"
            dataKey="priceBook"
            stroke={theme.colorsDesignSystem.chartColors.green}
          />
          <Line
            type="monotone"
            dataKey="average"
            stroke={theme.colorsDesignSystem.chartColors.gray}
          />
          <Line
            type="monotone"
            dataKey="supplierInvoice"
            stroke={theme.colorsDesignSystem.chartColors.orange}
          />
        </LineChart>
      ) : (
        <Empty />
      )}
    </ExpandableCard>
  );
}
