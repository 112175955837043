import { useTranslation } from 'react-i18next';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import theme from '../../../../styles/theme';
import { chartData } from '../types';
import * as S from './styles';
import { useUnitPriceTotalInvoice } from './useUnitPriceTotalInvoice';

export default function UnitPriceTotalInvoiceChart(data: chartData) {
  const { t } = useTranslation();
  const { CustomTooltipComponent, CustomLabel, handleColorBars, formatTick } =
    useUnitPriceTotalInvoice();

  const handleRenderLegend = () => {
    if (data.legend) {
      return (
        <div>
          <S.LegendContainer>
            <S.IndicatorLegend color={theme.colorsDesignSystem.chartColors.green} />
            <S.TextLegend>{t('pages.procurement.chart.chartBarsLabel.negotiated')}</S.TextLegend>
            <S.IndicatorLegend color={theme.colorsDesignSystem.chartColors.blue} />
            <S.TextLegend>{t('pages.procurement.chart.chartBarsLabel.executed')}</S.TextLegend>
            <S.IndicatorLegend color={theme.colorsDesignSystem.chartColors.gray} />
            <S.TextLegend>{t('pages.procurement.chart.chartBarsLabel.gap')}</S.TextLegend>
          </S.LegendContainer>
        </div>
      );
    }
    return (
      <div
        style={{
          marginTop: '62px',
        }}
      />
    );
  };
  return (
    <ResponsiveContainer width="100%" height="100%" maxHeight={480}>
      <BarChart
        width={500}
        height={300}
        data={data.dataChart}
        key="name"
        margin={{
          top: 25,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        {data.tooltipData.length || data.dataChart[0].label ? (
          <Tooltip
            content={<CustomTooltipComponent dataTooltip={data.tooltipData} />}
            wrapperStyle={{ zIndex: 2, pointerEvents: 'auto' }}
            cursor={{ fill: 'transparent' }}
          />
        ) : null}
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis tickFormatter={formatTick} />
        <Legend align="right" content={handleRenderLegend} />
        <Bar barSize={40} dataKey="value">
          <LabelList
            dataKey="label"
            content={<CustomLabel />}
            position="top"
            fill={theme.colorsDesignSystem.grayAcer}
          />
          {data.dataChart.map((entry) => (
            <Cell key={entry.name} fill={handleColorBars(entry.name)} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
