import { Flex, Form } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0.85rem;
`;
export const StyledFlex = styled(Flex)`
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const StyledForItem = styled(Form.Item)`
  width: 100%;
  margin: 0;
`;

export const CustomLabel = styled.span`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 132%; /* 1.155rem */
  letter-spacing: -0.01575rem;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
`;
