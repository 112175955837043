import { Row } from 'antd';
import styled from 'styled-components';

//styles do primeiro form
export const ContentForm = styled.div`
  padding: 1rem 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  ::-webkit-scrollbar {
    width: 1.125rem;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.625rem 0.625rem ${({ theme }) => theme.colorsDesignSystem.grayBorder};
    border: solid 0.375rem transparent;
    border-radius: 624rem;
  }
  ::-webkit-scrollbar-thumb {
    background: transparent;
    box-shadow: inset 0 0 0.625rem 0.625rem ${({ theme }) => theme.colorsDesignSystem.placeHolder};
    border: solid 0.375rem transparent;
    -webkit-border-radius: 624rem;
    border-radius: 624rem;
  }
`;

export const StepFooter = styled(Row)`
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colorsDesignSystem.border};
  padding: 1rem 2.5rem;
`;

export const ContainerButtonAddNewOdm = styled.div`
  padding: 0.5rem 2.5rem 1rem 2.5rem;
  display: flex;
  flex-direction: column;
`;
