import {
  CheckCircleFilled,
  DownOutlined,
  ExclamationCircleFilled,
  UpOutlined,
} from '@ant-design/icons';
import { Divider, Row } from 'antd';
import styled from 'styled-components';
import StyledInput from '../../../../components/Common/StyledInput';

interface StatusProps {
  status?: 'WAIT_SEND_EMAIL' | 'WARNING' | null | undefined;
}

export const Container = styled.div<StatusProps>`
  width: 100%;
  border: 1px solid
    ${({ status }) =>
      status === 'WARNING'
        ? ({ theme }) => theme.colorsDesignSystem.tagColorYellow
        : ({ theme }) => theme.colorsDesignSystem.border};

  border-radius: 0.75rem;
  border-radius: 0.378rem;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Header = styled(Row)`
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colorsDesignSystem.border};
`;

export const StatusOK = styled(CheckCircleFilled)`
  color: ${({ theme }) => theme.colorsDesignSystem.blue};
  font-size: 0.91rem;
`;

export const StatusWarning = styled(ExclamationCircleFilled)`
  color: ${({ theme }) => theme.colorsDesignSystem.tagColorYellow};
  font-size: 0.91rem;
`;

export const Title = styled.span`
  display: flex;
  font-weight: 600;
  font-size: 1rem;
  gap: 0.5rem;

  b {
    font-weight: 600;
    color: ${({ theme }) => theme.colorsDesignSystem.blue};
  }

  .soft {
    color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
  }
`;

export const ArrowUp = styled(UpOutlined)<{ isOpen: boolean }>`
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
  transform: ${({ isOpen }) => `rotate(${isOpen ? 180 : 0}deg)`};
  transition: all 0.5s;
  cursor: pointer;
`;
export const ArrowDown = styled(DownOutlined)<{ isOpen: boolean }>`
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
  transform: ${({ isOpen }) => `rotate(${isOpen ? 180 : 0}deg)`};
  transition: all 0.5s;
  cursor: pointer;
`;

export const Sumary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
`;

export const Label = styled.span`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colorsDesignSystem.grayBorderTooltip};
`;

export const Description = styled.span<{ hasIcon?: boolean }>`
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
`;

export const Labels = styled.span`
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
`;

export const ContainerClosedCardContent = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const InfoContactConteiner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  background-color: ${({ theme }) =>
    theme.colorsDesignSystem.newTablesColors.colorBackgroundSubItens};
  border: 0.063rem solid ${({ theme }) => theme.colorsDesignSystem.border};
  border-radius: ${({ theme }) => theme.dimensions.border_radius_medium};
  height: 8rem;
  margin-bottom: 0.625rem;
  padding: 1.406rem 1.406rem 1.406rem 1.406rem;
`;

export const ContainerCardContent = styled.div`
  margin: 1.5rem;
`;

export const InvitedMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.125rem;
`;

export const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 10fr;
  max-width: max-content;
  width: 100%;
  gap: 0.5rem;
`;

export const ContactNameInput = styled(StyledInput)`
  width: 13.75rem;
  color: ${({ theme }) => theme.colorsDesignSystem.black} !important;
  font-size: 0.875rem !important;
`;

export const EmailInput = styled(StyledInput)`
  color: ${({ theme }) => theme.colorsDesignSystem.black} !important;
  font-size: 0.875rem !important;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

export const TagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1.5rem;
`;

export const StyledDivider = styled(Divider)`
  border-color: ${({ theme }) => theme.colorsDesignSystem.border} !important;
`;

export const SubTitleCard = styled.p`
  font-size: 1.25rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  margin: 0;
`;

export const SaveButtonCardContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 0.063rem solid ${({ theme }) => theme.colorsDesignSystem.border};
  padding: 1rem 2.5rem;
`;
