import { CalendarOutlined, DownOutlined, SearchOutlined, UpOutlined } from '@ant-design/icons';
import { Flex, Form } from 'antd';
import styled from 'styled-components';

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  height: 3rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.registerComponents.white};
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.registerComponents.borderColor};
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
`;
export const ArrowStyled = styled(UpOutlined)`
  font-size: 1rem;
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
`;
export const ArrowDownStyled = styled(DownOutlined)`
  font-size: 1rem;
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
  cursor: pointer;
`;

export const LabelPartNumber = styled.span`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colorsDesignSystem.registerComponents.blackLabels};

  &.clear {
    color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
  }
`;

export const ContentContainerEditing = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2rem 1.5rem 2rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.tag_background};
  border-left: 1px solid ${({ theme }) => theme.colorsDesignSystem.registerComponents.borderColor};
  border-right: 1px solid ${({ theme }) => theme.colorsDesignSystem.registerComponents.borderColor};
`;

export const LabelSelect = styled.span`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
`;

export const StyledCalendar = styled(CalendarOutlined)`
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
`;

export const ContainerContact = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  background-color: ${({ theme }) => theme.colorsDesignSystem.tag_background};
  padding: 1.5rem 1.88rem 1.5rem 1.88rem;
  gap: 1rem;
`;

export const TitleCardContactTitle = styled.span`
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
`;

export const ContainerFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  height: 3rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.registerComponents.white};
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.registerComponents.borderColor};
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
`;

export const ContentContainerNoEditing = styled.div`
  border-radius: 0rem 0rem 0.75rem 0.75rem;
  border-right: 1px solid ${({ theme }) => theme.colorsDesignSystem.registerComponents.borderColor};
  border-bottom: 1px solid ${({ theme }) => theme.colorsDesignSystem.registerComponents.borderColor};
  border-left: 1px solid ${({ theme }) => theme.colorsDesignSystem.registerComponents.borderColor};

  display: flex;
  padding: 1rem;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
`;

export const LabelContent = styled.span`
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};

  &.strong {
    font-weight: 600;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
    font-size: 0.875rem;
  }
`;

export const StyledSearchIcon = styled(SearchOutlined)`
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
`;

export const StyledFlex = styled(Flex)`
  padding: 0;

  &.bigger {
    flex: 1;
  }

  &.small {
    width: 31%;
  }

  &.ultraSmall {
    width: 20%;
  }

  &.normal {
    width: 50%;
  }
`;
