import { forwardRef } from 'react';
import * as S from './styles';
import ICardMenuProps from './types';

const CardMenus = forwardRef<HTMLDivElement, ICardMenuProps>(
  ({ children, title, hidden, setCard, onClick }, ref) => {
    if (hidden) return null;
    return (
      <S.Container onClick={onClick} ref={ref} setCard={setCard}>
        <S.Content>{children}</S.Content>
        <S.Footer>
          <span>{title}</span>
        </S.Footer>
      </S.Container>
    );
  }
);
// 🔹 Definindo um display name para evitar o erro do ESLint
CardMenus.displayName = 'CardMenus';
export default CardMenus;
