import { Flex, RadioChangeEvent } from 'antd';
import { useState } from 'react';
import { useAlert } from '../../../../context/AlertContext';
import { handleFormatValueFields } from '../../../../helpers/nUtils';
import { getURI } from '../../../../helpers/utils';
import { api } from '../../../../services/api';
import { services } from '../../../../services/services';
import theme from '../../../../styles/theme';
import { IParams } from '../types';
import * as S from './styles';
import { CustomTooltipProps, IPricesWinGraph } from './types';

export function usePricesWin() {
  const { openAlert } = useAlert();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [graphData, setGraphData] = useState<IPricesWinGraph[]>();
  const [selectedOption, setSelectedOption] = useState<string>('NET');

  const toggleOptions = [
    { label: 'GROSS', value: 'GROSS' },
    { label: 'NET', value: 'NET' },
  ];

  const COLORS = [
    theme.colorsDesignSystem.chartColors.teal,
    theme.colorsDesignSystem.chartColors.mintGreen,
    theme.colorsDesignSystem.chartColors.lightGreen,
    theme.colorsDesignSystem.chartColors.limeGreen,
  ];

  const onChangeOption = ({ target: { value } }: RadioChangeEvent) => {
    setSelectedOption(value);
  };

  const formatGraphData = (data: IPricesWinGraph[]) => {
    const formatted = data.map((item, index) => {
      return {
        altGroup: item.altGroup,
        children: item.children.map((child) => {
          return {
            ...child,
            fill: COLORS[index % COLORS.length],
          };
        }),
      };
    });

    return formatted;
  };

  const renderColorfulLegendText = (value: string, entry: any) => {
    return <span style={{ color: theme.colors.inputPlaceHolder }}>{value}</span>;
  };

  const CustomizedContent = (props: any) => {
    const { x, y, width, height, spec, fill, total } = props;

    const truncateText = (text: string, maxWidth: number, color?: string) => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      if (context) {
        let textWidth = context.measureText(text).width;

        if (textWidth <= maxWidth) {
          return text;
        }

        while (text?.length > 0 && textWidth > maxWidth) {
          text = text.slice(0, -1);
          textWidth = context.measureText(`${text}...`).width;
        }

        return `${text}...`;
      }
      return text;
    };

    const splitName = spec ? truncateText(spec, width - 32) : '';
    const splitTotal = truncateText(`$${handleFormatValueFields({ number: total })}`, width - 32);

    return (
      <g overflow="auto">
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          style={{
            fill,
            stroke: theme.colorsDesignSystem.white,
            strokeWidth: 2,
            strokeOpacity: 1,
          }}
        />
        <text
          x={x + width / 2}
          y={y + height / 2}
          textAnchor="middle"
          fontSize={12}
          fontWeight="normal"
        >
          {splitName}
        </text>
        <text
          x={x + width / 2}
          y={y + height / 2 + 14}
          textAnchor="middle"
          fontSize={12}
          fontWeight="normal"
          fill={theme.colors.text}
        >
          {splitTotal}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
      return (
        <S.TooltipContainer>
          {payload.map((entry) => {
            return (
              <Flex key={entry?.payload?.spec} vertical>
                <S.AltGroup>{`${entry?.payload?.spec}`}</S.AltGroup>
                <Flex gap={8} vertical>
                  {entry?.payload?.suppliers.map((supply) => {
                    return (
                      <Flex key={supply.supllierName} justify="space-between">
                        <S.Supplier>{supply.supllierName}</S.Supplier>
                        <Flex gap={8}>
                          <S.Percentage>
                            {handleFormatValueFields({ number: supply.percentage })}%
                          </S.Percentage>
                          <S.Value>${handleFormatValueFields({ number: supply.value })}</S.Value>
                        </Flex>
                      </Flex>
                    );
                  })}
                </Flex>
                <S.StyledDivider />
                <S.Value alignRight>
                  ${handleFormatValueFields({ number: entry.payload.total })}
                </S.Value>
              </Flex>
            );
          })}
        </S.TooltipContainer>
      );
    }
    return null;
  };

  const fetchGraphData = async (params: IParams) => {
    try {
      setIsLoading(true);
      const { status, data } = await api.get(
        getURI(`${services.rfq}/bid/management/pricesWin`, {
          altGroup: params.altGroup,
          category: params.category,
          idBid: params.idBid,
          priceType: selectedOption,
          supplier: params.supplier,
        })
      );
      if (status === 200) {
        setGraphData(formatGraphData(data));
      }
    } catch (error: any) {
      openAlert('error', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    onChangeOption,
    selectedOption,
    toggleOptions,
    graphData,
    fetchGraphData,
    CustomizedContent,
    CustomTooltip,
    renderColorfulLegendText,
  };
}
