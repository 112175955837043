import { Divider as AntDivider, Flex } from 'antd';
import styled from 'styled-components';
import { DetailsTrackingTagEnum } from '../../../../helpers/enums';
import styledTheme from '../../../../styles/theme';

export const FontColorTags = {
  [DetailsTrackingTagEnum.PICKUP_SCHEDULED]:
    styledTheme.colorsDesignSystem.qualificationTagColors.orange,
  [DetailsTrackingTagEnum.IN_TRANSIT]: styledTheme.colorsDesignSystem.qualificationTagColors.blue,
  [DetailsTrackingTagEnum.DELIVERED]: styledTheme.colorsDesignSystem.avocato,
  [DetailsTrackingTagEnum.DELAYED]: styledTheme.colorsDesignSystem.red_secondary,
  [DetailsTrackingTagEnum.TEMPORARY_HOLD]:
    styledTheme.colorsDesignSystem.qualificationTagColors.lightOrange,
  [DetailsTrackingTagEnum.UPDATED]: styledTheme.colorsDesignSystem.qualificationTagColors.darkBlue,
};

export const Container = styled(Flex)`
  overflow-y: auto;
  padding: 1.5rem;
`;

export const TrackingNumberInformation = styled(Flex)`
  flex-direction: column;
  width: 22rem;
  height: max-content;
  gap: 1.5rem;
  padding: 1rem 1.5rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  border-radius: 0.75rem;

  .arrow {
    font-size: 1rem;
    color: ${({ theme }) => theme.colorsDesignSystem.grayBorderTooltip};
  }
`;

export const Icon = styled(Flex)`
  width: 2.5rem;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colorsDesignSystem.neutralGray};

  svg {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colorsDesignSystem.primary};
  }
`;

export const Label = styled.span<{ isBold?: boolean }>`
  font-size: 0.75rem;
  font-weight: ${({ isBold }) => (isBold ? 700 : 400)};
  line-height: 1rem;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
`;

export const Value = styled.span<{ size?: 'large' | 'normal' }>`
  font-size: ${({ size }) => (size === 'large' ? '1rem' : '0.75rem')};
  font-weight: 700;
  line-height: ${({ size }) => (size === 'large' ? '1.375rem' : '0.875rem')};
  color: ${({ theme }) => theme.colorsDesignSystem.text};

  i {
    font-weight: 400;
  }
`;

export const Divider = styled(AntDivider)`
  margin: 0px;
`;

export const TrackingNumberStatus = styled(Flex)`
  width: 28rem;
  padding: 1.5rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  border-radius: 0.75rem;
  flex: 1;
`;

export const StatusTitle = styled.span`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;
  color: ${({ theme }) => theme.colorsDesignSystem.quartenaryText};
`;

export const Tag = styled(Flex)<{ status: DetailsTrackingTagEnum }>`
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ status }) => FontColorTags[status]};

  .circle {
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 50%;
    background-color: ${({ status }) => FontColorTags[status]};
  }
`;
