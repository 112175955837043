import { CalendarOutlined, MoreOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const Container = styled.div`
  /* min-width: 19.4375rem */
  padding: 1.25rem 0.55rem 1.31rem 0.88rem;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  min-width: 7.3125rem;
  background-color: white;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.newTablesColors.borderColor};
`;

export const Bar = styled.div<{ delayed: boolean }>`
  min-height: 4.75rem;
  width: 0.5rem;
  display: flex;
  background-color: ${({ theme, delayed }) =>
    delayed ? 'red' : theme.colorsDesignSystem.tagColorBlue};
`;

export const MoreOptions = styled(MoreOutlined)`
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
  font-size: 2rem;
`;

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.25rem;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 0.81rem;
`;

export const Calendar = styled(CalendarOutlined)`
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
  margin-right: 0.37rem;
`;

export const Labels = styled.span<{ type: 'title' | 'subtitle' }>`
  font-size: ${({ type }) => (type === 'title' ? '0.875rem' : '1rem')};
  font-weight: ${({ type }) => (type === 'title' ? '400' : '600')};
  color: ${({ type, theme }) => (type === 'title' ? 'black' : theme.colorsDesignSystem.text)};
`;

export const Separator = styled.div`
  height: 0.3rem;
  width: 0.3rem;
  border-radius: 50%;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  background-color: ${({ theme }) => theme.colors.input_labels};
`;
