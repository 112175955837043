import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const StyledIconSearch = styled(SearchOutlined)`
  font-size: 1rem;
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  .description {
    width: 33rem;
  }
`;
