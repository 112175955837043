import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PayloadTrackingNumberDetails } from './types';
import { DetailsTrackingTagEnum, TrackingTagEnum } from '../../../../helpers/enums';
import { useAlert } from '../../../../context/AlertContext';
import { services } from '../../../../services/services';
import { api } from '../../../../services/api';

export function useModalTrackingNumberDetails() {
  const [isLoading, setIsLoading] = useState(false);
  const { openAlert } = useAlert();
  const labelTexts = {
    [DetailsTrackingTagEnum.PICKUP_SCHEDULED]: 'Pickup Scheduled',
    [DetailsTrackingTagEnum.IN_TRANSIT]: 'In Transit',
    [DetailsTrackingTagEnum.DELIVERED]: 'Delivered',
    [DetailsTrackingTagEnum.DELAYED]: ' Delayed',
    [DetailsTrackingTagEnum.TEMPORARY_HOLD]: 'Temporary Hold',
    [DetailsTrackingTagEnum.UPDATED]: 'Updated',
  };
  const [dataTracking, setDataTracking] = useState<PayloadTrackingNumberDetails>();
  const { t } = useTranslation();

  const handleFetchData = async (qualificationId: string, trackNumber: string) => {
    try {
      setIsLoading(true);
      const { data, status } = await api.get(
        `${services.qualification}/supplier/view/tracking/${qualificationId}?trackingNumber=${trackNumber}`
      );
      status === 200 && setDataTracking(data);
    } catch (error) {
      openAlert('error', t('toast.errorOnList'));
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleFetchData,
    isLoading,
    labelTexts,
    dataTracking,
  };
}
