import styled from 'styled-components';

interface CardProps {
  setCard?: boolean;
}

export const Container = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  height: 13.188rem;
  width: 12.438rem;
  border-radius: 1rem;
  box-shadow: 0px 1px 2px 0px ${({ theme }) => theme.colors.text_disabled};
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  border: ${({ theme, setCard }) =>
    setCard ? `2px solid ${theme.colorsDesignSystem.tabBarInactiveColor}` : 'none'};

  justify-content: space-between;
  transition: all 0.4s;
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
    border: 2px solid ${({ theme }) => theme.colorsDesignSystem.tabBarInactiveColor};
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25%;
  padding: 1rem;

  border-top: 1px solid ${({ theme }) => theme.colors.text_disabled};

  span {
    font-weight: 600;
    font-size: 1rem;
    color: ${({ theme }) => theme.colorsDesignSystem.primary};
    line-height: 1.125rem;
  }
`;
