import { Col, Divider, Form, Input, Select, InputNumber } from 'antd';
import { t } from 'i18next';
import { useEffect } from 'react';

import { ModalNewComponentProps } from './types';
import * as S from './styles';
import { StyledSelectOptions } from '../../../../components/Common/StyledSelect/types';

export function ContentModalNewComponent({
  categories,
  cms,
  form,
  products,
}: ModalNewComponentProps) {
  const sources: Array<StyledSelectOptions> = [
    { label: 'FOB', value: 'FOB' },
    { label: 'BR', value: 'BR' },
  ];

  useEffect(() => {
    form.resetFields();
  }, []);

  return (
    <Col span={24}>
      <Divider orientation="left" orientationMargin="0">
        {t('pages.requesRegistration.modalNewComponent.titleContent')}
      </Divider>
      <Form name="add-component" layout="vertical" form={form}>
        <Form.Item
          label={t('pages.requesRegistration.components.stepOne.labels.partNumber')}
          style={{ width: '25%' }}
          name="partNumber"
          rules={[
            {
              required: true,
              message: `${t('pages.requesRegistration.components.stepOne.labels.partNumber')} ${t(
                `common.validate.required`
              )}`,
            },
          ]}
        >
          <Input
            style={{ color: 'blue', backgroundColor: 'white' }}
            placeholder={t('pages.requesRegistration.components.stepOne.placeHolders.partNumber')}
          />
        </Form.Item>
        <Form.Item noStyle>
          <Form.Item
            label={t('pages.requesRegistration.components.stepOne.labels.cm')}
            style={{ display: 'inline-block', width: 'calc(20% - 0.5rem)' }}
            name="cm"
          >
            <Select
              placeholder={t('pages.requesRegistration.components.stepOne.placeHolders.cm')}
              options={cms}
            />
          </Form.Item>
          <Form.Item
            label={t('pages.requesRegistration.components.stepOne.labels.product')}
            style={{ display: 'inline-block', width: 'calc(20% - 1rem)', margin: '0 0 0 1rem' }}
            name="product"
            rules={[
              {
                required: true,
                message: `${t('pages.requesRegistration.components.stepOne.labels.product')} ${t(
                  `common.validate.required`
                )}`,
              },
            ]}
          >
            <Select
              placeholder={t('pages.requesRegistration.components.stepOne.placeHolders.product')}
              options={products}
            />
          </Form.Item>
          <Form.Item
            label={t('pages.requesRegistration.components.stepOne.labels.category')}
            style={{ display: 'inline-block', width: 'calc(20% - 1rem)', margin: '0 0 0 1rem' }}
            name="category"
            rules={[
              {
                required: true,
                message: `${t('pages.requesRegistration.components.stepOne.labels.category')} ${t(
                  `common.validate.required`
                )}`,
              },
            ]}
          >
            <Select
              options={categories}
              showSearch
              suffixIcon={<S.StyledIconSearch />}
              placeholder={t('pages.requesRegistration.components.stepOne.placeHolders.category')}
            />
          </Form.Item>
          <Form.Item
            label={t('pages.requesRegistration.components.stepOne.labels.specification')}
            style={{ display: 'inline-block', width: 'calc(40% - 0.5rem)', margin: '0 0 0 1rem' }}
            name="specification"
            rules={[
              {
                required: true,
                message: `${t(
                  'pages.requesRegistration.components.stepOne.labels.specification'
                )} ${t(`common.validate.required`)}`,
              },
            ]}
          >
            <Input
              placeholder={t('pages.requesRegistration.components.stepOne.placeHolders.spec')}
            />
          </Form.Item>
        </Form.Item>
        <S.FieldsContainer>
          <Form.Item
            label={t('pages.requesRegistration.components.stepOne.labels.description')}
            name="description"
            rules={[
              {
                required: true,
                message: `${t(
                  'pages.requesRegistration.components.stepOne.labels.specification'
                )} ${t(`common.validate.required`)}`,
              },
              {
                min: 2,
                message: `${t(
                  'pages.requesRegistration.components.stepOne.labels.specification'
                )} ${t(`pages.requesRegistration.components.stepTwo.errorMessages.minLength`, {
                  length: '2',
                })}`,
              },
            ]}
          >
            <Input
              className="description"
              maxLength={30}
              placeholder={t(
                'pages.requesRegistration.components.stepOne.placeHolders.description'
              )}
            />
          </Form.Item>
          <Form.Item
            label={t('pages.requesRegistration.components.stepOne.labels.source')}
            name="source"
          >
            <Select
              options={sources}
              placeholder={t('pages.requesRegistration.components.stepOne.placeHolders.source')}
            />
          </Form.Item>
          <Form.Item
            label={t('pages.requesRegistration.components.stepOne.labels.subComponents')}
            name="subComponents"
          >
            <InputNumber
              style={{ minWidth: '8.25rem' }}
              placeholder={t(
                'pages.requesRegistration.components.stepOne.placeHolders.subComponents'
              )}
            />
          </Form.Item>
          <Form.Item
            label={t('pages.requesRegistration.components.stepOne.labels.leadTime')}
            name="leadTime"
          >
            <InputNumber
              style={{ minWidth: '8.25rem' }}
              maxLength={3}
              placeholder={t('pages.requesRegistration.components.stepOne.placeHolders.addDays')}
            />
          </Form.Item>
        </S.FieldsContainer>
      </Form>
    </Col>
  );
}
