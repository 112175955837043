import { Flex, Form, Input, Select, DatePicker, InputNumber } from 'antd';
import { useEffect } from 'react';
import { t } from 'i18next';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { AssociatedOdmData, AssociatedOdmsComponentProps } from './types';
import * as S from './styles';
import StyledButton from '../../../../components/Common/StyledButton';
import { useAssociatedOdms } from './useAssociatedOdms';

export function AssociatedOdms({
  item,
  deleteItem,
  editItem,
  saveItem,
}: AssociatedOdmsComponentProps) {
  const [form] = Form.useForm<AssociatedOdmData>();

  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        ...item,
        sampleRequest: dayjs(item.sampleRequest),
      });
    }
  }, [item]);

  const { disabledDate } = useAssociatedOdms();

  return (
    <Form
      form={form}
      initialValues={item}
      layout="vertical"
      onFinish={() => {
        saveItem({
          ...item,
          ...form.getFieldsValue(),
        });
      }}
    >
      <Flex vertical>
        <S.ContainerHeader>
          <>
            <Flex justify="center" align="center" gap={16}>
              {item.isEditing ? (
                <S.ArrowStyled />
              ) : (
                <S.ArrowDownStyled onClick={() => editItem(item)} />
              )}
              <S.LabelPartNumber>
                {item.odmName || t('pages.requesRegistration.components.stepTwo.titleHeader')}
              </S.LabelPartNumber>
            </Flex>
            {!item.isEditing ? (
              <StyledButton
                variant="slim"
                onClick={() => {
                  editItem(item);
                }}
              >
                <EditOutlined />
                {t('common.edit')}
              </StyledButton>
            ) : (
              <StyledButton
                variant="slim"
                onClick={() => {
                  deleteItem(item);
                }}
              >
                <DeleteOutlined />
                {t('common.delete')}
              </StyledButton>
            )}
          </>
        </S.ContainerHeader>
        {item.isEditing && (
          <S.ContentContainerEditing>
            <Flex gap={16}>
              {/* ODM Name */}
              <S.StyledFlex vertical className="bigger" style={{ maxWidth: '38rem' }}>
                <S.LabelSelect>
                  {t('pages.requesRegistration.components.stepTwo.labels.odmName')}
                </S.LabelSelect>
                <Form.Item
                  name="odmName"
                  rules={[
                    {
                      required: true,
                      message: `${t(
                        'pages.requesRegistration.components.stepTwo.labels.odmName'
                      )} ${t(`common.validate.required`)}`,
                    },
                  ]}
                >
                  <Input
                    placeholder={t(
                      'pages.requesRegistration.components.stepTwo.placeholders.odmName'
                    )}
                  />
                </Form.Item>
              </S.StyledFlex>

              {/* Tax ID */}
              {/* <S.StyledFlex vertical className="small">
                <S.LabelSelect>
                  {t('pages.requesRegistration.components.stepTwo.labels.taxId')}
                </S.LabelSelect>
                <Form.Item name="taxId">
                  <Input
                    disabled
                    placeholder={t(
                      'pages.requesRegistration.components.stepTwo.placeholders.taxId'
                    )}
                  />
                </Form.Item>
              </S.StyledFlex> */}
            </Flex>
            <Flex gap={16}>
              {/* sample quantity */}
              <S.StyledFlex vertical style={{ maxWidth: '18.50rem' }} className="small">
                <S.LabelSelect>
                  {t('pages.requesRegistration.components.stepTwo.labels.quantity')}
                </S.LabelSelect>
                <Form.Item
                  name="sampleQuantity"
                  rules={[
                    {
                      required: true,
                      message: `${t(
                        'pages.requesRegistration.components.stepTwo.labels.quantity'
                      )} ${t(`common.validate.required`)}`,
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    maxLength={2}
                    placeholder={t(
                      'pages.requesRegistration.components.stepTwo.placeholders.quantity'
                    )}
                  />
                </Form.Item>
              </S.StyledFlex>
              <S.StyledFlex vertical style={{ maxWidth: '18.40rem' }} className="small">
                <S.LabelSelect>
                  {t('pages.requesRegistration.components.stepTwo.labels.request')}
                </S.LabelSelect>
                {/* Sample request */}
                <Form.Item
                  name="sampleRequest"
                  rules={[
                    {
                      required: true,
                      message: `${t(
                        'pages.requesRegistration.components.stepTwo.labels.request'
                      )} ${t(`common.validate.required`)}`,
                    },
                  ]}
                >
                  <DatePicker
                    width={150}
                    style={{ width: '100%' }}
                    placeholder={t('pages.requesRegistration.components.stepTwo.placeholders.date')}
                    suffixIcon={<S.StyledCalendar />}
                    format="DD/MM/YYYY"
                    onChange={(date) => {
                      form.setFieldsValue({ sampleRequest: date });
                    }}
                    disabledDate={disabledDate}
                  />
                </Form.Item>
              </S.StyledFlex>
            </Flex>

            <S.ContainerContact>
              <S.TitleCardContactTitle>
                {t('pages.requesRegistration.components.stepTwo.titleCardContact')}
              </S.TitleCardContactTitle>
              <Flex gap={16}>
                {/* Contact Person */}
                <S.StyledFlex vertical className="small">
                  <S.LabelSelect>
                    {t('pages.requesRegistration.components.stepTwo.labels.contactPerson')}
                  </S.LabelSelect>
                  <Form.Item
                    name={['contact', 'person']}
                    rules={[
                      {
                        required: true,
                        message: `${t(
                          'pages.requesRegistration.components.stepTwo.labels.contactPerson'
                        )} ${t(`common.validate.required`)}`,
                      },
                      {
                        min: 3,
                        message: `${t(
                          'pages.requesRegistration.components.stepTwo.labels.contactPerson'
                        )} ${t(
                          'pages.requesRegistration.components.stepTwo.errorMessages.minLength',
                          { length: '3' }
                        )}`,
                      },
                    ]}
                  >
                    <Input
                      maxLength={20}
                      placeholder={t(
                        'pages.requesRegistration.components.stepTwo.placeholders.person'
                      )}
                    />
                  </Form.Item>
                </S.StyledFlex>
                <S.StyledFlex vertical className="bigger">
                  <S.LabelSelect>
                    {t('pages.requesRegistration.components.stepTwo.labels.email')}
                  </S.LabelSelect>
                  {/* email */}
                  <Form.Item
                    name={['contact', 'email']}
                    rules={[
                      {
                        required: true,
                        message: `${t(
                          'pages.requesRegistration.components.stepTwo.labels.email'
                        )} ${t(`common.validate.required`)}`,
                      },
                      {
                        min: 10,
                        message: `${t(
                          'pages.requesRegistration.components.stepTwo.labels.email'
                        )} ${t(
                          'pages.requesRegistration.components.stepTwo.errorMessages.minLength',
                          { length: '10' }
                        )}`,
                      },
                    ]}
                  >
                    <Input
                      maxLength={40}
                      placeholder={t(
                        'pages.requesRegistration.components.stepTwo.placeholders.others'
                      )}
                    />
                  </Form.Item>
                </S.StyledFlex>
              </Flex>
              <Flex gap={16}>
                {/* mobile phone */}
                <S.StyledFlex vertical className="normal">
                  <S.LabelSelect>
                    {t('pages.requesRegistration.components.stepTwo.labels.mobile')}
                  </S.LabelSelect>
                  <Form.Item
                    name={['contact', 'mobileNumber']}
                    rules={[
                      {
                        required: true,
                        message: `${t(
                          'pages.requesRegistration.components.stepTwo.labels.mobile'
                        )} ${t(`common.validate.required`)}`,
                      },
                    ]}
                  >
                    <Input
                      maxLength={50}
                      placeholder={t(
                        'pages.requesRegistration.components.stepTwo.placeholders.others'
                      )}
                    />
                  </Form.Item>
                </S.StyledFlex>
                <S.StyledFlex className="normal" vertical>
                  <S.LabelSelect>
                    {t('pages.requesRegistration.components.stepTwo.labels.phone')}
                  </S.LabelSelect>
                  {/* phone number */}
                  <Form.Item name={['contact', 'phoneNumber']}>
                    <Input
                      placeholder={t(
                        'pages.requesRegistration.components.stepTwo.placeholders.others'
                      )}
                    />
                  </Form.Item>
                </S.StyledFlex>
              </Flex>
              <S.TitleCardContactTitle>
                {t('pages.requesRegistration.components.stepTwo.addressContact')}
              </S.TitleCardContactTitle>
              <Flex gap={16}>
                <S.StyledFlex vertical className="ultraSmall">
                  <S.LabelSelect>
                    {t('pages.requesRegistration.components.stepTwo.labels.zipCode')}
                  </S.LabelSelect>
                  {/* Zip code */}
                  <Form.Item
                    name={['contact', 'zipCode']}
                    rules={[
                      {
                        required: true,
                        message: `${t(
                          'pages.requesRegistration.components.stepTwo.labels.zipCode'
                        )} ${t(`common.validate.required`)}`,
                      },
                    ]}
                  >
                    <InputNumber
                      maxLength={6}
                      style={{ width: '100%' }}
                      placeholder={t(
                        'pages.requesRegistration.components.stepTwo.placeholders.others'
                      )}
                    />
                  </Form.Item>
                </S.StyledFlex>
                <S.StyledFlex className="small" vertical>
                  <S.LabelSelect>
                    {t('pages.requesRegistration.components.stepTwo.labels.country')}
                  </S.LabelSelect>
                  {/* Country */}
                  <Form.Item
                    name={['contact', 'country']}
                    rules={[
                      {
                        required: true,
                        message: `${t(
                          'pages.requesRegistration.components.stepTwo.labels.country'
                        )} ${t(`common.validate.required`)}`,
                      },
                      {
                        min: 2,
                        message: `${t(
                          'pages.requesRegistration.components.stepTwo.labels.country'
                        )} ${t(
                          'pages.requesRegistration.components.stepTwo.errorMessages.minLength',
                          { length: '10' }
                        )}`,
                      },
                    ]}
                  >
                    <Input
                      maxLength={20}
                      suffix={<S.StyledSearchIcon />}
                      placeholder={t(
                        'pages.requesRegistration.components.stepTwo.placeholders.others'
                      )}
                    />
                  </Form.Item>
                </S.StyledFlex>
              </Flex>
              <S.StyledFlex vertical>
                <S.LabelSelect>
                  {t('pages.requesRegistration.components.stepTwo.labels.address')}
                </S.LabelSelect>
                {/* address */}
                <Form.Item
                  name={['contact', 'address']}
                  rules={[
                    {
                      required: true,
                      message: `${t(
                        'pages.requesRegistration.components.stepTwo.labels.country'
                      )} ${t(`common.validate.required`)}`,
                    },
                  ]}
                >
                  <Input
                    maxLength={80}
                    placeholder={t(
                      'pages.requesRegistration.components.stepTwo.placeholders.others'
                    )}
                  />
                </Form.Item>
              </S.StyledFlex>
              <S.StyledFlex vertical>
                <S.LabelSelect>
                  {t('pages.requesRegistration.components.stepTwo.labels.information')}
                </S.LabelSelect>
                {/* additional info */}
                <Form.Item name={['contact', 'additionalInformation']}>
                  <Input
                    maxLength={100}
                    placeholder={t(
                      'pages.requesRegistration.components.stepTwo.placeholders.others'
                    )}
                  />
                </Form.Item>
              </S.StyledFlex>
            </S.ContainerContact>
          </S.ContentContainerEditing>
        )}
        {!item.isEditing && (
          <S.ContentContainerNoEditing>
            <Flex vertical>
              <S.LabelContent>
                {t('pages.requesRegistration.components.stepTwo.labels.quantity')}
              </S.LabelContent>
              <S.LabelContent className="strong">{item.sampleQuantity}</S.LabelContent>
            </Flex>
            <Flex vertical>
              <S.LabelContent>
                {t('pages.requesRegistration.components.stepTwo.labels.request')}
              </S.LabelContent>
              <S.LabelContent className="strong">
                {dayjs(item.sampleRequest).format('DD.MM.YY')}
              </S.LabelContent>
            </Flex>
            <Flex vertical>
              <S.LabelContent>
                {t('pages.requesRegistration.components.stepTwo.labels.country')}
              </S.LabelContent>
              <S.LabelContent className="strong">{item.contact.country}</S.LabelContent>
            </Flex>
          </S.ContentContainerNoEditing>
        )}
        {item.isEditing && (
          <S.ContainerFooter>
            <StyledButton
              onClick={() => {
                deleteItem(item);
              }}
              variant="slim"
            >
              {t('common.cancel')}
            </StyledButton>
            <StyledButton htmlType="submit" variant="primary">
              {t('common.save')}
            </StyledButton>
          </S.ContainerFooter>
        )}
      </Flex>
    </Form>
  );
}
