import {
  CheckCircleOutlined,
  EnvironmentOutlined,
  FieldTimeOutlined,
  InfoCircleOutlined,
  RightCircleOutlined,
  SecurityScanOutlined,
} from '@ant-design/icons';
import { Col, Flex, Row } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import CircularArrowSvg from '../../../../assets/circular-arrow.svg';
import IconLocalSvg from '../../../../assets/icon-local.svg';
import WatchSvg from '../../../../assets/watch.svg';
import * as S from './styles';
import { TrackingInfo } from '../ModalTrackinNumberDetails/types';
import { DetailsTrackingTagEnum } from '../../../../helpers/enums';
import { FontColorTags } from '../ModalTrackinNumberDetails/styles';
import { useModalTrackingNumberDetails } from '../ModalTrackinNumberDetails/useModalTrackingNumberDetails';
import theme from '../../../../styles/theme';

export function useSteps() {
  const { t } = useTranslation();
  const { labelTexts } = useModalTrackingNumberDetails();

  const handleGetIconByStatus = (status: DetailsTrackingTagEnum): ReactNode => {
    if (status === DetailsTrackingTagEnum.PICKUP_SCHEDULED)
      return <FieldTimeOutlined style={{ fontSize: 24, color: FontColorTags[status] }} />;
    if (status === DetailsTrackingTagEnum.IN_TRANSIT)
      return <RightCircleOutlined style={{ fontSize: 24, color: FontColorTags[status] }} />;
    if (status === DetailsTrackingTagEnum.TEMPORARY_HOLD)
      return <SecurityScanOutlined style={{ fontSize: 24, color: FontColorTags[status] }} />;
    if (status === DetailsTrackingTagEnum.DELIVERED)
      return <CheckCircleOutlined style={{ fontSize: 24, color: FontColorTags[status] }} />;
    if (status === DetailsTrackingTagEnum.DELAYED)
      return <InfoCircleOutlined style={{ fontSize: 24, color: FontColorTags[status] }} />;

    return '';
  };

  const handleRenderSteps = (data: Array<TrackingInfo>) => {
    return data.map((item, index) => {
      return (
        <>
          <S.ContainerStep status={item.status} key={`${item.date}`}>
            <S.ContainerFirstLine>
              <Row gutter={6}>
                <Col>{handleGetIconByStatus(item.status)}</Col>
                <Col>{labelTexts[item.status]}</Col>
              </Row>
              <Row>
                <S.Label>{dayjs(item.date).format('MM.DD.YYYY - hh:mm A')}</S.Label>
              </Row>
            </S.ContainerFirstLine>
            <Row align="middle" justify="end" gutter={4}>
              <Col>
                <EnvironmentOutlined
                  style={{ color: theme.colorsDesignSystem.qualificationTagColors.labelsGray }}
                />
              </Col>
              <Col>
                <S.Label>{`${item.city}, ${item.country}`}</S.Label>
              </Col>
            </Row>
          </S.ContainerStep>
          {index !== data.length - 1 && <S.Pipe status={item.status} />}
        </>
      );
    });
  };

  return {
    handleRenderSteps,
  };
}
