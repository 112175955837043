import { Button, Form, Popconfirm, Space } from 'antd';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';
import {
  getFilterURL,
  getSortOrder,
  hasPropertyWithHeader,
  removeEmptyFilter,
  states,
} from '../../helpers/utils';
import { useTable } from '../../hooks/useTable';

import { SelectInput } from '../../components/LateralFilter/SelectInput';
import { TableAction } from '../../components/Tables/TableDropdownAction';
import { useAlert } from '../../context/AlertContext';
import { getColumnSearchProps } from '../../helpers/table';
import { api } from '../../services/api';

const initialParams = {
  order: '',
  orderBy: '',
  page: 0,
  limit: 25,
  name: '',
  shortName: '',
  state: null,
};

export function useSupplier() {
  const { openAlert } = useAlert();
  const { t } = useTranslation();
  const { getSortOrderTable, getSortTitle } = useTable();

  const [form] = Form.useForm();
  const searchInput = useRef(null);
  const [isNewItem, setIsNewItem] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [params, setParams] = useState(initialParams);
  const [pageControl, setPageControl] = useState({
    pageLabel: '1',
    totalPages: 0,
    totalElements: 0,
  });

  const fetchSuppliers = async () => {
    const queryParams = getFilterURL(removeEmptyFilter(params));
    setIsLoading(true);

    try {
      const {
        data: { content, totalElements, totalPages },
      } = await api.get(`suppliers?${queryParams}`);

      const dataContent = content?.length ? content : [];
      setData(dataContent);

      setPageControl({
        pageLabel: params.page + 1,
        totalPages,
        totalElements,
      });
    } catch (error) {
      console.log('Error on the fetch Suppliers', error);
    }
    setIsLoading(false);
  };

  const changePage = (page, pageSize) => {
    setParams({ ...params, page: page - 1, limit: pageSize });
    setPageControl({ ...pageControl, pageLabel: page });
  };

  const changePageValue = (page, type) => {
    if (type === 'input' || type === 'navigation') {
      setParams({ ...params, page: page - 1 });
      setPageControl({ ...pageControl, pageLabel: page });
    } else {
      setPageControl({ ...pageControl, pageLabel: page });
    }
  };

  const handleChangeTable = (pagination, filters, sorter) => {
    setParams({
      ...params,
      order: sorter.columnKey,
      orderBy: getSortOrder(sorter.order),
      page: 0,
    });
  };

  const save = async (id) => {
    setIsLoading(true);
    try {
      await form.validateFields();
      const row = await form.getFieldValue();

      if (isNewItem) {
        const response = await api.post('suppliers', { ...row });
        if (response.status !== 201) throw Error();
      } else {
        const response = await api.put(`suppliers/${id}`, { ...row });
        if (response.status !== 200) throw Error();
      }

      await fetchSuppliers();
      setEditingKey('');
      setIsNewItem(false);

      openAlert('success', t('toast.successOnSave'));
    } catch (error) {
      openAlert('error', t('toast.errorOnSave'));
    }
    setIsLoading(false);
  };

  const handleAddItem = () => {
    const newItem = {
      id: data.length + 1,
      name: '',
      shortName: '',
      state: '',
    };

    setIsNewItem(true);
    form.setFieldsValue({ ...newItem });
    setData([newItem, ...data]);
    setEditingKey(newItem.id);
  };

  const edit = (record) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id);
  };

  const deleteItem = async (itemId) => {
    setIsLoading(true);
    try {
      if (isNewItem) {
        setIsNewItem(false);
        await fetchSuppliers();
      } else {
        const response = await api.delete(`suppliers/${itemId}`);
        if (response.status !== 200) throw Error();

        await fetchSuppliers();
        openAlert('success', t('toast.successOnDelete'));
      }
    } catch (error) {
      openAlert('error', t('toast.errorOnDelete'));
    }
    setIsLoading(false);
  };

  const isEditing = (record) => {
    return record.id === editingKey;
  };

  const cancel = () => {
    if (isNewItem) {
      const newData = data.filter((item) => item.id !== editingKey);
      setData(newData);
      setIsNewItem(false);
    }
    setEditingKey('');
  };

  const searchFilter = async (newFilters) => {
    setParams({
      ...initialParams,
      ...newFilters,
      page: 0,
    });
  };

  const clearFilter = async () => {
    setParams({
      ...initialParams,
      name: null,
      shortName: null,
      state: null,
    });
  };

  const handleHeaderReset = (dataIndex) => {
    const filterParams = { ...params };
    delete filterParams[`header_${dataIndex}`];

    if (!hasPropertyWithHeader(filterParams)) {
      delete filterParams.tipoBusca;
    }

    setParams(filterParams);
  };

  const handleCloseFilteredTag = (field, idx) => {
    if (field?.includes('header_')) {
      const dataIndex = field.replace('header_', '');
      handleHeaderReset(dataIndex);
    } else {
      let newValue;

      if (Array.isArray(params[field])) {
        newValue = [...params[field]];
        newValue.splice(idx, 1);
      } else {
        newValue = field === 'state' ? null : '';
      }

      setParams({
        ...params,
        [field]: newValue,
        page: 0,
      });
    }
  };

  const handleHeaderSearch = (dataIndex, formValue) => {
    if (dataIndex === 'local' && formValue?.local?.length) {
      const filterParams = {
        ...params,
        header_local: formValue.local.join(','),
        tipoBusca: 'dinamica',
        page: 0,
      };

      setParams(filterParams);
    } else if (dataIndex !== 'local' && formValue?.length) {
      const filterParams = {
        ...params,
        [`header_${dataIndex}`]: formValue.trim(),
        tipoBusca: 'dinamica',
        page: 0,
      };

      setParams(filterParams);
    } else if (!formValue) {
      handleHeaderReset(dataIndex);
    }
  };

  const columns = [
    {
      title: t('pages.suppliers.fields.name'),
      label: t('pages.suppliers.fields.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      editable: true,
      sortOrder: params.order === 'name' && getSortOrderTable(params.orderBy),
      showSorterTooltip: { title: getSortTitle(params.orderBy, params.order, 'name') },
      ...getColumnSearchProps({
        dataIndex: 'name',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        searchInput,
      }),
    },
    {
      title: t('pages.suppliers.fields.shortName'),
      label: t('pages.suppliers.fields.shortName'),
      dataIndex: 'shortName',
      key: 'shortName',
      sorter: true,
      editable: true,
      sortOrder: params.order === 'shortName' && getSortOrderTable(params.orderBy),
      showSorterTooltip: { title: getSortTitle(params.orderBy, params.order, 'shortName') },
      ...getColumnSearchProps({
        dataIndex: 'shortName',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        searchInput,
      }),
    },
    {
      title: t('pages.suppliers.fields.code'),
      label: t('pages.suppliers.fields.code'),
      dataIndex: 'codigo',
      key: 'codigo',
      sorter: false,
      editable: true,
      ...getColumnSearchProps({
        dataIndex: 'codigo',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        searchInput,
      }),
    },
    {
      title: t('pages.suppliers.fields.state'),
      label: t('pages.suppliers.fields.state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
      editable: true,
      sortOrder: params.order === 'state' && getSortOrderTable(params.orderBy),
      showSorterTooltip: { title: getSortTitle(params.orderBy, params.order, 'state') },
      ...getColumnSearchProps({
        dataIndex: 'state',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        searchInput,
      }),
    },
  ];

  const columnsWithAction = [
    ...columns,
    {
      title: t('common.action'),
      key: 'action',
      align: 'center',
      width: 130,
      render: (_, record) => {
        const editable = isEditing(record);

        const actions = [
          {
            key: '1',
            label: (
              <Button
                type="link"
                disabled={editingKey !== ''}
                onClick={() => edit(record)}
                data-cy="edit"
              >
                {t('common.edit')}
              </Button>
            ),
          },
          {
            key: '2',
            label: (
              <Popconfirm
                title={t('common.deleteMessage')}
                placement="topLeft"
                onConfirm={() => deleteItem(record.id)}
              >
                <Button type="link" danger disabled={editingKey !== ''} data-cy="delete">
                  {t('common.delete')}
                </Button>
              </Popconfirm>
            ),
          },
        ];

        return editable ? (
          <Space direction="horizontal" size={12}>
            <Button type="primary" onClick={() => save(record.id)} data-cy="save">
              {t('common.save')}
            </Button>
            <Popconfirm title={t('common.cancelMessage')} onConfirm={cancel}>
              <Button shape="circle" default icon={<AiOutlineClose />} data-cy="cancel" />
            </Popconfirm>
          </Space>
        ) : (
          <TableAction dropdownItems={actions} />
        );
      },
    },
  ];

  const mergedColumns = columnsWithAction.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        required: col.dataIndex !== 'state',
        newInputNode: col.dataIndex === 'state' && (
          <SelectInput
            placeholder={t('pages.suppliers.fields.state')}
            allowClear
            options={states()}
          />
        ),
        dataIndex: col.dataIndex,
        title: col.title,
        editable: col.editable && isEditing(record),
        editing: isEditing(record),
        limit: col.key === 'shortName' ? 25 : null,
      }),
    };
  });

  const csvHeaders = [
    { label: t('pages.suppliers.fields.name'), key: 'name' },
    { label: t('pages.suppliers.fields.shortName'), key: 'shortName' },
    { label: t('pages.suppliers.fields.code'), key: 'codigo' },
    { label: t('pages.suppliers.fields.state'), key: 'state' },
  ];

  return {
    data,
    params,
    pageControl,
    fetchSuppliers,
    changePageValue,
    handleChangeTable,
    isLoading,
    form,
    isNewItem,
    handleAddItem,
    searchFilter,
    clearFilter,
    handleCloseFilteredTag,
    columns,
    mergedColumns,
    csvHeaders,
    changePage,
  };
}
