import { CloseCircleOutlined } from '@ant-design/icons';
import { Flex } from 'antd';
import styled from 'styled-components';

export const StepsContent = styled(Flex)`
  justify-content: center;
  width: 100%;
  padding: 1.5rem 0;
  margin-bottom: 1.5rem;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  background: ${({ theme }) => theme.colorsDesignSystem.secondaryGrayBackground};
  .ant-steps {
    width: 100%;
    padding: 0 1.5rem;
  }
  .ant-steps .ant-steps-item-title::after {
    background-color: ${({ theme }) => theme.colorsDesignSystem.blue};
  }
  .ant-steps
    .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: ${({ theme }) => theme.colorsDesignSystem.blue};
  }
  .ant-steps
    .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: ${({ theme }) => theme.colorsDesignSystem.grayBorderTooltip};
  }
  .ant-steps
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: ${({ theme }) => theme.colorsDesignSystem.blue};
  }
  .ant-steps
    .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    font-weight: 700;
    color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
  }
  .ant-steps .ant-steps-item-process.ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
    color: ${({ theme }) => theme.colorsDesignSystemblue};
  }
  .ant-steps .ant-steps-item-finish.ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
    color: ${({ theme }) => theme.colorsDesignSystem.blue};
  }
  .ant-steps
    .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: ${({ theme }) => theme.colorsDesignSystem.grayText};
    font-size: 0.625rem;
    line-height: 0.75rem;
  }
  .ant-steps
    .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: ${({ theme }) => theme.colorsDesignSystem.grayBorderTooltip};
    font-weight: 600;
    font-size: 1rem;
  }
  .ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: ${({ theme }) => theme.colors.sec};
  }
  .ant-steps
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: ${({ theme }) => theme.colorsDesignSystem.iconGrey};
    font-size: 0.625rem;
    line-height: 0.75rem;
  }
  .ant-steps
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: ${({ theme }) => theme.colorsDesignSystem.iconGrey};
    font-size: 0.625rem;
    line-height: 0.75rem;
  }
`;

export const StatusTitle = styled.span`
  font-size: 1rem;
  font-weight: 600;
`;
export const StatusIcons = styled(CloseCircleOutlined)`
  color: ${({ theme }) => theme.colorsDesignSystem.red};
`;
