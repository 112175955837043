/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undefined */
import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  BoldOutlined,
  HighlightOutlined,
  ItalicOutlined,
  OrderedListOutlined,
  RedoOutlined,
  UnderlineOutlined,
  UndoOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createHeadingNode } from '@lexical/rich-text';
import { $patchStyleText, $setBlocksType } from '@lexical/selection';
import { mergeRegister } from '@lexical/utils';
import { Divider } from 'antd';
import {
  $getSelection,
  $isRangeSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_LOW,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from 'lexical';
import { useCallback, useEffect, useRef, useState } from 'react';
import Heading1 from '../../../../../assets/Heading1.svg';
import Heading2 from '../../../../../assets/Heading2.svg';
import JustifyAlign from '../../../../../assets/JustifyAlign.svg';
import FontSize from './fontSize';
import * as S from './styles';
import { INodes } from './types';

export function Toolbar() {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isHighlight, setIsHighlight] = useState(false);
  const [fontSize, setFontSize] = useState<string>('0.75rem');

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsHighlight(selection.hasFormat('highlight'));
    }
  }, []);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar();
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor, updateToolbar]);

  const toggleHighlight = useCallback(() => {
    editor.update(() => {
      const selection = $getSelection();

      if (selection) {
        const nodes: INodes[] = selection.getNodes();
        const isHighlighted = nodes.some((node) => {
          if (node.__style) {
            return node.__style.includes('background-color: yellow');
          }
          return false;
        });

        $patchStyleText(selection, {
          'background-color': isHighlighted ? 'transparent' : 'yellow',
        });
      }
    });
  }, [editor]);

  return (
    <S.ToolbarContainer ref={toolbarRef} data-cy="editor-toolbar">
      <S.ToolbarButton
        disabled={!canUndo}
        onClick={() => {
          editor.dispatchCommand(UNDO_COMMAND, undefined);
        }}
        type="text"
        icon={<UndoOutlined />}
        data-cy="editor-undo"
      />
      <S.ToolbarButton
        disabled={!canRedo}
        onClick={() => {
          editor.dispatchCommand(REDO_COMMAND, undefined);
        }}
        type="text"
        icon={<RedoOutlined />}
        data-cy="editor-redo"
      />
      <Divider type="vertical" />
      <S.ToolbarButton
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
        }}
        active={isBold}
        type="text"
        icon={<BoldOutlined />}
        data-cy="editor-bold"
      />
      <S.ToolbarButton
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
        }}
        active={isItalic}
        type="text"
        icon={<ItalicOutlined />}
        data-cy="editor-italic"
      />
      <S.ToolbarButton
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
        }}
        active={isUnderline}
        type="text"
        icon={<UnderlineOutlined />}
        data-cy="editor-underline"
      />

      <S.ToolbarButton
        onClick={() => {
          toggleHighlight();
        }}
        active={isHighlight}
        type="text"
        icon={<HighlightOutlined />}
        data-cy="editor-highlight"
      />
      <Divider type="vertical" />
      <S.ToolbarButton
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
        }}
        type="text"
        icon={<AlignLeftOutlined />}
        data-cy="editor-align-left"
      />
      <S.ToolbarButton
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
        }}
        className="toolbar-item spaced"
        type="text"
        icon={<AlignCenterOutlined />}
        data-cy="editor-align-center"
      />
      <S.ToolbarButton
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
        }}
        className="toolbar-item spaced"
        type="text"
        icon={<AlignRightOutlined />}
        data-cy="editor-align-right"
      />
      <S.ToolbarButton
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');
        }}
        className="toolbar-item"
        type="text"
        icon={<img src={JustifyAlign} alt="Justify" className="small-image" />}
        data-cy="editor-align-justify"
      />
      <Divider type="vertical" />
      <FontSize
        selectionFontSize={(Number(fontSize.slice(0, -3)) * 16).toString()}
        editor={editor}
        data-cy="editor-font-size"
      />
      <S.ToolbarButton
        onClick={(): void => {
          editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
              $setBlocksType(selection, () => $createHeadingNode('h1'));
            }
          });
        }}
        className="toolbar-item"
        type="text"
        icon={<img src={Heading1} alt="H1" />}
        data-cy="editor-h1"
      />
      <S.ToolbarButton
        onClick={(): void => {
          editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
              $setBlocksType(selection, () => $createHeadingNode('h2'));
            }
          });
        }}
        className="toolbar-item"
        type="text"
        icon={<img src={Heading2} alt="H2" />}
        data-cy="editor-h2"
      />
      <Divider type="vertical" />
      <S.ToolbarButton
        onClick={(): void => {
          editor.update(() => {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
          });
        }}
        className="toolbar-item"
        type="text"
        icon={<UnorderedListOutlined />}
        data-cy="editor-unorderedd-list"
      />
      <S.ToolbarButton
        onClick={(): void => {
          editor.update(() => {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
          });
        }}
        className="toolbar-item"
        type="text"
        icon={<OrderedListOutlined />}
        data-cy="editor-ordered-list"
      />
    </S.ToolbarContainer>
  );
}
