import { DayScheduleProps } from './types';
import * as S from './styles';
import { useScheduleDay } from './useScheduleDay';

export function ScheduleDay({ currentDay, partNumbers, type, value }: DayScheduleProps) {
  const { handleVerifyIfIsWeekend, handleRenderEvents, handleVerifyIfDayIfCurrentMonth } =
    useScheduleDay();

  return (
    <>
      {type === 'NORMAL' && (
        <S.Container
          isActualDay={currentDay.isSame(value)}
          isWeekend={handleVerifyIfIsWeekend(currentDay)}
        >
          {String(currentDay.date()).padStart(2, '0')}
          {handleRenderEvents(partNumbers)}
        </S.Container>
      )}

      {type === 'SMALL' && (
        <S.ContainerSmall
          isDayOfCurrentMonth={handleVerifyIfDayIfCurrentMonth(currentDay, value)}
          isActualDay={currentDay.isSame(value)}
        >
          {String(currentDay.date()).padStart(2, '0')}
        </S.ContainerSmall>
      )}
    </>
  );
}
