import { Row } from 'antd';
import * as S from './styles';
import StyledIndicationTag from '../../../components/IndicationTag';
import { CardEventDetailsProps } from './types';

export function CardEventDetails({
  odmName,
  projectName,
  supplierName,
  qStatus,
  partNumber,
  delayed,
}: CardEventDetailsProps) {
  return (
    <S.Container>
      <S.Bar delayed={delayed} />
      <S.ContainerContent>
        <Row>
          <S.Calendar />
          <S.Labels type="title">{partNumber}</S.Labels>
        </Row>
        <Row align="middle">
          <S.Labels type="subtitle">{projectName}</S.Labels>
          <S.Separator />
          <S.Labels type="subtitle">{supplierName}</S.Labels>
          <S.Separator />
          <S.Labels type="subtitle">{odmName}</S.Labels>
        </Row>
        <StyledIndicationTag text={qStatus.toUpperCase()} />
      </S.ContainerContent>
      <S.MoreOptions />
    </S.Container>
  );
}
