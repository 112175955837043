import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { StyledLoading } from '../../../components/Common/StyledLoading';
import * as S from './styles';
import { useModalNewEvent } from './useModalNewEvent';
import StyledDatePicker from '../../../components/Common/StyledDatePicker';
import StyledSelect from '../../../components/Common/StyledSelect';
import StyledButton from '../../../components/Common/StyledButton';
import { ModalNewScheduleProps } from './types';

export default function ModalNewEvent({ closeModal }: ModalNewScheduleProps) {
  const {
    form,
    isLoading,
    statusCard,
    qualificationsIds,
    qualificationIdSelected,
    partNumbers,
    partNumberSelected,
    setPartNumberSelected,
    handleFetchOtherData,
    handleFetchQualificationIds,
    handleFetchParNumbers,
    handleUpdateQualificationId,
    handleSaveNewEvent,
  } = useModalNewEvent();
  const { t } = useTranslation();

  useEffect(() => {
    handleFetchQualificationIds();
  }, []);

  useEffect(() => {
    qualificationIdSelected && handleFetchParNumbers();
  }, [qualificationIdSelected]);

  useEffect(() => {
    partNumberSelected && handleFetchOtherData();
  }, [partNumberSelected]);

  return (
    <S.Container>
      {isLoading ? (
        <StyledLoading height={30} />
      ) : (
        <div>
          <Form
            layout="vertical"
            form={form}
            onFinish={(values) => {
              handleSaveNewEvent(values, closeModal);
            }}
          >
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t(
                    'pages.scheduleQualification.modalNewEvent.errorMessages.requiredField'
                  ),
                },
              ]}
              name="idQualification"
              label={t('pages.scheduleQualification.modalNewEvent.labels.idQualification')}
              style={{ flex: '1' }}
            >
              <StyledSelect
                options={qualificationsIds}
                value={qualificationIdSelected}
                placeholder={t('pages.scheduleQualification.modalNewEvent.placeholder.id')}
                onChange={(value: string) => {
                  handleUpdateQualificationId(value);
                }}
              />
            </Form.Item>
            <S.RowContainer>
              <Form.Item
                name="partNumber"
                label={t('pages.scheduleQualification.modalNewEvent.labels.partNumber')}
                style={{ flex: '1' }}
                rules={[
                  {
                    required: true,
                    message: t(
                      'pages.scheduleQualification.modalNewEvent.errorMessages.requiredField'
                    ),
                  },
                ]}
              >
                <StyledSelect
                  options={partNumbers}
                  onChange={(value: string) => {
                    setPartNumberSelected(value);
                  }}
                  placeholder={t(
                    'pages.scheduleQualification.modalNewEvent.placeholder.partNumber'
                  )}
                />
              </Form.Item>
              <Form.Item
                name="projectName"
                label={t('pages.scheduleQualification.modalNewEvent.labels.projectName')}
                style={{ flex: '1' }}
              >
                <Input
                  placeholder={t('pages.scheduleQualification.modalNewEvent.placeholder.others')}
                  disabled
                />
              </Form.Item>
            </S.RowContainer>
            <S.RowContainer>
              <Form.Item
                name="supplier"
                label={t('pages.scheduleQualification.modalNewEvent.labels.supplier')}
                style={{ flex: '1' }}
              >
                <Input
                  placeholder={t('pages.scheduleQualification.modalNewEvent.placeholder.others')}
                  disabled
                />
              </Form.Item>
              <Form.Item
                name="odm"
                label={t('pages.scheduleQualification.modalNewEvent.labels.odm')}
                style={{ flex: '1' }}
              >
                <Input
                  placeholder={t('pages.scheduleQualification.modalNewEvent.placeholder.others')}
                  disabled
                />
              </Form.Item>
            </S.RowContainer>
            <Form.Item
              name="createdBy"
              label={t('pages.scheduleQualification.modalNewEvent.labels.createdBy')}
              style={{ flex: '1' }}
            >
              <Input disabled />
            </Form.Item>
            <S.RowContainer>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: t(
                      'pages.scheduleQualification.modalNewEvent.errorMessages.requiredField'
                    ),
                  },
                ]}
                name="date"
                style={{ flex: '1' }}
              >
                <StyledDatePicker
                  styles={{ width: '100%', marginTop: '5px' }}
                  label={t('pages.scheduleQualification.modalNewEvent.labels.date')}
                />
              </Form.Item>
              <Form.Item
                name="status"
                label={t('pages.scheduleQualification.modalNewEvent.labels.status')}
                style={{ flex: '1' }}
              >
                <StyledSelect
                  options={statusCard}
                  onChange={() => {
                    return null;
                  }}
                />
              </Form.Item>
            </S.RowContainer>
            <S.StepFooter>
              <StyledButton variant="slim" onClick={closeModal}>
                {t('common.cancel')}
              </StyledButton>

              <StyledButton
                variant="primary"
                loading={isLoading}
                htmlType="submit"
                disabled={isLoading}
              >
                {t('common.save')}
              </StyledButton>
            </S.StepFooter>
          </Form>
        </div>
      )}
    </S.Container>
  );
}
