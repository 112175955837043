import { Empty, Row } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import ExpandableItem from '../../../../components/Common/ExpandableItem';
import { StyledLoading } from '../../../../components/Common/StyledLoading';
import StyledSelect from '../../../../components/Common/StyledSelect';
import { Graphic } from './Graphic';
import * as S from './styles';
import { StartvsEndProps } from './types';
import { useStartvsEnd } from './useStartVsEnd';

export function StartVSEnd({ date, skus, level, cm, isFirstRendering }: StartvsEndProps) {
  const { t } = useTranslation();
  const { isOpen, toogleView, data, categories, setParams, params, isLoadingDataGraphic } =
    useStartvsEnd(date, skus, level, isFirstRendering, cm);

  return (
    <S.Container>
      <S.CardHeader>
        <Row>
          <ExpandableItem
            isOpen={isOpen}
            onClick={() => {
              toogleView();
            }}
          />
          <span>
            <b>{t('pages.finance.dashboard.startvsend.start')}</b> vs{' '}
            <b>{t('pages.finance.dashboard.startvsend.end')}</b> | {t('common.quarter')}{' '}
            {dayjs(date).quarter()} | <S.BlueText>{date.format('MMM YY')}</S.BlueText>
          </span>
        </Row>
        <StyledSelect
          options={categories}
          onChange={(e: string) =>
            setParams((prevState) => {
              return { ...prevState, category: e };
            })
          }
          value={params.category}
          placeholder={t('pages.finance.dashboard.startvsend.selectCategory')}
          showSearch
          styles={{ width: '8rem', alignSelf: 'end' }}
        />
      </S.CardHeader>
      {isOpen && (
        <S.Content>
          {data && data.length > 0 && skus.length > 0 ? (
            data.map((item) => {
              const matchingSku = skus?.find((sku) => sku.sku === item.sku);
              return (
                <Graphic
                  key={item.sku}
                  data={item}
                  startColor={matchingSku?.color}
                  partNumber={item.partNumber}
                />
              );
            })
          ) : isLoadingDataGraphic ? (
            <StyledLoading height={22.5} />
          ) : (
            <Empty />
          )}
        </S.Content>
      )}
    </S.Container>
  );
}
