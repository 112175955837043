import { Flex } from 'antd';
import styled from 'styled-components';

export const Header = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

export const Title = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
`;

export const SubTitle = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
`;

export const SupplierSpan = styled.span`
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
`;

export const UserContainer = styled.div`
  display: flex;
  padding-left: 0.25rem;
  flex-direction: row;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
  border-left: 0.125rem solid ${({ theme }) => theme.colorsDesignSystem.pipeSeparatorColor};

  span {
    color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
    font-size: 1.25rem;
    line-height: 1.25rem;
  }
`;

export const SelectContainer = styled(Flex)`
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

export const StatusTitle = styled.span`
  font-size: 1rem;
  font-weight: 600;
`;
