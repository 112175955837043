import styled from 'styled-components';

export const SupplierSpan = styled.span`
  font-size: 1.25rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
`;

export const UserContainer = styled.div`
  display: flex;
  padding-left: 0.25rem;
  flex-direction: row;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
  border-left: 0.125rem solid ${({ theme }) => theme.colorsDesignSystem.pipeSeparatorColor};

  span {
    color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
    font-size: 1.25rem;
  }
`;

export const SpanTitlePage = styled.span`
  font-weight: 600;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colorsDesignSystem.color};
`;

export const ContainerCards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12.5rem, 1fr));
  justify-items: center;
  gap: 1.5rem;
  margin-top: 8.31rem;
`;

export const PagesTitle = styled.span`
  font-weight: 700;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
`;

export const ContainerPages = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: center;
  gap: 3rem;
  margin-left: 3rem;
`;
