import { Divider } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import folderEmpty from '../../../../assets/folderEmpty.svg';
import StyledButton from '../../../../components/Common/StyledButton';
import { StyledCardAccepted } from '../../../../components/Common/StyledCardAccepted';
import * as S from './styles';
import { TermsAndConditonsProps } from './types';

export function TermsAndConditions({
  title,
  body,
  accept,
  status,
  firstAccess,
  details,
  review,
  accepted,
  deny,
}: TermsAndConditonsProps) {
  const { t } = useTranslation();
  return (
    <S.TermsConditionsContainer>
      <S.TermsConditionsHeader>
        <S.TermsConditionsCardTitle>
          <S.AuditIcon />
          {t('pages.bidSupplierRFQ.termsAndConditions.title')}
        </S.TermsConditionsCardTitle>
        {accept !== null && (
          <StyledCardAccepted
            status="denied"
            time={dayjs(details?.rejectDate).format('MM.DD.YYYY - hh:mma') || ''}
            user={`${details?.userReject} - ${details?.supplier}` || ''}
          />
        )}
      </S.TermsConditionsHeader>
      <S.TermsConditionsContent>
        {firstAccess ? (
          <>
            <Divider orientation="left" orientationMargin="0">
              <S.TermsConditionsContentTitle>{title}</S.TermsConditionsContentTitle>
            </Divider>
            <div
              dangerouslySetInnerHTML={{
                __html: body,
              }}
            />
          </>
        ) : status ? (
          <S.TermsConditionsDenied>
            <img src={folderEmpty} alt="Denied" width={127} />
            <S.DeniedMessage>
              {t('pages.bidSupplierRFQ.termsAndConditions.denyMessage')}
            </S.DeniedMessage>
            <S.DeniedSubMessage>
              {t('pages.bidSupplierRFQ.termsAndConditions.denySubmessage2')}
            </S.DeniedSubMessage>
          </S.TermsConditionsDenied>
        ) : (
          <S.TermsConditionsDenied>
            <img src={folderEmpty} alt="Denied" width={127} />
            <S.DeniedMessage>
              {t('pages.bidSupplierRFQ.termsAndConditions.denyMessage')}
            </S.DeniedMessage>
            <S.DeniedSubMessage>
              {t('pages.bidSupplierRFQ.termsAndConditions.denySubMessage')}
            </S.DeniedSubMessage>
            <StyledButton variant="primary" onClick={review}>
              {t('pages.bidSupplierRFQ.termsAndConditions.ViewTermsAndConditions')}
            </StyledButton>
          </S.TermsConditionsDenied>
        )}
      </S.TermsConditionsContent>
      {firstAccess && (
        <S.TermsConditionsFooter>
          <StyledButton variant="secondaryCancel" onClick={deny}>
            {t('pages.bidSupplierRFQ.termsAndConditions.deny')}
          </StyledButton>
          <StyledButton variant="primary" onClick={accepted}>
            {t('pages.bidSupplierRFQ.termsAndConditions.agreeAndContinue')}
          </StyledButton>
        </S.TermsConditionsFooter>
      )}
    </S.TermsConditionsContainer>
  );
}
