import { Flex, UploadFile } from 'antd';
import { HttpStatusCode } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledSelectOptions } from '../../components/Common/StyledSelect/types';
import { CancelMessage } from '../../components/Qualification/CancelMessage';
import { ComponentSpecifications } from '../../components/Qualification/ComponentSpecifications';
import { IData } from '../../components/Qualification/QualificationStatus/types';
import { IProcessPercent } from '../../components/UploadModal/UploadFilesModal/types';
import { useAlert } from '../../context/AlertContext';
import { QualificationStatus } from '../../helpers/enums';
import { formatDataToSelect, getSupplierUserName } from '../../helpers/nUtils';
import { getURI } from '../../helpers/utils';
import { api } from '../../services/api';
import { services } from '../../services/services';
import { TrackingNumberData } from './components/ModalNewTrackingNumber/types';
import { ODMDetails } from './components/ODMDetails';
import { SubmitUpdateInfo } from './components/SubmitUpdateInfo';
import { QualificationSupplierViewData, UploadedDatasheet } from './types';

export function useQualificationSupplierView() {
  const { openAlert } = useAlert();
  const { t } = useTranslation();

  const [qualificationData, setQualificationData] = useState<QualificationSupplierViewData>();
  const [isLoadingQualification, setIsLoadingQualification] = useState<boolean>();
  const [isLoadingStatus, setIsLoadingStatus] = useState<boolean>();
  const [steps, setSteps] = useState<IData[]>([]);
  const [qualificationOption, setQualificationOption] = useState<StyledSelectOptions[]>([]);
  const [selectedQualification, setSelectedQualification] = useState<string>();

  const [isLoadingTrackingNumber, setIsLoadingTrackingNumber] = useState<boolean>(false);
  const [isLoadingSubmitFile, setIsLoadingSubmitFile] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<IProcessPercent>({});

  const fetchQualificationsList = async () => {
    try {
      const { status, data } = await api.get<string[]>(`${services.qualification}/supplier/view`);
      if (status === HttpStatusCode.Ok) {
        setQualificationOption(formatDataToSelect(data, false));
        setSelectedQualification(data[0]);
      } else setQualificationOption([]);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchStatus = async () => {
    try {
      setIsLoadingStatus(true);
      const { status, data } = await api.get(
        `${services.qualification}/${qualificationData?.registerComponentId}/status`
      );
      if (status === 200) return setSteps(data);
      setSteps([]);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingStatus(false);
    }
  };

  const handleFetchQualificationData = async () => {
    try {
      setIsLoadingQualification(true);
      const { status, data } = await api.get(
        `${services.qualification}/supplier/view/details/${selectedQualification}`
      );
      if (status === 200) {
        setQualificationData(data);
      }
    } catch (error: any) {
      openAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setIsLoadingQualification(false);
    }
  };

  const saveTrackingNumber = async (data: TrackingNumberData) => {
    try {
      setIsLoadingTrackingNumber(true);
      const { status } = await api.post(
        `${services.qualification}/supplier/view/tracking/${selectedQualification}`,
        { ...data }
      );
      if (status === HttpStatusCode.Ok || status === HttpStatusCode.Created) {
        await handleFetchQualificationData();
      }
    } catch (error: any) {
      openAlert('error', error.message || t('toast.errorOnSave'));
    } finally {
      setIsLoadingTrackingNumber(false);
    }
  };

  const saveFiles = async (data: UploadFile[]) => {
    try {
      setIsLoadingSubmitFile(true);
      const formData = new FormData();

      data.forEach((file) => {
        formData.append('files', file.originFileObj as File);
      });

      const lastFileId = data[data.length - 1].uid;

      const response = await api.post<UploadedDatasheet[]>(
        getURI(
          `${services.qualification}/supplier/view/upload/${selectedQualification}?name=${
            getSupplierUserName()?.user
          }`
        ),
        formData,
        {
          onUploadProgress: (progressEvent) => {
            if (progressEvent.total) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress((prev) => ({
                ...prev,
                [lastFileId]: percentCompleted,
              }));
            }
          },
        }
      );

      setQualificationData({
        ...(qualificationData as QualificationSupplierViewData),
        uploadedDatasheet: response.data,
      });

      return response;
    } catch (error: any) {
      openAlert('error', error.message || t('toast.errorOnSave'));
    } finally {
      setIsLoadingSubmitFile(false);
    }
  };

  const renderCancelContent = () => {
    return (
      <CancelMessage
        QualificationID={qualificationData?.qualificationNameId || '-'}
        partNumber={qualificationData?.qualificationComponentItem.partNumber || '-'}
      />
    );
  };

  const renderConteianer = () => {
    return (
      <Flex gap={24} style={{ width: '100%' }}>
        <Flex vertical gap={24} style={{ width: '100%' }}>
          <ComponentSpecifications {...qualificationData?.qualificationComponentItem} />
          <ODMDetails odms={qualificationData?.odmsAssocieted} />
        </Flex>
        <SubmitUpdateInfo
          dataSheets={qualificationData?.uploadedDatasheet}
          position={0}
          trackingNumbers={qualificationData?.trackingNumbers}
          selectedQualification={selectedQualification || ''}
          isLoading={isLoadingTrackingNumber}
          onSaveTrackingNumber={saveTrackingNumber}
          isLoadingUploadFiles={isLoadingSubmitFile}
          saveFiles={saveFiles}
          uploadProgress={uploadProgress}
        />
      </Flex>
    );
  };

  const handleRenderContent = () => {
    if (steps.some((step) => step.status === QualificationStatus.CANCELED)) {
      return renderCancelContent();
    }
    return renderConteianer();
  };

  useEffect(() => {
    if (qualificationData) {
      fetchStatus();
    }
  }, [qualificationData]);

  useEffect(() => {
    if (selectedQualification) {
      handleFetchQualificationData();
    }
  }, [selectedQualification]);

  useEffect(() => {
    fetchQualificationsList();
  }, []);

  return {
    qualificationData,
    qualificationOption,
    selectedQualification,
    isLoadingQualification,
    isLoadingTrackingNumber,
    isLoadingSubmitFile,
    uploadProgress,
    isLoadingStatus,
    steps,
    handleRenderContent,
    setSelectedQualification,
    saveTrackingNumber,
    saveFiles,
  };
}
