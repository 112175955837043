import { DownOutlined, SearchOutlined, UpOutlined } from '@ant-design/icons';
import { Flex } from 'antd';
import styled from 'styled-components';

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  height: 3rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.registerComponents.white};
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.registerComponents.borderColor};
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
`;

export const ArrowUpStyled = styled(UpOutlined)`
  font-size: 1rem;
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
  cursor: pointer;
`;
export const ArrowDownStyled = styled(DownOutlined)`
  cursor: pointer;
  font-size: 1rem;
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
`;

export const LabelBlue = styled.label`
  font-size: 1rem;
  color: ${({ theme }) => theme.colorsDesignSystem.blue};
  line-height: 1.5rem;
  font-weight: 600;
`;

export const ContentContainerEditing = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2rem 1.5rem 2rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.tag_background};
  border-left: 1px solid ${({ theme }) => theme.colorsDesignSystem.registerComponents.borderColor};
  border-right: 1px solid ${({ theme }) => theme.colorsDesignSystem.registerComponents.borderColor};
  gap: 1.5rem;
`;

export const ContentContainerNoEditing = styled.div`
  border-radius: 0rem 0rem 0.75rem 0.75rem;
  border-right: 1px solid ${({ theme }) => theme.colorsDesignSystem.registerComponents.borderColor};
  border-bottom: 1px solid ${({ theme }) => theme.colorsDesignSystem.registerComponents.borderColor};
  border-left: 1px solid ${({ theme }) => theme.colorsDesignSystem.registerComponents.borderColor};

  display: flex;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

export const FlexHalfContent = styled(Flex)`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: end;
`;

export const LabelNewComponent = styled.span<{ visible: boolean }>`
  color: ${({ theme }) => theme.colorsDesignSystem.tabBarInactiveColor};
  white-space: nowrap;
  cursor: pointer;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

export const StyledIconSearch = styled(SearchOutlined)`
  font-size: 1rem;
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
`;

export const ContainerFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  height: 3rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.registerComponents.white};
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.registerComponents.borderColor};
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
`;

export const LabelPartNumber = styled.span`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colorsDesignSystem.registerComponents.blackLabels};

  &.clear {
    color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
  }
`;

export const FlexFortyPercent = styled(Flex)`
  display: flex;
  width: 40%;

  padding-left: 8%;
`;

export const FlexSixtyPercent = styled(Flex)`
  display: flex;
  width: 60%;
`;

export const LabelContent = styled.span`
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};

  &.strong {
    font-weight: 600;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
    font-size: 0.875rem;
  }
`;
