import { InfoCircleOutlined, PercentageOutlined } from '@ant-design/icons';
import { Col, Divider, Form, Row } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import find from '../../../assets/find.svg';
import { formatDataToSelect } from '../../../helpers/formatDataToSelect';
import { states } from '../../../helpers/utils';
import { Taxes } from '../../../pages/SettingsTaxes/types';
import { api } from '../../../services/api';
import theme from '../../../styles/theme';
import StyledInput from '../../Common/StyledInput';
import StyledRangePiker from '../../Common/StyledRangePicker';
import StyledSelect from '../../Common/StyledSelect';
import { StyledSelectOptions } from '../../Common/StyledSelect/types';
import * as S from './styles';
import { ContentNewTaxProps } from './types';

export default function ContentNewTax({ taxe, saveNewTax, form }: ContentNewTaxProps) {
  const [taxesGroup, setTaxesGroup] = useState<StyledSelectOptions[]>([]);
  const initialDisabled = {
    disableICMS: false,
    disablePISCOFINS: false,
  };
  const [disabledTaxes, setDisabledTaxes] = useState(initialDisabled);
  const SOURCES = [
    { label: 'FOB', value: 'FOB' },
    { label: 'BR', value: 'BR' },
  ];
  const [sourceSelected, setSourceSelected] = useState('FOB');

  const { t } = useTranslation();

  const handleTaxToEdit = () => {
    if (taxe) {
      form.setFieldsValue({
        ...taxe,
        taxes: taxe.taxes ? taxe.taxes[0] : null,
        source: taxe.source,
        ii: taxe.importTax,
        recoveryTaxDto: {
          recoveryTaxesIcms: String(taxe.recoveryTaxDto?.recoveryTaxesIcms),
          recoveryTaxesIpi: String(taxe.recoveryTaxDto?.recoveryTaxesIpi),
          recoveryTaxesPisCofins: String(taxe.recoveryTaxDto?.recoveryTaxesPisCofins),
          taxesRD: String(taxe.recoveryTaxDto?.taxesRD),
        },
        taxDto: {
          taxesIcms: String(taxe.taxDto?.taxesIcms),
          taxesIpi: String(taxe.taxDto?.taxesIpi),
          taxesPisCofins: String(taxe.taxDto?.taxesPisCofins),
        },

        period: [dayjs(taxe.periodFrom), dayjs(taxe.periodTo)],
      });
    } else {
      form.setFieldsValue({
        source: SOURCES[0].value,
        taxDto: {
          taxesIcms: '0.00',
          taxesIpi: '0.00',
          taxesPisCofins: '0.00',
        },
        recoveryTaxDto: {
          recoveryTaxesIcms: '0.00',
          recoveryTaxesIpi: '0.00',
          recoveryTaxesPisCofins: '0.00',
          taxesRD: '0.00',
        },
      });
    }
  };

  const fetchTaxes = async () => {
    try {
      const { status, data } = await api.get('/package/header/taxes');
      if (status === 200) {
        const formattedData = formatDataToSelect(data, false);
        setTaxesGroup(formattedData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDisableTaxes = (value: string) => {
    if (value) {
      let newDisableTaxes = initialDisabled;
      if (!value.toLowerCase().includes('icms')) {
        newDisableTaxes = {
          ...newDisableTaxes,
          disableICMS: true,
        };
        form.setFieldValue(['taxDto', 'taxesIcms'], '0.00');
        form.setFieldValue(['recoveryTaxDto', 'recoveryTaxesIcms'], '0.00');
      }
      if (!value.toLowerCase().includes('pis/cofins')) {
        newDisableTaxes = {
          ...newDisableTaxes,
          disablePISCOFINS: true,
        };
        form.setFieldValue(['taxDto', 'taxesPisCofins'], '0.00');
        form.setFieldValue(['recoveryTaxDto', 'recoveryTaxesPisCofins'], '0.00');
      }
      setDisabledTaxes(newDisableTaxes);
    }
  };

  useEffect(() => {
    handleTaxToEdit();
    fetchTaxes();
    if (taxe && taxe.taxes) handleDisableTaxes(taxe.taxes[0]);
  }, []);

  const onSelectsChange = (
    field:
      | 'taxes'
      | 'to'
      | 'componentType'
      | 'from'
      | 'period'
      | 'id'
      | 'recoveryTaxDto'
      | 'taxDto'
      | 'source',
    value: string
  ) => {
    form.setFieldValue([field], value);
  };

  const pattern = /^\d+(\.\d+)?$/;
  const patternFixDecimal = /^\d+\.\d{2}$/;

  const regexPattern = new RegExp(pattern);

  return (
    <S.container>
      <Divider orientation="left" orientationMargin="0" style={{ fontSize: '1.25rem' }}>
        {t('components.modals.addNewTax.description')}
      </Divider>
      <Form form={form} onFinish={saveNewTax} requiredMark>
        <Form.Item noStyle name="id" />
        <Row gutter={16}>
          <Col>
            <Form.Item
              name="componentType"
              rules={[
                {
                  required: true,
                  message: `${t('components.modals.addNewTax.partType')} ${t(
                    `common.validate.required`
                  )}`,
                },
              ]}
            >
              <StyledSelect
                type="COMPONENT_TYPE"
                styles={{ width: '13.87rem' }}
                value={form.getFieldValue('componentType')}
                suffixIcon={<img alt="" src={find} />}
                label={t('components.modals.addNewTax.partType')}
                showSearch
                placeholder={t('components.modals.addNewTax.selectComponentType')}
                onChange={(value: string) => {
                  if (value === 'PACKING') {
                    form.setFieldValue('from', 'SP');
                    form.setFieldValue('to', 'SP');
                  } else {
                    form.setFieldValue('taxesGroup', null);
                    setDisabledTaxes(initialDisabled);
                  }
                  onSelectsChange('componentType', value);
                }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="source">
              <StyledSelect
                styles={{ width: '12rem' }}
                options={SOURCES}
                value={form.getFieldValue('source')}
                label={t('components.modals.addNewTax.source')}
                onChange={(value: string) => {
                  onSelectsChange('source', value);
                  setSourceSelected(value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item shouldUpdate noStyle>
          {() => {
            if (form.getFieldValue('componentType') === 'PACKING') {
              return (
                <Form.Item
                  style={{ marginRight: '1.5rem' }}
                  name="taxes"
                  rules={[
                    {
                      required: true,
                      message: `${t('components.modals.addNewTax.taxesGroup')} ${t(
                        `common.validate.required`
                      )}`,
                    },
                  ]}
                  tooltip={{ title: 'Text', icon: <InfoCircleOutlined /> }}
                >
                  <StyledSelect
                    value={form.getFieldValue('taxes')}
                    label={t('components.modals.addNewTax.taxesGroup')}
                    onChange={(value: any) => {
                      onSelectsChange('taxes', value);
                      handleDisableTaxes(value);
                    }}
                    options={taxesGroup}
                    placeholder={t('components.modals.addNewTax.taxesGroup')}
                  />
                </Form.Item>
              );
            }
          }}
        </Form.Item>

        <S.logisticContainer>
          <Form.Item
            name="from"
            rules={[
              {
                required: true,
                message: `${t('components.modals.addNewTax.from')} ${t(
                  `common.validate.required`
                )}`,
              },
            ]}
          >
            <StyledSelect
              styles={{ width: '6.25rem' }}
              value={form.getFieldValue('from')}
              label={t('components.modals.addNewTax.from')}
              onChange={(value: any) => {
                onSelectsChange('from', value);
              }}
              options={states('no-state')}
              placeholder="SP"
            />
          </Form.Item>
          <Form.Item
            name="to"
            rules={[
              {
                required: true,
                message: `${t('components.modals.addNewTax.to')} ${t(`common.validate.required`)}`,
              },
            ]}
          >
            <StyledSelect
              value={form.getFieldValue('to')}
              placeholder="SP"
              styles={{ width: '6.25rem' }}
              label={t('components.modals.addNewTax.to')}
              onChange={(value: any) => {
                onSelectsChange('to', value);
              }}
              options={states()}
            />
          </Form.Item>
          {sourceSelected === 'FOB' && (
            <Form.Item
              name="ii"
              rules={[
                {
                  required: true,
                  message: `${t('components.modals.addNewTax.ii')} ${t(
                    `common.validate.required`
                  )}`,
                },
                {
                  pattern: regexPattern,
                  message: `${t('toast.invalidField')}`,
                },
                {
                  pattern: patternFixDecimal,
                  message: `${t('components.modals.addNewTax.formatTaxe')}`,
                },
              ]}
            >
              <StyledInput
                styles={{ width: '9rem' }}
                label={t('components.modals.addNewTax.ii')}
                placeholder={t('common.moneyPlaceholder')}
                addonAfter={<PercentageOutlined style={{ color: theme.colors.dark_green }} />}
              />
            </Form.Item>
          )}
        </S.logisticContainer>

        <S.onlyTaxesContainer>
          <Form.Item
            name={['taxDto', 'taxesIpi']}
            rules={[
              {
                required: true,
                message: `${t('components.modals.addNewTax.ipi')} ${t(`common.validate.required`)}`,
              },
              {
                pattern: regexPattern,
                message: `${t('toast.invalidField')}`,
              },
              {
                pattern: patternFixDecimal,
                message: `${t('components.modals.addNewTax.formatTaxe')}`,
              },
            ]}
          >
            <StyledInput
              styles={{ width: '9rem' }}
              label={t('components.modals.addNewTax.ipi')}
              placeholder={t('common.moneyPlaceholder')}
              addonAfter={<PercentageOutlined style={{ color: theme.colors.dark_green }} />}
            />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {() => {
              return (
                <Form.Item<Taxes>
                  name={['taxDto', 'taxesIcms']}
                  rules={[
                    {
                      required: true,
                      message: `${t('components.modals.addNewTax.icms')} ${t(
                        `common.validate.required`
                      )}`,
                    },
                    {
                      pattern: regexPattern,
                      message: `${t('toast.invalidField')}`,
                    },
                    {
                      pattern: patternFixDecimal,
                      message: `${t('components.modals.addNewTax.formatTaxe')}`,
                    },
                  ]}
                >
                  <StyledInput
                    styles={{ width: '9rem' }}
                    label={t('components.modals.addNewTax.icms')}
                    placeholder={t('common.moneyPlaceholder')}
                    disabled={disabledTaxes.disableICMS}
                    addonAfter={<PercentageOutlined style={{ color: theme.colors.dark_green }} />}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {() => {
              return (
                <Form.Item<Taxes>
                  name={['taxDto', 'taxesPisCofins']}
                  rules={[
                    {
                      required: true,
                      message: `${t('components.modals.addNewTax.pisConfins')} ${t(
                        `common.validate.required`
                      )}`,
                    },
                    {
                      pattern: regexPattern,
                      message: `${t('toast.invalidField')}`,
                    },
                    {
                      pattern: patternFixDecimal,
                      message: `${t('components.modals.addNewTax.formatTaxe')}`,
                    },
                  ]}
                >
                  <StyledInput
                    styles={{ width: '9rem' }}
                    label={t('components.modals.addNewTax.pisConfins')}
                    placeholder={t('common.moneyPlaceholder')}
                    addonAfter={<PercentageOutlined style={{ color: theme.colors.dark_green }} />}
                    disabled={disabledTaxes.disablePISCOFINS}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </S.onlyTaxesContainer>
        {/* recuperação de taxas */}
        <Divider orientation="left" orientationMargin="0">
          {t('components.modals.addNewTax.recoveryTaxes')}
        </Divider>
        <S.recoveryTaxesContainer>
          <Form.Item<Taxes>
            name={['recoveryTaxDto', 'recoveryTaxesIpi']}
            rules={[
              {
                required: true,
                message: `${t('components.modals.addNewTax.ipi')} ${t(`common.validate.required`)}`,
              },
              {
                pattern: regexPattern,
                message: `${t('toast.invalidField')}`,
              },
              {
                pattern: patternFixDecimal,
                message: `${t('components.modals.addNewTax.formatTaxe')}`,
              },
            ]}
          >
            <StyledInput
              styles={{ width: '9rem' }}
              label={t('components.modals.addNewTax.ipi')}
              placeholder={t('common.moneyPlaceholder')}
              addonAfter={<PercentageOutlined style={{ color: theme.colors.dark_green }} />}
            />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {() => {
              return (
                <Form.Item<Taxes>
                  name={['recoveryTaxDto', 'recoveryTaxesIcms']}
                  rules={[
                    {
                      required: true,
                      message: `${t('components.modals.addNewTax.icms')} ${t(
                        `common.validate.required`
                      )}`,
                    },
                    {
                      pattern: regexPattern,
                      message: `${t('toast.invalidField')}`,
                    },
                    {
                      pattern: patternFixDecimal,
                      message: `${t('components.modals.addNewTax.formatTaxe')}`,
                    },
                  ]}
                >
                  <StyledInput
                    styles={{ width: '9rem' }}
                    label={t('components.modals.addNewTax.icms')}
                    placeholder={t('common.moneyPlaceholder')}
                    addonAfter={<PercentageOutlined style={{ color: theme.colors.dark_green }} />}
                    disabled={disabledTaxes.disableICMS}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {() => {
              return (
                <Form.Item<Taxes>
                  name={['recoveryTaxDto', 'recoveryTaxesPisCofins']}
                  rules={[
                    {
                      required: true,
                      message: `${t('components.modals.addNewTax.pisConfins')} ${t(
                        `common.validate.required`
                      )}`,
                    },
                    {
                      pattern: regexPattern,
                      message: `${t('toast.invalidField')}`,
                    },
                    {
                      pattern: patternFixDecimal,
                      message: `${t('components.modals.addNewTax.formatTaxe')}`,
                    },
                  ]}
                >
                  <StyledInput
                    styles={{ width: '9rem' }}
                    label={t('components.modals.addNewTax.pisConfins')}
                    placeholder={t('common.moneyPlaceholder')}
                    addonAfter={<PercentageOutlined style={{ color: theme.colors.dark_green }} />}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item
            name={['recoveryTaxDto', 'taxesRD']}
            rules={[
              {
                required: true,
                message: `${t('components.modals.addNewTax.rd')} ${t(`common.validate.required`)}`,
              },
              {
                pattern: regexPattern,
                message: `${t('toast.invalidField')}`,
              },
              {
                pattern: patternFixDecimal,
                message: `${t('components.modals.addNewTax.formatTaxe')}`,
              },
            ]}
          >
            <StyledInput
              styles={{ width: '9rem' }}
              label={t('components.modals.addNewTax.rd')}
              placeholder={t('common.moneyPlaceholder')}
              addonAfter={<PercentageOutlined style={{ color: theme.colors.dark_green }} />}
            />
          </Form.Item>
        </S.recoveryTaxesContainer>

        <S.Label orientation="left" orientationMargin="0">
          {t('components.modals.addNewTax.period')}
        </S.Label>
        <S.periodContainer>
          <Form.Item
            name="period"
            rules={[
              {
                required: true,
                message: `${t('components.modals.addNewTax.periodRangePicker')} ${t(
                  `common.validate.required`
                )}`,
              },
            ]}
          >
            <StyledRangePiker label={t('components.modals.addNewTax.periodRangePicker')} />
          </Form.Item>
        </S.periodContainer>
      </Form>
    </S.container>
  );
}
