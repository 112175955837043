import { Row } from 'antd';
import styled from 'styled-components';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
`;

export const StepFooter = styled(Row)`
  align-items: center;
  justify-content: space-between;
`;
