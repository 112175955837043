import { Row } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  margin-top: 1rem;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 0.25rem;
  padding: 1rem;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  border-bottom: 1px solid ${({ theme }) => theme.colorsDesignSystem.border};

  span {
    font-size: 1rem;
    font-weight: 400;
    margin-left: 0.5rem;

    b {
      font-size: 1rem;
      font-weight: 700;
    }
  }
`;

export const BlueText = styled.span`
  color: ${({ theme }) => theme.colorsDesignSystem.blue};
`;

export const Content = styled(Row)`
  gap: 1rem;
  justify-content: center;
  padding: 2rem 1.25rem;
`;
