import { Row, Slider } from 'antd';
import styled from 'styled-components';
import StyledButton from '../../../components/Common/StyledButton';
import theme from '../../../styles/theme';

export const SearchButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  .icon {
    color: ${theme.colorsDesignSystem.primary};
    font-size: 0.875rem;
  }
`;

export const CellMoneySpan = styled.span`
  margin-right: 1rem;
`;
export const CellMoneyHeaderSpan = styled.span`
  margin-right: 0.3rem;
`;

export const BlueText = styled.p`
  color: #0c6dff;
  margin-bottom: 0;
`;

export const FiltersContainer = styled(Row)`
  display: flex;
  align-items: end;
  gap: ${theme.dimensions.gap};
  margin-bottom: 2.5rem;
  gap: 0.5rem;
`;

export const TableFiltersContainer = styled(Row)`
  gap: ${theme.dimensions.gap};
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
`;

export const BodyContainer = styled.div``;

export const ApplyButton = styled(StyledButton)`
  display: flex;
  align-items: end;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 38rem;
  width: 100%;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 1.688rem;

  .grafic {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ant-tooltip-inner {
    font-family: 'AcerFoco';
    color: ${theme.colorsDesignSystem.text};
    box-shadow: none;
    background-color: transparent;
  }
  .ant-tooltip-arrow {
    display: none;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  gap: ${theme.dimensions.gap};
  flex-direction: row;
  color: ${theme.colorsDesignSystem.text};
  size: bold;
  padding: 1rem;
  border-bottom: 0.063rem solid ${theme.colorsDesignSystem.border};
  gap: 0.5rem;
`;

export const CardLateralDiv = styled.div`
  width: 70%;
  margin-top: 2rem;
  overflow-y: none;
`;

export const CustomSlider = styled(Slider)`
  .ant-slider-track {
    background-color: ${theme.colorsDesignSystem.primary};
  }
`;

export const FiltersTagContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  gap: 0.375rem;
`;

export const SecondCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.25);
  height: 25.5rem;
  margin-bottom: 1.688rem;
  padding-bottom: 2rem;
`;

export const ThirdCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.25);
  margin-bottom: 1.688rem;
  padding-bottom: 2rem;
`;

export const CardsFiltersContainer = styled.div`
  display: flex;
  align-items: end;
  gap: 1rem;
`;
export const CardsFiltersTagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  margin: 1.5rem 1.5rem 0 1.5rem;
  gap: 0.375rem;
`;

export const TitleCards = styled.div`
  display: flex;
  align-items: center;
  gap: 0.65rem;
`;
export const TitleCadsWithFilter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`;

export const BodyGraphContainer = styled.div`
  max-height: 40rem;
  margin: 1.5rem;
  display: flex;
  border: 0.063rem solid ${theme.colorsDesignSystem.border};
  border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;
`;

export const GraphContainer = styled.div`
  width: 50%;
  max-height: 40rem;
  padding-bottom: 2.188rem;
  &:nth-child(1) {
    border-right: 0.063rem solid ${theme.colorsDesignSystem.border};
  }
`;

export const TitleGraphContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
`;

export const TitleGraph = styled.div`
  font-size: 1.5rem;
  margin-top: 1.5rem;

  &:nth-child(1) {
    margin-left: 3.75rem;
    font-weight: 600;
  }
  &:nth-child(3) {
    font-weight: 400;
  }
`;

export const SubTitleGraphContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  margin-bottom: 12px;
  gap: 5px;
`;

export const SubTitleGraph = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  color: ${theme.colorsDesignSystem.blue};
  &:nth-child(1) {
    margin-left: 3.75rem;
  }
`;

export const AverageIndication = styled.p`
  min-height: 0.75rem;
  color: ${theme.colorsDesignSystem.grayAcer};
  margin-left: 3.75rem;
`;

export const SpinContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40rem;
  padding-bottom: 2.188rem;
`;
