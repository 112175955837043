import { Flex } from 'antd';
import styled from 'styled-components';
import styledTheme from '../../../../styles/theme';

export const Container = styled.div`
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  height: max-content;
  width: 33rem;
`;

export const Padding = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 1.2rem 1.5rem;
`;

export const Header = styled(Flex)`
  gap: 0.75rem;
  font-size: 1.25rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  border-bottom: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  svg {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colorsDesignSystem.iconGrey};
  }
`;

export const Content = styled(Flex)`
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem 1.5rem;
`;

export const RankingContent = styled(Flex)`
  flex-direction: column;
  padding: 1.25rem 2rem;
  border-radius: 0.75rem;
  background-color: ${({ theme }) =>
    theme.colorsDesignSystem.newTablesColors.colorBackgroundSubItens};
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  gap: 0.5rem;
  span {
    font-size: 0.875rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
    line-height: 1.25rem;
  }
  .position {
    font-size: 2rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
    line-height: 1.75rem;
  }
`;

export const Component = styled(Flex)`
  flex-direction: column;
  padding: 1.25rem 1.5rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  border-radius: 0.75rem;
`;

export const ComponentTile = styled(Flex)<{ status?: 'ok' | 'warning' }>`
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 1rem;
  cursor: pointer;
  color: ${({ theme, status }) =>
    status === 'ok'
      ? theme.colorsDesignSystem.tabBarInactiveColor
      : theme.colorsDesignSystem.inputLabelColor};
  .icon {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colorsDesignSystem.iconGrey};
  }
  img {
    width: 1rem;
    height: 1rem;
  }
  .warning {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colorsDesignSystem.tagColorYellow};
  }
`;

export const TrackingContainer = styled(Flex)`
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const TrackingContent = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  border-radius: 0.75rem;
  padding: 1rem 1.5rem;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const TrackingIcon = styled.div`
  padding: 0.625rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colorsDesignSystem.neutralGray};
  svg {
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colorsDesignSystem.tabBarInactiveColor};
  }
`;

export const TrackingNumber = styled(Flex)`
  flex-direction: column;
  gap: 0.25rem;
  .label {
    font-size: 0.75rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
    line-height: 1rem;
  }
  .trackingNumber {
    font-size: 1rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colorsDesignSystem.tabBarInactiveColor};
    line-height: 1.375rem;
  }
`;

const FontColor = {
  New: styledTheme.colorsDesignSystem.qualificationTagColors.goldenYellow,
  Updated: styledTheme.colorsDesignSystem.qualificationTagColors.darkGray,
  Delivered: styledTheme.colorsDesignSystem.qualificationTagColors.brightGreen,
};

export const TrackingTag = styled(Flex)<{ status: 'New' | 'Updated' | 'Delivered' }>`
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ status }) => FontColor[status]};
  .circle {
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 50%;
    background-color: ${({ status }) => FontColor[status]};
  }
`;
