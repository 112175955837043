import { useState } from 'react';
import { StyledSelectOptions } from '../../../../components/Common/StyledSelect/types';
import { useAlert } from '../../../../context/AlertContext';
import { FreightForwarderEnum } from '../../../../helpers/enums';
import { formatDataToSelect } from '../../../../helpers/nUtils';
import { api } from '../../../../services/api';
import { services } from '../../../../services/services';

export function useModalTrackingNumber() {
  const { openAlert } = useAlert();
  const [isLoadingOptions, setIsLoadingOptions] = useState<boolean>(false);
  const forWarderOptions: Array<StyledSelectOptions> = [
    {
      label: FreightForwarderEnum.Fedex,
      value: FreightForwarderEnum.Fedex,
    },
    {
      label: FreightForwarderEnum.DHL,
      value: FreightForwarderEnum.DHL,
    },
  ];
  const [odmOptions, setOdmOptions] = useState<StyledSelectOptions[]>([]);

  const handleGetOdmFilterData = async (qualification: string) => {
    try {
      setIsLoadingOptions(true);
      const { data, status } = await api.get(
        `${services.qualification}/supplier/view/odm/filter/${qualification}`
      );
      if (status === 200) {
        return setOdmOptions(formatDataToSelect(data, false));
      }
      setOdmOptions([]);
    } catch (error: any) {
      openAlert('error', error.message);
    } finally {
      setIsLoadingOptions(false);
    }
  };

  return {
    odmOptions,
    forWarderOptions,
    handleGetOdmFilterData,
    isLoadingOptions,
  };
}
