import {
  ContainerOutlined,
  ExclamationCircleFilled,
  FileAddOutlined,
  PlusOutlined,
  TagOutlined,
  TruckOutlined,
} from '@ant-design/icons';
import { Button, Flex, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import ExclamationCircleSvg from '../../../../assets/exclamation-circle.svg';
import StyledButton from '../../../../components/Common/StyledButton';
import StyledModal from '../../../../components/Common/StyledModal';
import { SampleTrackingNumberModal } from '../../../../components/Qualification/SampleTrackingNumberModal';
import { UploadedFilesModal } from '../../../../components/UploadModal/UploadedFilesModal';
import { UploadFilesModal } from '../../../../components/UploadModal/UploadFilesModal';
import { formatNumberToOrdinal } from '../../../../helpers/FormatValues';
import ModalNewTrackingNumber from '../ModalNewTrackingNumber';
import * as S from './styles';
import { RoundDetailsProps } from './types';
import { useSubmitUpdateInfo } from './useSubmitUpdateInfo';
import { ModalTrackingNumberDetails } from '../ModalTrackinNumberDetails';

export function SubmitUpdateInfo({
  dataSheets,
  position,
  trackingNumbers,
  isLoading,
  onSaveTrackingNumber,
  selectedQualification,
  isLoadingUploadFiles,
  saveFiles,
  uploadProgress,
}: RoundDetailsProps) {
  const { t } = useTranslation();
  const {
    setVisibleModal,
    visibleModal,
    form,
    isUploadModalOpen,
    setIsUploadModalOpen,
    clearFiles,
    handleUploadFile,
    removeFile,
    updateFilesState,
    fileList,
    isUploadedFilesModalOpen,
    setIsUploadedFilesModalOpen,
    addProcessingError,
    isTrackingNumberModalOpen,
    setIsTrackingNumberModalOpen,
    trackingSelected,
    setTrackingSelected,
  } = useSubmitUpdateInfo();

  return (
    <S.Container>
      <S.Header>
        <S.Padding>
          <ContainerOutlined />
          {t('pages.qualificationSupplierView.submitInfo.title')}
        </S.Padding>
      </S.Header>
      <S.Content>
        <S.RankingContent>
          <span>{t('pages.qualificationSupplierView.submitInfo.submissionRanking')}</span>
          <span className="position">{position ? formatNumberToOrdinal(position) : '-'}</span>
        </S.RankingContent>
        <S.Component>
          <S.ComponentTile
            status={dataSheets && dataSheets.length > 0 ? 'ok' : 'warning'}
            onClick={() => setIsUploadedFilesModalOpen(true)}
          >
            <Flex gap={8} align="center">
              <FileAddOutlined className="icon" />
              <span>{t('pages.qualificationSupplierView.submitInfo.datasheet')}</span>
            </Flex>
            {dataSheets && dataSheets.length > 0 ? (
              <img src={ExclamationCircleSvg} alt="" />
            ) : (
              <ExclamationCircleFilled className="warning" />
            )}
          </S.ComponentTile>
          <StyledButton variant="secondary" onClick={() => setIsUploadModalOpen(true)}>
            {t('pages.qualificationSupplierView.submitInfo.uploadFile')}
          </StyledButton>
        </S.Component>
        <S.Component>
          <S.ComponentTile>
            <Flex align="center" gap={8}>
              <TruckOutlined className="icon" />
              <span>{t('pages.qualificationSupplierView.submitInfo.sampleTrackingNumber')}</span>
            </Flex>
            {trackingNumbers && trackingNumbers.length > 0 ? (
              <img src={ExclamationCircleSvg} alt="" />
            ) : (
              <ExclamationCircleFilled className="warning" />
            )}
          </S.ComponentTile>
          {trackingNumbers && (
            <S.TrackingContainer>
              {trackingNumbers.map((tracking) => {
                return (
                  <S.TrackingContent
                    key={tracking.trackingNumber}
                    onClick={() => {
                      setTrackingSelected(tracking.trackingNumber);
                      setIsTrackingNumberModalOpen(true);
                    }}
                  >
                    <Flex align="center" gap={16}>
                      <S.TrackingIcon>
                        <TagOutlined />
                      </S.TrackingIcon>
                      <S.TrackingNumber>
                        <span className="label">
                          {t('pages.qualificationSupplierView.submitInfo.trackingNumber')}
                        </span>
                        <span className="trackingNumber">{tracking.trackingNumber}</span>
                      </S.TrackingNumber>
                    </Flex>
                    <S.TrackingTag status={tracking.status}>
                      <div className="circle" />
                      {tracking.status}
                    </S.TrackingTag>
                  </S.TrackingContent>
                );
              })}
            </S.TrackingContainer>
          )}
          <StyledButton
            variant="secondary"
            iconLeft={<PlusOutlined />}
            onClick={() => {
              setVisibleModal(true);
            }}
          >
            {t('pages.qualificationSupplierView.submitInfo.addTrackingNumber')}
          </StyledButton>
        </S.Component>
      </S.Content>
      <StyledModal
        key="modal-newTrackingNumber"
        title={t('pages.qualificationSupplierView.modal.title')}
        open={visibleModal}
        onClose={() => {
          setVisibleModal(false);
        }}
        footer={
          <Row justify="space-between">
            <Button
              type="link"
              onClick={() => {
                setVisibleModal(false);
                form.resetFields();
              }}
            >
              {t('common.cancel')}
            </Button>
            <Button
              type="primary"
              loading={isLoading}
              onClick={() => {
                form.validateFields().then(() => {
                  onSaveTrackingNumber(form.getFieldsValue(true));
                });
              }}
            >
              {t('common.confirm')}
            </Button>
          </Row>
        }
        body={<ModalNewTrackingNumber form={form} selectedQualification={selectedQualification} />}
        width="34rem"
      />

      <StyledModal
        key="uploadDatasheetFile"
        body={
          <UploadFilesModal
            title="Datasheet file submission"
            loading={isLoadingUploadFiles}
            handleChange={handleUploadFile}
            removeFile={removeFile}
            fileList={fileList}
            fileIdDelete="fileIdDelete"
            uploadedFiles={dataSheets?.map((item) => {
              return {
                fileName: item.fileName,
                response: 'done',
                sendDate: item.uploadDate,
                supplier: item.supplier,
                uploadedFileId: '',
                urlDownloaded: item.pathFileS3,
                userUploaded: item.resposibleSupplier,
              };
            })}
            acceptedExtensions=".csv,.xls,.xlsx,.ods,.doc,.doscx,.txt,.pdf"
            uploadProgress={uploadProgress}
          />
        }
        footer={
          <Flex justify="space-between">
            <StyledButton
              variant="slim"
              disabled={isLoadingUploadFiles}
              onClick={() => {
                clearFiles();
                setIsUploadModalOpen(false);
              }}
            >
              {t('common.cancel')}
            </StyledButton>
            <StyledButton
              variant="primary"
              onClick={() =>
                fileList.length > 0
                  ? saveFiles(fileList)
                      .then((response) => updateFilesState(response?.data))
                      .catch(() => addProcessingError())
                  : setIsUploadModalOpen(false)
              }
              disabled={isLoadingUploadFiles}
            >
              {fileList.length > 0 ? t('common.send') : t('common.close')}
            </StyledButton>
          </Flex>
        }
        onClose={() => {
          clearFiles();
          setIsUploadModalOpen(false);
        }}
        title={t('pages.bidSupplierRFQ.quotation.modal.title')}
        centered
        open={isUploadModalOpen}
        width="34rem"
      />
      <StyledModal
        key="uploadedDatasheetFile"
        body={
          <UploadedFilesModal
            title="Uploaded files"
            uploadedFiles={dataSheets?.map((item) => {
              return {
                fileName: item.fileName,
                response: 'done',
                sendDate: item.uploadDate,
                supplier: item.supplier,
                uploadedFileId: '',
                urlDownloaded: item.pathFileS3,
                userUploaded: item.resposibleSupplier,
              };
            })}
          />
        }
        footer={null}
        onClose={() => {
          clearFiles();
          setIsUploadedFilesModalOpen(false);
        }}
        title="Datasheet"
        centered
        open={isUploadedFilesModalOpen}
        width="34rem"
      />
      {isTrackingNumberModalOpen && (
        <StyledModal
          key="trackingNumber"
          title={t('components.modals.sampleTrackingNumber.title')}
          body={
            <ModalTrackingNumberDetails
              trackingNumber={trackingSelected}
              qualificationNameId={selectedQualification}
            />
          }
          footer={null}
          open={isTrackingNumberModalOpen}
          centered
          onClose={() => setIsTrackingNumberModalOpen(false)}
          width="55rem"
          height="43rem"
          noMargin
          noPadding
        />
      )}
    </S.Container>
  );
}
