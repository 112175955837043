import { Divider, Flex } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
`;

export const Padding = styled.div`
  padding: 1.2rem 1.5rem;
  display: flex;
  gap: 0.5rem;
`;

export const Header = styled(Flex)`
  gap: 0.75rem;
  font-size: 1.25rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  border-bottom: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
`;

export const Content = styled(Flex)`
  flex-direction: column;
  padding: 2rem;
  gap: 2rem;
  .copySvg {
    width: 1rem;
    height: 1rem;
    color: ${({ theme }) => theme.colorsDesignSystem.primary};
    cursor: pointer;
  }
`;

export const CardInfos = styled(Flex)`
  flex-direction: column;
  padding: 1.25rem 2rem;
  border-radius: 0.75rem;
  background-color: ${({ theme }) =>
    theme.colorsDesignSystem.newTablesColors.colorBackgroundSubItens};
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  gap: 0.5rem;
  span {
    font-size: 0.875rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
    line-height: 1.25rem;
    &.title {
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 1.75rem;
      color: ${({ theme }) => theme.colorsDesignSystem.text};
    }
  }
  &.small {
    width: 26%;
  }
  &.normal {
    width: 40%;
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 0;
`;

export const Labels = styled.span`
  font-size: 1rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colorsDesignSystem.black85};
  &.subtitle {
    font-size: 0.75rem;
    font-weight: 400;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
    line-height: 132%; /* 0.99rem */
    letter-spacing: -0.0135rem;
  }
  &.values {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }
`;
