import { Steps } from 'antd';
import { useEffect } from 'react';
import * as S from './styles';
import { IQualificationStatus } from './types';
import { useQualificationStatus } from './useQualificationStatus';

export function QualificationStatus({ data }: IQualificationStatus) {
  const { steps, formatSteps } = useQualificationStatus();

  useEffect(() => {
    formatSteps(data);
  }, [data]);

  return (
    <S.StepsContent>
      <Steps items={steps} />
    </S.StepsContent>
  );
}
