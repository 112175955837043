import { DollarOutlined, SearchOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.registerComponents.borderColor};
`;

//styles do primeiro form
export const ContentForm = styled.div`
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;

  ::-webkit-scrollbar {
    width: 1.125rem;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.625rem 0.625rem ${({ theme }) => theme.colorsDesignSystem.grayBorder};
    border: solid 0.375rem transparent;
    border-radius: 624rem;
  }
  ::-webkit-scrollbar-thumb {
    background: transparent;
    box-shadow: inset 0 0 0.625rem 0.625rem ${({ theme }) => theme.colorsDesignSystem.placeHolder};
    border: solid 0.375rem transparent;
    -webkit-border-radius: 624rem;
    border-radius: 624rem;
  }
`;

export const StepFooter = styled(Row)`
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colorsDesignSystem.border};
  padding: 1rem 2.5rem;
`;

export const BasicInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 2.5rem 0 2.5rem;
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.registerComponents.borderColor};
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
`;

export const ContainerSelects = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.75rem;
  align-items: center;
`;

export const StyledIconSearch = styled(SearchOutlined)`
  font-size: 1rem;
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
`;

export const ContainerCards = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2.5rem;
  gap: 1.5rem;
`;

export const SuffixIcon = styled(DollarOutlined)`
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
`;
