import { RuleObject } from 'antd/es/form';
import dayjs from 'dayjs';

export function useAssociatedOdms() {
  const disabledDate = (current: dayjs.Dayjs) => {
    // Impede datas anteriores ao dia atual
    return current && current.isBefore(dayjs().startOf('day'));
  };
  return {
    disabledDate,
  };
}
