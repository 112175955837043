import { Form, Tooltip } from 'antd';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAlert } from '../../../context/AlertContext';
import { getColumnOptionProps, getColumnSearchProps } from '../../../helpers/table';
import {
  filterAndUrlParams,
  getErrorMessage,
  getSortOrder,
  getURI,
  hasPropertyWithHeader,
  isFobOrBr,
  parseBaseScenarios,
  parseScenariosLite,
  renameObjKeys,
} from '../../../helpers/utils';
import { api } from '../../../services/api';

export function useReportsScenarios(dataModal = null) {
  const { openAlert } = useAlert();
  const localTargetYear = localStorage.getItem('targetYear') || new Date().getFullYear().toString();
  const initialParams = {
    order: '',
    orderBy: '',
    page: 0,
    limit: 25,
    targetYear: localTargetYear,
    scenario: '',
    sku: '',
    spec: [],
    partType: [],
    partNumber: [],
    componentAmount: '',
    local: '',
  };

  //Modal must replace all others params
  if (dataModal && dataModal.scenario && dataModal.targetYear) {
    initialParams.scenario = dataModal.scenario;
    initialParams.targetYear = dataModal.targetYear;
  }

  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');

  const [form] = Form.useForm();
  const searchInput = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isNewItem, setIsNewItem] = useState(false);
  const [canUpdate, setCanUpdate] = useState(true);
  const [selectedScenario, setSelectedScenario] = useState();
  const [params, setParams] = useState(initialParams);
  const [scenariosOptions, setScenariosOptions] = useState([]);

  const [pageControl, setPageControl] = useState({
    pageLabel: '1',
    totalPages: 0,
    totalElements: 0,
  });

  const fetchScenarios = async () => {
    if (!dataModal) {
      try {
        const allParams = filterAndUrlParams(params);

        const { data: content } = await api.get(
          `scenarios/lite?targetYear=${allParams.targetYear}`
        );

        const selectScenarios = parseScenariosLite(content);

        if (selectScenarios?.length) {
          if (!allParams.scenario) {
            const localScenario = localStorage.getItem('scenario');
            const scenarioExist = selectScenarios.find((i) => i.value === localScenario);

            if (!localScenario || !scenarioExist) {
              allParams.scenario = selectScenarios[0]?.value;
            } else {
              allParams.scenario = localScenario;
            }
          }

          if (allParams.scenario && allParams.targetYear) {
            localStorage.setItem('scenario', allParams.scenario);
            localStorage.setItem('targetYear', allParams.targetYear);
          }

          setSelectedScenario(selectScenarios.find((i) => i.value === allParams?.scenario));
          setScenariosOptions(selectScenarios);
          setParams(allParams);
        } else {
          setScenariosOptions([]);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        const {
          data: { content },
        } = await api.get(`scenarios?targetYear=${params.targetYear}`);

        const selectScenarios = parseBaseScenarios(content);
        if (selectScenarios?.length) {
          setSelectedScenario(selectScenarios.find((i) => i.value === params?.scenario));
        }
      } catch (error) {
        openAlert('error', getErrorMessage(error) || t('toast.missingScenario'));
      }
    }
  };

  function getReportScenariosParams() {
    const getParams = { ...params };
    getParams.scenario = null;
    getParams.targetYear = null;

    const updatedParams = renameObjKeys(
      {
        spec: 'header_spec',
        partType: 'header_partType',
        partNumber: 'header_partNumber',
        sku: 'header_sku',
      },
      getParams
    );

    return updatedParams;
  }

  const fetchReportsScenarios = async () => {
    if (params && !!params.scenario) {
      //change filter params
      const updatedParams = getReportScenariosParams();

      try {
        if (!isLoading) setIsLoading(true);

        const {
          data: { content, totalElements, totalPages },
        } = await api.get(
          getURI(`scenarios/${params.scenario}/components/report`, {
            ...updatedParams,
            order: params.order,
            orderBy: params.orderBy,
          })
        );

        if (params.scenario && params.targetYear) {
          localStorage.setItem('scenario', params.scenario);
          localStorage.setItem('targetYear', params.targetYear);
        }

        const dataContent = content?.length ? content : [];
        setData(dataContent);

        if (scenariosOptions?.length && params?.scenario) {
          setSelectedScenario(scenariosOptions.find((i) => i.value === params?.scenario));
        }

        setPageControl({
          pageLabel: params.page + 1,
          totalPages,
          totalElements,
        });

        setIsLoading(false);
      } catch (error) {
        setData([]);
        setIsLoading(false);
      }
    }
  };

  const changePage = (page, pageSize) => {
    setParams({ ...params, page: page - 1, limit: pageSize });
    setPageControl({ ...pageControl, pageLabel: page, pageSize });
  };

  const handleChangeTable = (pagination, filters, sorter) => {
    if (data.length === 0) return;
    setParams({
      ...params,
      order: sorter.columnKey,
      orderBy: getSortOrder(sorter.order),
      page: 0,
    });
  };

  const onValuesChange = (changedValues) => {
    if (isNewItem) return;

    const month = Object.keys(changedValues)[0];
    const currentData = [...data];
    const currentItem = currentData.find((item) => item?.id === editingKey);
    const isEdited = currentItem[month]?.amount !== changedValues[month]?.amount;

    form.setFieldsValue({
      [month]: { amount: changedValues[month]?.amount, edited: isEdited },
    });
  };

  const searchFilter = async (value, filterName = '') => {
    setCanUpdate(filterName === 'submit');

    const filterParams = {
      ...params,
      [filterName]: value,
      page: 0,
    };

    console.log(filterName, filterParams);

    //Reset dependent filters
    if (filterName === 'targetYear') {
      filterParams.scenario = null;
    }

    setParams(filterParams);
    // setCanUpdate(true);
  };

  const clearFilter = async () => {
    setCanUpdate(false);

    setParams({
      ...initialParams,
      targetYear: null,
      scenario: null,
      sku: null,
      partType: [],
      spec: [],
      partNumber: [],
      componentAmount: '',
      local: '',
    });
  };

  const handleHeaderReset = (dataIndex) => {
    const filterParams = { ...params, page: 0 };
    delete filterParams[`header_${dataIndex}`];

    if (!hasPropertyWithHeader(filterParams)) {
      delete filterParams.tipoBusca;
    }

    setParams(filterParams);
  };

  const handleCloseFilteredTag = (field, idx) => {
    if (field?.includes('header_')) {
      const dataIndex = field.replace('header_', '');
      handleHeaderReset(dataIndex);
    } else {
      let newValue;

      if (Array.isArray(params[field])) {
        newValue = [...params[field]];
        newValue.splice(idx, 1);
      } else {
        newValue = '';
      }

      setParams({
        ...params,
        [field]: newValue,
        page: 0,
      });
    }
  };

  const handleHeaderSearch = (dataIndex, formValue) => {
    if (dataIndex === 'local' && formValue?.local?.length) {
      const filterParams = {
        ...params,
        header_local: formValue.local.join(','),
        tipoBusca: 'dinamica',
      };

      setParams(filterParams);
    } else if (dataIndex !== 'local' && formValue?.length) {
      const filterParams = {
        ...params,
        [`header_${dataIndex}`]: formValue.trim(),
        tipoBusca: 'dinamica',
      };

      setParams(filterParams);
    } else if (!formValue) {
      handleHeaderReset(dataIndex);
    }
  };

  const transformData = (dataSource) => {
    const updatedData = dataSource.map((item) => ({
      ...item,
      local: isFobOrBr(item.local),
    }));

    return updatedData;
  };

  const columns = [
    {
      title: t('pages.reports.scenarios.fields.sku'),
      dataIndex: ['sku'],
      key: 'sku',
      width: '10rem',
      ...getColumnSearchProps({
        dataIndex: 'sku',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        searchInput,
      }),
    },
    {
      title: t('pages.reports.scenarios.fields.partType'),
      label: t('pages.reports.scenarios.fields.partType'),
      dataIndex: 'partType',
      key: 'partType',
      width: '10rem',
      ...getColumnSearchProps({
        dataIndex: 'partType',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        searchInput,
      }),
    },
    {
      title: t('pages.reports.scenarios.fields.spec'),
      dataIndex: 'spec',
      key: 'spec',
      width: '28rem',
      label: t('pages.reports.scenarios.fields.spec'),
      ...getColumnSearchProps({
        dataIndex: 'spec',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        searchInput,
      }),
    },
    {
      title: t('pages.reports.scenarios.fields.partNumber'),
      label: t('pages.reports.scenarios.fields.partNumber'),
      dataIndex: 'partNumber',
      key: 'partNumber',
      width: '10rem',
      ...getColumnSearchProps({
        dataIndex: 'partNumber',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        searchInput,
      }),
    },
    {
      title: t('pages.reports.scenarios.fields.componentAmount'),
      label: t('pages.reports.scenarios.fields.componentAmount'),
      dataIndex: 'componentAmount',
      key: 'componentAmount',
      width: '8.75rem',
      render: (value) => {
        return <div style={{ textAlign: 'center' }}>{value}</div>;
      },
      ...getColumnSearchProps({
        dataIndex: 'componentAmount',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        searchInput,
      }),
    },
    {
      title: t('pages.reports.scenarios.fields.local'),
      label: t('pages.reports.scenarios.fields.local'),
      dataIndex: 'local',
      key: 'local',
      width: '7.5rem',
      render: (value, row) => {
        const hasValue = parseInt(value, 10) >= 0;
        const local = isFobOrBr(value.toString());
        return Object.hasOwn(row, 'local') && hasValue ? (
          <Tooltip title={value}>{local}</Tooltip>
        ) : null;
      },
      ...getColumnOptionProps({
        dataIndex: 'local',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        options: [
          { label: 'FOB', value: 'FOB' },
          { label: 'BR', value: 'BR' },
        ],
      }),
    },
  ];

  const csvHeaders = [
    { label: t('pages.reports.scenarios.fields.sku'), key: 'sku' },
    { label: t('pages.reports.scenarios.fields.partType'), key: 'partType' },
    { label: t('pages.reports.scenarios.fields.spec'), key: 'spec' },
    { label: t('pages.reports.scenarios.fields.partNumber'), key: 'partNumber' },
    { label: t('pages.reports.scenarios.fields.componentAmount'), key: 'componentAmount' },
    { label: t('pages.reports.scenarios.fields.local'), key: 'local' },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        // inputType: col.dataIndex === 'componentAmount' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  return {
    data,
    params,
    pageControl,
    fetchScenarios,
    scenariosOptions,
    canUpdate,
    selectedScenario,
    csvHeaders,
    mergedColumns,
    fetchReportsScenarios,
    handleChangeTable,
    isLoading,
    form,
    isNewItem,
    onValuesChange,
    searchFilter,
    clearFilter,
    handleCloseFilteredTag,
    getReportScenariosParams,
    transformData,
    changePage,
  };
}
