import { t } from 'i18next';
import { ReactNode, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CardSuppliers } from '../../components/Common/CardBidSuplliers';
import { BidSuppliers } from '../../components/Common/CardBidSuplliers/types';
import { useAlert } from '../../context/AlertContext';
import { BidStatus } from '../../helpers/enums';
import { getURI } from '../../helpers/utils';
import { api } from '../../services/api';
import { services } from '../../services/services';
import CardDistribution from './CardDistribution';
import { AwardDistribution } from './CardDistribution/types';
import { AwardPageData, ReceivedBidSupplier } from './types';

export function useAwardSimulation() {
  const { openAlert } = useAlert();
  const [awardData, setAwardData] = useState<AwardPageData>();
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const { id: bidId } = useParams<{ id: string }>();

  const handleConvertTotalBidSupplierToBidSupplier = (
    bidSupplierReceived: Array<ReceivedBidSupplier>
  ): Array<BidSuppliers> => {
    try {
      return bidSupplierReceived.map((supplier) => {
        return {
          supplier: supplier.name,
          impact: supplier.impactSavingPercentage,
          saving: supplier.impactSavingEstimate,
          totalWin: supplier.totalNet,
          demand: supplier.demand,
        };
      });
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const fetchAward = async () => {
    try {
      setLoading(true);
      const { data, status } = await api.get(`${services.rfq}/award-simulation/${bidId}`);
      if (status === 200) {
        setAwardData({
          ...data,
          totalBIDSuppliers: handleConvertTotalBidSupplierToBidSupplier(data.totalBIDSuppliers),
        });
        return;
      }
    } catch (error) {
      openAlert('error', t('toast.errorOnList'));
    } finally {
      setLoading(false);
    }
  };

  const handleSendAward = async (message: string) => {
    try {
      setLoading(true);
      const { status } = await api.post(
        getURI(`${services.rfq}/${bidId}/send-award`, {
          awardMessage: message,
        })
      );
      if (status === 200) {
        openAlert('success', t('pages.awardSimulation.modalSendAwards.successMessage'));
        fetchAward();
      }
    } catch (error) {
      openAlert('error', t('toast.errorOnSave'));
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRenderBidSuppliersCard = (values: Array<BidSuppliers>): ReactNode => {
    try {
      return values.map((card) => (
        <CardSuppliers
          supplier={card.supplier}
          demand={card.demand}
          impact={card.impact}
          totalWin={card.totalWin}
          saving={card.saving}
          key={card.supplier}
        />
      ));
    } catch (error) {
      return null;
    }
  };

  const handleRenderDistributionCard = (values: Array<AwardDistribution>): ReactNode => {
    try {
      return values.map((card) => (
        <CardDistribution
          demand={card.demand}
          bidStatus={awardData?.status}
          impactSaving={card.impactSaving}
          projectList={card.projectList}
          reviewedIn={card.reviewedIn}
          altGroup={card.altGroup}
          totalNet={card.totalNet}
          totalProjects={card.totalProjects}
          impactSavingPercentage={card.impactSavingPercentage}
          taxesEmpty={card.taxesEmpty}
          key={card.altGroup}
          bidId={bidId}
          //o altGroup será considerado como preenchido quando existir a data de review
          answered={!!card.reviewedIn}
        />
      ));
    } catch (error) {
      return null;
    }
  };

  const handleVerifyIfCanSendAward = (): boolean => {
    if (awardData?.status === BidStatus.RESULT) return true;
    return awardData?.awardDistribution?.some((award) => !award.reviewedIn) ?? false;
  };

  return {
    fetchAward,
    handleConvertTotalBidSupplierToBidSupplier,
    handleRenderBidSuppliersCard,
    handleRenderDistributionCard,
    handleVerifyIfCanSendAward,
    setVisibleModal,
    handleSendAward,
    visibleModal,
    awardData,
    loading,
    bidId,
  };
}
