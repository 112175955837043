import { Form, Input, Popconfirm } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { ActionButton } from '../components/ActionButton';
import { useAlert } from '../context/AlertContext';
import { ValidateMessages } from '../helpers/ValidateMessages';
import * as S from '../pages/Prices/styles';
import { FormData } from './types';

export function useHiddenPrices() {
  const { openAlert } = useAlert();
  const { t } = useTranslation();
  const validateMessages = ValidateMessages();
  const localHiddenPrices = localStorage.getItem('hiddenPrices') === 'true';
  const [hiddenPrices, setHiddenPrices] = useState(localHiddenPrices);
  const [showPassword, setShowPassword] = useState(false);

  const handleHiddenPrices = () => {
    if (!hiddenPrices) {
      setHiddenPrices(true);
      localStorage.setItem('hiddenPrices', 'true');
    } else {
      setShowPassword(true);
    }
  };

  const handleHiddenData = (formData: FormData) => {
    if (formData?.password === '@acer10') {
      setShowPassword(false);
      setHiddenPrices(false);
      localStorage.setItem('hiddenPrices', 'false');
    } else {
      openAlert('error', t('pages.prices.checkPassword'));
    }
  };

  function HiddenPricesForm({ disabledActions }: FormData) {
    return (
      <S.EyeContainer>
        {showPassword && (
          <Form validateMessages={validateMessages} onFinish={handleHiddenData}>
            <div className="eye-input">
              <Form.Item name="password">
                <Input
                  type="password"
                  placeholder={t('pages.prices.fields.password')}
                  maxLength={12}
                  required
                />
              </Form.Item>

              <ActionButton htmlType="submit" buttonText={`${t('common.ok')}`} />
            </div>
          </Form>
        )}

        <Popconfirm
          title={
            hiddenPrices ? t('pages.prices.showPricesAlert') : t('pages.prices.hidePricesAlert')
          }
          onConfirm={handleHiddenPrices}
          onClick={() => console.log('Confirm! ')}
        >
          <ActionButton
            disabled={disabledActions}
            icon={hiddenPrices ? <AiFillEyeInvisible /> : <AiFillEye />}
          />
        </Popconfirm>
      </S.EyeContainer>
    );
  }

  return {
    showPassword,
    hiddenPrices,
    handleHiddenPrices,
    handleHiddenData,
    HiddenPricesForm,
  };
}
