import { CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StyledFilterTag from '../../components/Common/StyledFilterTag';
import StyledClearTag from '../../components/Common/StyledFilterTag/StyledClearTag';
import { StyledSelectOptions } from '../../components/Common/StyledSelect/types';
import { useAlert } from '../../context/AlertContext';
import { useCmsContext } from '../../context/CmsContext';
import { handleFormatValueFields as formatValues } from '../../helpers/nUtils';
import { getURI } from '../../helpers/utils';
import { api } from '../../services/api';
import theme from '../../styles/theme';
import { PricesPageProps } from '../Finance/types';
import { TableData, TableProjectHeader } from './TableQuotes/styles';
import { ParamsProps } from './types';

export function useQuotes() {
  const { openAlert } = useAlert();
  const { t } = useTranslation();
  const { handleGetCms, handleSelectDefaultCm } = useCmsContext();
  const CMs = handleGetCms(false);
  const levels = [
    { value: '', label: 'Show All' },
    { value: 'L6', label: 'L6' },
    { value: 'L10', label: 'L10' },
  ];
  const products = [{ value: 'Notebook', label: 'Notebook' }];

  const initialParams: ParamsProps = {
    product: 'Notebook',
    cm: handleSelectDefaultCm('Foxconn'),
    date: null,
    project: '',
    sku: '',
    level: '',
    withForecast: false,
    page: 0,
    limit: 10,
  };

  const initialPagination = {
    pageLabel: '1',
    totalPages: 0,
    totalElements: 0,
  };

  const [pageControl, setPageControl] = useState(initialPagination);
  const [tableLevel, setTableLevel] = useState('');
  const [dataFile, setDataFile] = useState('');
  const [params, setParams] = useState<ParamsProps>(initialParams);
  const [DATA_L6, setDataL6] = useState([]);
  const [DATA_L10, setDataL10] = useState([]);
  const [fxRate, setFxRate] = useState('0.00');
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstRendering, setIsFirstRendering] = useState(true);
  const [lastDate, setLastDate] = useState<dayjs.Dayjs>();
  const [projectsFilter, setProjectsFilter] = useState<Array<StyledSelectOptions>>([]);
  const [skus, setSkus] = useState<Array<StyledSelectOptions>>([]);

  // const [selectedSku, setSelectedSku] = useState('');

  const changePageValue = (page: number, type: string) => {
    if (type === 'input' || type === 'navigation') {
      setParams({ ...params, page: page - 1 });
      setPageControl({ ...pageControl, pageLabel: String(page) });
    } else {
      setPageControl({ ...pageControl, pageLabel: String(page) });
    }
  };

  const handleFetchQuotes = async (paramsToFetch = params) => {
    try {
      setIsLoading(true);
      const { data } = await api.get(
        getURI('quotations', {
          ...paramsToFetch,
          date: dayjs(paramsToFetch.date).format('MMMM/YYYY').toUpperCase(),
        })
      );
      setDataL10(data.l10 || []);
      setDataFile(data.date || '');
      setDataL6(data.l6 || []);
      setFxRate(data.fxRate || '');
      data
        ? setPageControl({
            pageLabel: String(params.page + 1),
            totalElements: data.totalElements,
            totalPages: data.totalPage,
          })
        : setPageControl(initialPagination);
    } catch (error: any) {
      openAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setIsLoading(false);
    }
  };

  const convertDataToStyledSelectOptions = (data: Array<string>): Array<StyledSelectOptions> => {
    try {
      const convertedData = data.map((project: string) => {
        return {
          label: project,
          value: project,
        };
      });
      return convertedData;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const handleUpdateFilters = (field: string, value: any) => {
    setParams((oldParams) => {
      return {
        ...oldParams,
        [field]: value,
      };
    });
  };

  const handleFetchFiltersData = async (paramsToFetch = params) => {
    try {
      isFirstRendering && setIsLoading(true);
      //removendo o campo date nao utilizado nessa request
      const { date, limit, page, ...restParams } = paramsToFetch;
      const { data, status } = await api.get(
        getURI('/quotations/header', {
          ...restParams,
          period: paramsToFetch.date && dayjs(paramsToFetch.date).format('MMMM/YYYY').toUpperCase(),
        })
      );
      if (status === 200) {
        setProjectsFilter(convertDataToStyledSelectOptions(data.projectNameList) || []);
        setSkus(convertDataToStyledSelectOptions(data.skuList) || []);
        if (!paramsToFetch.date) {
          const formattedData = dayjs(data.currentDate, 'MM/YYYY');
          setParams({ ...params, date: formattedData });
          setLastDate(formattedData);
        }
      } else {
        setProjectsFilter([]);
      }
    } catch (error: any) {
      openAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setIsFirstRendering(false);
    }
  };

  const handleGetWidthByQtSkuForProjects = (projectName: string, DATA: any): number => {
    return DATA.filter((item: any) => item.project === projectName).length;
  };

  const handleRenderTableHeaderProjects = (DATA: any) => {
    const projects: Array<string> = [];
    DATA.forEach((item: any) => {
      !projects.includes(item.project) && projects.push(item.project);
    });
    return projects.map((item: any) => {
      return (
        <TableProjectHeader qtSkus={handleGetWidthByQtSkuForProjects(item, DATA)} key={item}>
          {item}
        </TableProjectHeader>
      );
    });
  };

  const handleRenderTableHeaderSKus = (DATA: any) => {
    return (
      <>
        {DATA.map((item: any) => {
          return <td key={item}>{item.sku}</td>;
        })}
      </>
    );
  };

  const handleRenderTableHeaderForecasts = (DATA: any) => {
    return DATA.map((item: any) => {
      return (
        <th key={item.estimatedForecast}>
          {formatValues({ number: item.estimatedForecast, minFractional: 0, maxFactional: 0 })}
        </th>
      );
    });
  };

  const handleRenderFieldsBodyByFieldName = (field: string, DATA: any) => {
    return DATA.map((item: any) => {
      return (
        <th key={item[field]}>
          <th key={item[field]}>{item[field] ? <p>{item[field].toFixed(2)}</p> : <p> - </p>}</th>
        </th>
      );
    });
  };

  const handleFormatValueFields = (number: string | number): string => {
    try {
      return Number(number).toLocaleString(t('common.localeMoney'), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } catch (error) {
      console.log(error);
      return '-';
    }
  };

  //enviando informações para rota de prices
  function handleNavigateToFinancePage(pricePageProps: PricesPageProps) {
    try {
      const financeParams = new URLSearchParams();
      financeParams.set('level', pricePageProps.level);
      financeParams.set('compType', pricePageProps.compType);
      financeParams.set('sku', pricePageProps.sku);
      financeParams.set('monthYear', encodeURI(pricePageProps.monthYear));
      financeParams.set('category', pricePageProps.category);
      financeParams.set('cm', pricePageProps.cm);
      financeParams.set('source', pricePageProps.source);
      financeParams.set('project', pricePageProps.project);
      financeParams.set('quotePrice', pricePageProps.quotePrice);

      window.open(`/finance?${financeParams.toString()}`);
    } catch (error) {
      console.log(error);
    }
  }

  const handleRenderSubFieldsByTag = (
    date: dayjs.Dayjs,
    tag: string,
    subTag: string,
    DATA: any,
    isComponentLink: boolean,
    level?: 'L6' | 'L10'
  ) => {
    return DATA.map((item: any) => {
      const value = item[tag].find((subItem: any) => subItem.item === subTag);
      const itemHasDetails = value?.detail;
      return (
        <TableData
          isComponentLink={isComponentLink && itemHasDetails}
          key={item.id}
          onClick={() => {
            if (itemHasDetails && isComponentLink && value.value && level) {
              handleNavigateToFinancePage({
                category: value.item,
                cm: item.contractManufacturer,
                monthYear: dayjs(date).format('MMMM/YYYY').toUpperCase(),
                project: item.project,
                quotePrice: value.value,
                sku: item.sku,
                source: tag === 'materialFobDtos' ? 'FOB' : 'BR',
                level,
                compType: value.originItem,
              });
            }
          }}
        >
          {!value?.value || value.value === 0 ? '-' : handleFormatValueFields(value.value)}
        </TableData>
      );
    });
  };

  const handleRenderRateFieldByTag = (tag: string, subTag: string, DATA: any) => {
    try {
      const item: any = DATA[0];
      const value = item[tag].find((subItem: any) => subItem.item === subTag);
      return (
        <span className="table-body" key={item.id}>
          {value.rate !== null ? `${value.rate}%` : ''}
        </span>
      );
    } catch (error) {
      console.log(error);
      return <span className="table-body">{}</span>;
    }
  };

  const clearAllFilters = () => {
    // setSelectedSku('');
    setParams((oldParams) => {
      return {
        ...oldParams,
        // product: '',
        date: lastDate || initialParams.date,
        project: '',
        sku: '',
        level: '',
      };
    });
  };

  const handleRenderClearAllFilters = () => {
    if (
      // params.cm ||
      // params.date ||
      params.level ||
      // params.product ||
      params.project ||
      params.project ||
      params.sku
    ) {
      return <StyledClearTag onClick={clearAllFilters} />;
    }
  };

  const handleRenderFiltersTags = () => {
    return (
      <>
        {/* {params.date && (
          <StyledFilterTag
            label={t('pages.quotes.labels.date')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={dayjs(params.date).format('MM/YYYY')}
            closeble
            onClose={() => {
              handleUpdateFilters('date', '');
            }}
          />
        )} */}
        {params.level && (
          <StyledFilterTag
            label={t('pages.quotes.labels.level')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.level}
            closeble
            onClose={() => {
              handleUpdateFilters('levels', '');
            }}
          />
        )}
        {/* {params.product && (
          <StyledFilterTag
            label={t('pages.quotes.labels.product')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.product}
            closeble
            onClose={() => {
              handleUpdateFilters('product', '');
            }}
          />
        )} */}
        {params.project && (
          <StyledFilterTag
            label={t('pages.quotes.labels.project')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.project}
            closeble
            onClose={() => {
              handleUpdateFilters('project', '');
            }}
          />
        )}
        {params.sku && (
          <StyledFilterTag
            label={t('pages.quotes.labels.sku')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.sku}
            closeble
            onClose={() => {
              handleUpdateFilters('sku', '');
            }}
          />
        )}
      </>
    );
  };

  const findSubItensForMemoryBadge = (DATA: any, category: string): Array<string> => {
    const items: Array<string> = [];

    DATA.forEach((element: any) => {
      element[category].forEach((item: any) => {
        if (item.item === 'Memory1' || item.item === 'Memory2' || item.item === 'Memory3') {
          items.push(item.item);
        }
      });
    });
    return [...new Set(items)];
  };

  const findSubItensForHDDBadge = (DATA: any, category: string): Array<string> => {
    const items: Array<string> = [];

    DATA.forEach((element: any) => {
      element[category].forEach((item: any) => {
        if (
          item.item === 'HDD1' ||
          item.item === 'HDD2' ||
          item.item === 'HDD3' ||
          item.item === 'HDD4'
        ) {
          items.push(item.item);
        }
      });
    });
    return [...new Set(items)];
  };

  const findSubItensForSSDBadge = (DATA: any, category: string): Array<string> => {
    const items: Array<string> = [];

    DATA.forEach((element: any) => {
      element[category].forEach((item: any) => {
        if (
          item.item === 'SSD1' ||
          item.item === 'SSD2' ||
          item.item === 'SSD3' ||
          item.item === 'SSD4'
        ) {
          items.push(item.item);
        }
      });
    });
    return [...new Set(items)];
  };

  const saveParamsOnStorage = () => {
    try {
      sessionStorage.setItem('filtersQuotes', JSON.stringify(params));
    } catch (error) {
      console.log(error);
    }
  };

  return {
    CMs,
    levels,
    DATA_L10,
    DATA_L6,
    params,
    // selectedSku,
    skus,
    fxRate,
    isLoading,
    pageControl,
    dataFile,
    projectsFilter,
    products,
    tableLevel,
    // findSubItensForHDDBadgeMaterialFob,
    findSubItensForMemoryBadge,
    findSubItensForHDDBadge,
    setTableLevel,
    handleFetchQuotes,
    changePageValue,
    setSkus,
    handleRenderTableHeaderProjects,
    handleRenderTableHeaderSKus,
    handleRenderTableHeaderForecasts,
    handleRenderSubFieldsByTag,
    handleRenderFieldsBodyByFieldName,
    handleUpdateFilters,
    handleRenderFiltersTags,
    handleRenderClearAllFilters,
    handleRenderRateFieldByTag,
    findSubItensForSSDBadge,
    setParams,
    saveParamsOnStorage,
    handleFetchFiltersData,
    isFirstRendering,
  };
}
