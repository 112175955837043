import { SearchOutlined } from '@ant-design/icons';
import { Checkbox, Empty } from 'antd';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import StyledButton from '../../../components/Common/StyledButton';
import StyledDatePicker from '../../../components/Common/StyledDatePicker';
import StyledModal from '../../../components/Common/StyledModal';
import StyledSearchInput from '../../../components/Common/StyledSearchInput';
import StyledSelect from '../../../components/Common/StyledSelect';
import { Loading } from '../../../components/Loading';
import theme from '../../../styles/theme';
import { SearchButton } from '../styles';
import { InOutDashboard } from './Dashboard';
import * as S from './styles';
import { useInOut } from './useInOut';

export function InOutPage() {
  const {
    inOutComponents,
    isLoading,
    CMs,
    sources,
    params,
    categories,
    specifications,
    partNumbers,
    visibleDashbord,
    modalData,
    spec,
    setSpec,
    closeDashard,
    handleFetchComponents,
    handleFetchHeaders,
    handleRenderTablesComponents,
    handleRenderFiltersTags,
    handleRenderClearAllFilters,
    updateParams,
  } = useInOut();

  useEffect(() => {
    handleFetchHeaders();
  }, [params]);

  useEffect(() => {
    handleFetchComponents();
  }, []);
  const { t } = useTranslation();
  return (
    <S.Container>
      {visibleDashbord && (
        <StyledModal
          title={t('pages.inOut.modalTitle')}
          body={
            <InOutDashboard data={modalData} date={dayjs(params.date, 'MM/YY').format('MM/YYYY')} />
          }
          onClose={closeDashard}
          footer={null}
          open
          width="100%"
        />
      )}
      <S.ContainerFilters>
        <StyledSelect
          label={t('pages.inOut.labels.cm')}
          placeholder={t('pages.inOut.placeholders.cm')}
          options={CMs}
          value={params.cm}
          styles={{ minWidth: '7.25rem' }}
          onChange={(value: string) => updateParams('cm', value)}
          disable
        />
        {/* <StyledSelect
          label={t('pages.inOut.labels.product')}
          placeholder={t('pages.inOut.placeholders.product')}
          onChange={null}
          // options={CMs}
          // value={params.cm}
          styles={{ minWidth: '7.25rem' }}
        /> */}
        <StyledSelect
          suffixIcon={<SearchOutlined style={{ color: theme.colors.dark_green }} />}
          label={t('pages.inOut.labels.category')}
          placeholder={t('pages.inOut.placeholders.category')}
          options={categories}
          value={params.category || null}
          styles={{ minWidth: '7.25rem' }}
          showSearch
          onChange={(value: string) => updateParams('category', value)}
        />
        <StyledSearchInput
          placeholder={t('pages.inventory.placeholders.specification')}
          label={t('pages.inventory.labels.specification')}
          value={spec}
          suffix={
            <SearchButton
              onClick={() => {
                updateParams('spec', spec);
                setSpec('');
              }}
            >
              <SearchOutlined className="icon" />
            </SearchButton>
          }
          onChange={(event) => {
            setSpec(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              updateParams('spec', spec);
              setSpec('');
            }
          }}
        />
        <StyledSelect
          label={t('pages.inOut.labels.source')}
          placeholder={t('pages.inOut.placeholders.source')}
          options={sources}
          value={params.source}
          styles={{ minWidth: '7.25rem' }}
          onChange={(value: string) => updateParams('source', value)}
        />
        <StyledSelect
          suffixIcon={<SearchOutlined style={{ color: theme.colors.dark_green }} />}
          label={t('pages.inOut.labels.partNumber')}
          placeholder={t('pages.inOut.placeholders.partNumber')}
          options={partNumbers}
          value={params.partNumber || null}
          styles={{ minWidth: '8.75rem' }}
          showSearch
          onChange={(value: string) => updateParams('partNumber', value)}
        />
        <StyledDatePicker
          label={t('pages.inOut.labels.month')}
          picker="month"
          mode="month"
          format="MM/YYYY"
          onChange={(date, _) => {
            updateParams('date', date);
          }}
          defaultValue={params.date}
          styles={{ minWidth: '4rem' }}
        />
        <Checkbox
          style={{ padding: '0.375rem' }}
          checked={params.withData || false}
          onChange={() => {
            const newValue = params.withData ? null : true;
            updateParams('withData', newValue);
          }}
        >
          with Data
        </Checkbox>
        <StyledButton
          small
          onClick={handleFetchComponents}
          variant="primary"
          style={{ height: '2rem' }}
          text={t('pages.inventory.labels.apply')}
        />
      </S.ContainerFilters>
      <S.FiltersTagContainer>
        {handleRenderFiltersTags()}
        {handleRenderClearAllFilters()}
      </S.FiltersTagContainer>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {inOutComponents.length > 0 && handleRenderTablesComponents()}
          {!inOutComponents.length && <Empty />}
        </>
      )}
    </S.Container>
  );
}
