import { t } from 'i18next';
import { Form } from 'antd';
import { useState } from 'react';
import { getURI } from '../../../../helpers/utils';
import { api } from '../../../../services/api';
import { ComponentReceived, RegisterComponentData } from './types';
import { oneAlert } from '../../../../helpers/nUtils';

export function useRegisterNewComponent() {
  const [form] = Form.useForm<RegisterComponentData>();
  const [visibleNewComponent, setVisibleNewComponent] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const handleSaveDataAfterGetPartNumber = (component: ComponentReceived) => {
    form.setFieldsValue({
      specification: component.spec,
      description: component.description,
      supplier: component.supplier,
    });
  };

  const handleGetComponentByPartNumber = async (partNumber: string) => {
    try {
      if (!partNumber) return;
      setIsLoadingForm(true);
      const {
        data: { content },
        status,
      } = await api.get(getURI('/components', { partNumber, source: 'BR' }));
      if (status === 200 && content) {
        handleSaveDataAfterGetPartNumber(content[0]);
        setVisibleNewComponent(false);
      }
      if (status === 204) {
        oneAlert({ type: 'info', message: t('toast.componentNotFound') });
        setVisibleNewComponent(true);
      }
    } catch (error) {
      console.log(error);
      oneAlert({ type: 'error', message: t('toast.errorOnList') });
    } finally {
      setIsLoadingForm(false);
    }
  };

  return {
    handleGetComponentByPartNumber,
    setVisibleNewComponent,
    isLoadingForm,
    form,
    visibleNewComponent,
  };
}
