import { Form } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useModal } from '../../components/Modal/useModal';
import { useAlert } from '../../context/AlertContext';
import { getURI } from '../../helpers/utils';
import { RoutesPath } from '../../routes/routesPath';
import { api } from '../../services/api';

const initialParams = {
  order: '',
  orderBy: '',
  page: 0,
  limit: 10,
};

export function usePBB() {
  const { openAlert } = useAlert();
  const { t } = useTranslation();
  const history = useHistory();

  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingTitles, setLoadingTitles] = useState(false);
  const [params, setParams] = useState(initialParams);
  const [visible, openModal, closeModal] = useModal();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const fetchPPBProducts = async () => {
    setIsLoading(true);
    try {
      const {
        data: { content: record },
      } = await api.get(getURI(`ppb`, params));

      setData(record);
    } catch (error) {
      console.log('Error on the fetch Forecast');
    }
    setIsLoading(false);
  };

  const save = async () => {
    setIsLoading(true);
    try {
      const row = await form.getFieldValue();
      if (!row.id) {
        const response = await api.post('ppb', row);
        if (response.status !== 201) throw Error();
      } else {
        const response = await api.put(`ppb/${row.id}`, row);
        if (response.status !== 201) throw Error();
      }
      await fetchPPBProducts();
      openAlert('success', t('toast.successOnSave'));
    } catch (error) {
      openAlert('error', t('toast.errorOnSave'));
    }
    setIsLoading(false);
  };

  const deleteItem = async (itemId) => {
    setIsLoading(true);
    try {
      const response = await api.delete(`ppb/${itemId}`);
      if (response.status !== 200) throw Error();

      await fetchPPBProducts();
      openAlert('info', t('toast.successOnDelete'));
    } catch (error) {
      openAlert('error', t('toast.errorOnDelete'));
    }
    setIsLoading(false);
  };

  const isEditing = Object.entries(form.getFieldValue()).length > 0;

  const openModalEdit = (e, record) => {
    e.stopPropagation();
    form.setFieldsValue({
      ...record,
      inforceDate: record.inforceDate && moment(record.inforceDate),
      publishedDate: record.publishedDate && moment(record.publishedDate),
      modified: record.modified && moment(record.modified),
    });
    openModal();
  };

  const onCloseModal = () => {
    form.setFieldsValue({});
    form.resetFields();
    closeModal();
  };

  const goToRulesPPB = (record) => {
    history.push(`${RoutesPath.ppb}/${record.id}`);
  };
  return {
    data,
    params,
    fetchPPBProducts,
    isLoading,
    form,
    save,
    isEditing,
    deleteItem,
    onCloseModal,
    visible,
    openModal,
    openModalEdit,
    goToRulesPPB,
    isDropdownVisible,
    setIsDropdownVisible,
  };
}
