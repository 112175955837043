import styled from 'styled-components';

export const Container = styled.div`
  /* min-width: 45rem; */
`;
export const Title = styled.span`
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
`;

export const Message = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
`;
