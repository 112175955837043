/* eslint-disable no-undefined */
import { useState } from 'react';
import { useAlert } from '../../../../context/AlertContext';
import { getURI } from '../../../../helpers/utils';
import { api } from '../../../../services/api';
import { services } from '../../../../services/services';
import { IParams } from '../types';
import { IData } from './types';

export function useTotalAwards() {
  const { openAlert } = useAlert();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalAwardsInformation, setTotalAwardsInformation] = useState<IData>();

  const fetchData = async (filters: IParams) => {
    try {
      setIsLoading(true);
      const { status, data } = await api.get(
        getURI(`${services.rfq}/bid/management/totalAwards`, {
          ...filters,
        })
      );
      if (status === 200) {
        setTotalAwardsInformation(data);
      } else setTotalAwardsInformation(undefined);
    } catch (err: any) {
      openAlert('error', err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    fetchData,
    isLoading,
    totalAwardsInformation,
  };
}
