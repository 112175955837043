import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 5.375rem;
`;

export const Image = styled('img')`
  width: 10.438rem;
  margin: 1.5rem 0 1.5rem 0;
`;

export const FirstText = styled.p`
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0;
  color: ${({ theme }) => theme.colorsDesignSystem.tableValues};
`;

export const SecondText = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  text-align: center;
  margin: 0;
  color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
`;
