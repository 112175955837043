import dayjs from 'dayjs';
import { ReactNode } from 'react';
import { StyledCardAccepted } from '../../../../components/Common/StyledCardAccepted';
import { TermsConditionsStatusData } from './types';

export function useTermsConditionsModal() {
  const renderStatusCard = (dataStatus: Array<TermsConditionsStatusData>): ReactNode => {
    return dataStatus.map((item) => {
      if (item.status === 'accepted') {
        return (
          <StyledCardAccepted
            key={crypto.randomUUID()}
            status={item.status}
            time={dayjs(item.time).format('MM.DD.YYYY - hh:mma')}
            user={`${item.user} - ${item.supplier}`}
          />
        );
      }
      if (item.status === 'denied') {
        return (
          <StyledCardAccepted
            key={crypto.randomUUID()}
            status={item.status}
            time={dayjs(item.time).format('MM.DD.YYYY - hh:mma')}
            user={`${item.user} - ${item.supplier}`}
          />
        );
      }
      return null;
    });
  };
  return {
    renderStatusCard,
  };
}
