import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { IData } from '../../components/Qualification/QualificationStatus/types';
import { useAlert } from '../../context/AlertContext';
import { QualificationStatus } from '../../helpers/enums';
import { getErrorMessage, oneAlert } from '../../helpers/utils';
import { api } from '../../services/api';
import { services } from '../../services/services';
import { IQualificationData } from './types';

export function useViewQualification() {
  const { pathname, state } = useLocation<{
    id: string;
    idQualification: string;
    partNumber: string;
    supplier: string;
  }>();

  const { t } = useTranslation();
  const { openAlert } = useAlert();
  const [isLoadingStatus, setIsLoadingStatus] = useState<boolean>(false);
  const [steps, setSteps] = useState<IData[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [qualificationData, setQualificationData] = useState<IQualificationData>(
    {} as IQualificationData
  );
  const [form] = Form.useForm();

  const fetchStatus = async () => {
    try {
      setIsLoadingStatus(true);
      const { status, data } = await api.get(
        `${services.qualification}/${state.idQualification}/status`
      );
      if (status === 200) return setSteps(data);
      setSteps([]);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingStatus(false);
    }
  };

  const fetchQualificationData = async () => {
    try {
      setIsLoading(true);
      const { status, data } = await api.get(
        `${services.qualification}/summary/view/details/${state.idQualification}?partNumber=${state.partNumber}&supplier=${state.supplier}`
      );

      if (status === 200) {
        return setQualificationData(data);
      }
    } catch (error: any) {
      oneAlert('error', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onModalClose = () => {
    setOpenModal(false);
    form.resetFields();
  };

  const cancelQualification = async () => {
    try {
      setIsLoading(true);
      const { status } = await api.post(`${services.qualification}/summary/view/cancel`, {
        reasonCancelation: form.getFieldValue('reason'),
        registerComponentId: state.id,
      });
      if (status === 200) {
        openAlert('success', t('toast.successOnDelete'));
        return Promise.all([fetchStatus(), fetchQualificationData()]);
      }
    } catch (error: any) {
      openAlert('error', getErrorMessage(error) || t('toast.errorOnDelete'));
    } finally {
      setIsLoading(false);
    }
  };
  const verifyStatus = (): boolean => {
    return !steps.some(
      (item) =>
        item.status === QualificationStatus.CANCELED ||
        item.status === QualificationStatus.RESULT_AVAILABLE
    );
  };
  useEffect(() => {
    Promise.all([fetchStatus(), fetchQualificationData()]);
  }, []);

  return {
    qualificationData,
    isLoading,
    isLoadingStatus,
    steps,
    form,
    openModal,
    onModalClose,
    setOpenModal,
    cancelQualification,
    verifyStatus,
  };
}
