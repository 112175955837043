import { useEffect } from 'react';
import { t } from 'i18next';
import { Flex, Popconfirm, Row } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { FaUber } from 'react-icons/fa';
import * as S from './styles';
import { StepThreeProps } from './types';
import { useStepThreeSendEmail } from './useStepThreeSendEmail';
import { Loading } from '../../../components/Loading';
import StyledButton from '../../../components/Common/StyledButton';
import StyledModal from '../../../components/Common/StyledModal';
import { ConfirmationSendMailModal } from '../../../components/Common/ConfirmationSendMailModal';

export function StepThreeSendEmail({
  associetedOdmId,
  registerComponentId,
  setStep,
}: StepThreeProps) {
  const {
    handleRenderCards,
    handleGetSuppliersData,
    setRegisterComponentId,
    setReceivedAssocietedOdmId,
    setVisiblePopConfirm,
    handleVerifyIfAllCardsIsSended,
    handleTriggerEmail,
    handleNavigateQualification,
    visiblePopConfirm,
    receivedAssocietedOdmId,
    isLoading,
    visibleModalSendMail,
  } = useStepThreeSendEmail();

  useEffect(() => {
    setReceivedAssocietedOdmId(associetedOdmId);
    setRegisterComponentId(registerComponentId);
  }, []);

  useEffect(() => {
    receivedAssocietedOdmId && handleGetSuppliersData();
  }, [receivedAssocietedOdmId]);

  return (
    <S.ContentForm>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <S.ContainerCardsInside>{handleRenderCards()}</S.ContainerCardsInside>
          <S.StepFooter>
            <StyledButton variant="slim" onClick={handleNavigateQualification}>
              {t('common.cancel')}
            </StyledButton>
            <Flex gap={18}>
              <StyledButton
                variant="secondary"
                onClick={() => {
                  setStep(1);
                }}
                htmlType="submit"
                disabled={false}
              >
                {t('common.previous')}
              </StyledButton>
              <Popconfirm
                description={
                  <S.PopUpContainerContent>
                    <span>
                      {t('pages.requesRegistration.components.stepThree.popConfirm.text')}
                    </span>
                    <Row justify="space-between">
                      <StyledButton
                        variant="secondary"
                        onClick={() => {
                          setVisiblePopConfirm(false);
                        }}
                      >
                        {t('common.cancel')}
                      </StyledButton>
                      <StyledButton
                        variant="primary"
                        onClick={() => {
                          handleTriggerEmail();
                          setVisiblePopConfirm(false);
                        }}
                      >
                        {t('common.confirm')}
                      </StyledButton>
                    </Row>
                  </S.PopUpContainerContent>
                }
                open={visiblePopConfirm}
                icon={null}
                showCancel={false}
                okButtonProps={{ style: { display: 'none' } }}
                // title={t('pages.requesRegistration.components.stepThree.popConfirm.title')}
                title={
                  <S.ContainerPopConfirmTitle>
                    <span>
                      {t('pages.requesRegistration.components.stepThree.popConfirm.title')}
                    </span>
                    <CloseOutlined
                      onClick={() => {
                        setVisiblePopConfirm(false);
                      }}
                    />
                  </S.ContainerPopConfirmTitle>
                }
                onClick={() => {
                  return null;
                }}
              >
                <StyledButton
                  variant="primary"
                  disabled={handleVerifyIfAllCardsIsSended()}
                  onClick={() => {
                    setVisiblePopConfirm(true);
                  }}
                >
                  {t('pages.requesRegistration.components.stepThree.labels.finish')}
                </StyledButton>
              </Popconfirm>
            </Flex>
          </S.StepFooter>
        </>
      )}
      <StyledModal
        title={t('pages.newRfq.steps.send.modal.title')}
        open={visibleModalSendMail}
        body={
          <ConfirmationSendMailModal
            title={t('pages.requesRegistration.components.stepThree.modalSendMail.title')}
            subTitle={t('pages.requesRegistration.components.stepThree.modalSendMail.subTitle')}
          />
        }
        width="45rem"
        footer={null}
        onClose={handleNavigateQualification}
      />
    </S.ContentForm>
  );
}
