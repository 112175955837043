import { ArrowRightOutlined, TagOutlined } from '@ant-design/icons';
import { Divider, Flex } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import * as S from './styles';
import { ModalDetailsTrackNumberProps } from './types';
import { useModalTrackingNumberDetails } from './useModalTrackingNumberDetails';
import { TrackingTag } from '../../../../components/Qualification/TrackingTag';
import { Steps } from '../Steps';
import { Loading } from '../../../../components/Loading';

export function ModalTrackingNumberDetails({
  qualificationNameId,
  trackingNumber,
}: ModalDetailsTrackNumberProps) {
  const { t } = useTranslation();
  const { dataTracking, labelTexts, isLoading, handleFetchData } = useModalTrackingNumberDetails();

  useEffect(() => {
    handleFetchData(qualificationNameId, trackingNumber);
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <S.Container gap={16}>
          {true && (
            <>
              <S.TrackingNumberInformation>
                <Flex justify="space-between" align="center">
                  <Flex gap={16}>
                    <S.Icon>
                      <TagOutlined />
                    </S.Icon>
                    <Flex vertical gap={4}>
                      <S.Label>
                        {t('components.modals.sampleTrackingNumber.trackingNumber')}
                      </S.Label>
                      <S.Value size="large">{trackingNumber}</S.Value>
                    </Flex>
                  </Flex>
                  {dataTracking && (
                    <S.Tag status={dataTracking?.currentStatus}>
                      <div className="circle" />
                      <span>{dataTracking ? labelTexts[dataTracking?.currentStatus] : ''}</span>
                    </S.Tag>
                  )}
                </Flex>

                <Flex gap={24} align="center">
                  <Flex vertical gap={4}>
                    <S.Label>
                      {dataTracking ? dayjs(dataTracking.from.date).format('MMM DD, YYYY') : ''}
                    </S.Label>
                    <S.Value size="large">
                      {dataTracking
                        ? `${dataTracking?.from.city}, ${dataTracking?.from.country}`
                        : '-'}
                    </S.Value>
                  </Flex>
                  <ArrowRightOutlined className="arrow" />
                  <Flex vertical gap={4}>
                    <S.Label>
                      {dataTracking ? dayjs(dataTracking.to.date).format('MMM DD, YYYY') : ''}
                    </S.Label>
                    <S.Value size="large">
                      {dataTracking ? `${dataTracking?.to.city}, ${dataTracking?.to.country}` : '-'}
                    </S.Value>
                  </Flex>
                </Flex>
                <S.Divider />

                <Flex vertical gap={4}>
                  <S.Label>{t('components.modals.sampleTrackingNumber.freightForwarder')}</S.Label>
                  <S.Value>{dataTracking?.freightForwarder || '-'}</S.Value>
                </Flex>

                <Flex gap={34}>
                  <Flex vertical gap={4}>
                    <S.Label>
                      {t('components.modals.sampleTrackingNumber.responsibleSupplier')}
                    </S.Label>
                    <S.Value>
                      {dataTracking ? dataTracking.responsibleSupplier.name : ''} -{' '}
                      <i>{dataTracking ? dataTracking.responsibleSupplier.supplier : ''}</i>
                    </S.Value>
                  </Flex>
                  <Flex vertical gap={4}>
                    <S.Label>{t('components.modals.sampleTrackingNumber.dateInput')}</S.Label>
                    <S.Value>
                      {dataTracking
                        ? dayjs(dataTracking.responsibleSupplier.date).format(
                            'MM.DD.YYYY - hh:mm A'
                          )
                        : '-'}
                    </S.Value>
                  </Flex>
                </Flex>

                <Flex gap={34}>
                  <Flex vertical gap={4}>
                    <S.Label>{t('components.modals.sampleTrackingNumber.responsibleODM')}</S.Label>
                    <S.Value>
                      {dataTracking?.responsibleOdm.name} -{' '}
                      <i>{dataTracking?.responsibleOdm.supplier}</i>
                    </S.Value>
                  </Flex>
                  <Flex vertical gap={4}>
                    <S.Label>
                      {t('components.modals.sampleTrackingNumber.deliveryForecast')}
                    </S.Label>
                    <S.Value>
                      {dataTracking
                        ? dayjs(dataTracking?.responsibleOdm.date).format('MM.DD.YYYY - hh:mm A')
                        : '-'}
                    </S.Value>
                  </Flex>
                </Flex>
              </S.TrackingNumberInformation>
              <S.TrackingNumberStatus vertical>
                <S.StatusTitle>
                  {t('components.modals.sampleTrackingNumber.statusTrackingNumber')}
                </S.StatusTitle>
                <Divider />
                <Steps data={dataTracking?.tracking || []} />
              </S.TrackingNumberStatus>
            </>
          )}
        </S.Container>
      )}
    </div>
  );
}
