import { TabsProps } from 'antd';
import { useState } from 'react';
import { IODMDetails } from '../../types';

export function useODMDetails() {
  const [odmTabs, setOdmTabs] = useState<TabsProps['items']>();
  const [selectedTab, setSelectedTab] = useState<string>();
  const [selectedODM, setSelectedODM] = useState<IODMDetails>();

  const formatODMTabs = (odms: IODMDetails[]) => {
    setOdmTabs(
      odms.map((odm) => {
        return {
          key: odm.odmName,
          label: odm.odmName,
        };
      })
    );
    setSelectedODM(odms[0]);
  };

  const onChangeTab = (value: string, odms: IODMDetails[]) => {
    setSelectedTab(value);
    setSelectedODM(odms.filter((odm) => odm.odmName === value)[0]);
  };

  return {
    odmTabs,
    formatODMTabs,
    selectedTab,
    setSelectedTab,
    selectedODM,
    onChangeTab,
  };
}
