import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Flex, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import StyledButton from '../../components/Common/StyledButton';
import { StyledHeader } from '../../components/Common/StyledHeaderPage';
import StyledLabel from '../../components/Common/StyledLabel';
import { StyledPagination } from '../../components/Common/StyledPagination';
import StyledSelect from '../../components/Common/StyledSelect';
import SimpleTable from '../../components/Tables/SimpleTable';
import { RoutesPath } from '../../routes/routesPath';
import * as S from './styles';
import { IQualificationData } from './types';
import { useQualification } from './useQualification';

export function Qualification() {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    params,
    columns,
    categories,
    partNumbers,
    projects,
    specifications,
    status,
    suppliers,
    qualificationData,
    isLoading,
    totalElements,
    onChangeParams,
    handleRenderTags,
    onChangePage,
    fetchData,
    handleChangeTable,
    handleRenderClearTags,
    handleNavigationNewQualification,
  } = useQualification();

  return (
    <Flex vertical>
      <StyledHeader
        title={t('pages.qualification.title')}
        actions={
          <StyledButton variant="primary" onClick={handleNavigationNewQualification}>
            <PlusOutlined />
            {t('pages.qualification.labels.button')}
          </StyledButton>
        }
      />
      <S.FiltersContainer>
        <Flex vertical>
          <StyledLabel title={t('pages.qualification.filters.idQualification')} />
          <Input
            value={params.qualificationName ?? ''}
            suffix={<SearchOutlined className="searchIcon" />}
            placeholder={t('pages.qualification.filters.idQualificationPlaceholder')}
            style={{ width: '8.5rem' }}
            onChange={(event) => onChangeParams('qualificationName', event.target.value)}
          />
        </Flex>
        <StyledSelect
          label={t('pages.qualification.filters.project')}
          placeholder={t('pages.qualification.filters.projectPlaceholder')}
          value={params.project}
          onChange={(value: string) => onChangeParams('project', value)}
          options={projects}
          showSearch
          suffixIcon={<SearchOutlined className="searchIcon" />}
          styles={{ width: '8.5rem' }}
          allowClear
        />
        <StyledSelect
          label={t('pages.qualification.filters.category')}
          placeholder={t('pages.qualification.filters.categoryPlaceholder')}
          value={params.category}
          options={categories}
          onChange={(value: string) => onChangeParams('category', value)}
          showSearch
          suffixIcon={<SearchOutlined className="searchIcon" />}
          styles={{ width: '8.5rem' }}
          allowClear
        />
        <StyledSelect
          label={t('pages.qualification.filters.specification')}
          placeholder={t('pages.qualification.filters.specificationPlaceholder')}
          value={params.specification}
          options={specifications}
          onChange={(value: string) => onChangeParams('specification', value)}
          showSearch
          suffixIcon={<SearchOutlined className="searchIcon" />}
          styles={{ width: '10rem' }}
          allowClear
        />
        <StyledSelect
          label={t('pages.qualification.filters.partNumber')}
          placeholder={t('pages.qualification.filters.partNumberPlaceholder')}
          value={params.partNumber}
          options={partNumbers}
          onChange={(value: string) => onChangeParams('partNumber', value)}
          showSearch
          suffixIcon={<SearchOutlined className="searchIcon" />}
          styles={{ width: '9rem' }}
          allowClear
        />
        <StyledSelect
          label={t('pages.qualification.filters.supplier')}
          placeholder={t('pages.qualification.filters.supplierPlaceholder')}
          value={params.supplier}
          options={suppliers}
          onChange={(value: string) => onChangeParams('supplier', value)}
          showSearch
          suffixIcon={<SearchOutlined className="searchIcon" />}
          styles={{ width: '8.5rem' }}
          allowClear
        />
        <StyledSelect
          label={t('pages.qualification.filters.status')}
          placeholder={t('pages.qualification.filters.statusPlaceholder')}
          value={params.status}
          options={status}
          onChange={(value: string) => onChangeParams('status', value)}
          showSearch
          suffixIcon={<SearchOutlined className="searchIcon" />}
          styles={{ width: '7rem' }}
          allowClear
        />
        <StyledButton variant="primary" onClick={() => fetchData(params)}>
          {t('common.apply')}
        </StyledButton>
      </S.FiltersContainer>
      <S.TagsContainer>
        {handleRenderTags()} {handleRenderClearTags()}
      </S.TagsContainer>
      <SimpleTable
        dataSource={qualificationData}
        rowKey="id"
        columns={columns}
        scroll={{ x: 'max-content' }}
        pagination={false}
        isLoading={isLoading}
        onChange={handleChangeTable}
        onRow={(record: IQualificationData) => {
          return {
            onClick: () => {
              history.push(RoutesPath.qualificationSummary, {
                id: record.id,
                idQualification: record.id,
                partNumber: record.partNumber,
                supplier: record.supplier,
              });
            },
          };
        }}
      />
      <StyledPagination
        size={params.size}
        totalElements={totalElements}
        pageNumber={params.page + 1}
        onChange={onChangePage}
      />
    </Flex>
  );
}
