import { Button, Form, Popconfirm, Space } from 'antd';
import dayjs from 'dayjs';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';
import { EditButton } from './styles';

import { TableAction } from '../../components/Tables/TableDropdownAction';
import { useAlert } from '../../context/AlertContext';
import { getColumnSearchProps } from '../../helpers/table';
import {
  filterAndUrlParams,
  getErrorMessage,
  getSortOrder,
  getURI,
  hasPropertyWithHeader,
  parseBaseScenarios,
  parseScenariosLite,
} from '../../helpers/utils';
import { useTable } from '../../hooks/useTable';
import { api } from '../../services/api';
import * as S from './styles';

export function useForecasts(dataModal = null) {
  const { openAlert } = useAlert();
  const localTargetYear = localStorage.getItem('targetYear') || new Date().getFullYear().toString();
  const initialParams = {
    order: 'sku',
    orderBy: 'ASC',
    page: 0,
    limit: 25,
    targetYear: localTargetYear,
    scenario: '',
    sku: '',
    project: '',
    product: '',
  };

  //Modal must replace all others params
  if (dataModal && dataModal.scenario && dataModal.targetYear) {
    initialParams.scenario = dataModal.scenario;
    initialParams.targetYear = dataModal.targetYear;
  }

  const { t } = useTranslation();
  const { canBeEditable, getSortOrderTable } = useTable();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');

  const [form] = Form.useForm();
  const searchInput = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isNewItem, setIsNewItem] = useState(false);
  const [canUpdate, setCanUpdate] = useState(true);
  const [selectedScenario, setSelectedScenario] = useState();
  const [params, setParams] = useState(initialParams);
  const [isBaseScenario, setIsBaseScenario] = useState(false);
  const [scenariosOptions, setScenariosOptions] = useState([]);
  const [targetYear, setTargetYear] = useState();

  const [pageControl, setPageControl] = useState({
    pageLabel: '1',
    totalPages: 0,
    totalElements: 0,
  });

  const fetchScenarios = async () => {
    if (!dataModal) {
      try {
        const allParams = filterAndUrlParams(params);

        const { data: content } = await api.get(
          `scenarios/lite?targetYear=${allParams.targetYear}`
        );

        const selectScenarios = parseScenariosLite(content);

        if (selectScenarios?.length) {
          if (!allParams.scenario) {
            const localScenario = localStorage.getItem('scenario');
            const scenarioExist = selectScenarios.find((i) => i.value === localScenario);

            if (!localScenario || !scenarioExist) {
              allParams.scenario = selectScenarios[0]?.value;
            } else {
              allParams.scenario = localScenario;
            }
          }

          if (allParams.scenario && allParams.targetYear) {
            localStorage.setItem('scenario', allParams.scenario);
            localStorage.setItem('targetYear', allParams.targetYear);
          }

          setSelectedScenario(selectScenarios.find((i) => i.value === allParams?.scenario));
          setScenariosOptions(selectScenarios);
          setParams(allParams);
          setTargetYear(allParams.targetYear);
        } else {
          setScenariosOptions([]);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      try {
        const {
          data: { content },
        } = await api.get(`scenarios?targetYear=${params.targetYear}`);

        const selectScenarios = parseBaseScenarios(content);
        if (selectScenarios?.length) {
          setSelectedScenario(selectScenarios.find((i) => i.value === params?.scenario));
        }
      } catch (error) {
        openAlert('error', getErrorMessage(error) || t('toast.missingScenario'));
      }
    }
  };

  const fetchForecast = async () => {
    if (params && !!params.scenario) {
      //change filter params
      const getParams = { ...params };
      getParams.scenario = null;
      getParams.targetYear = null;

      try {
        if (!isLoading) setIsLoading(true);

        const {
          data: { content, totalElements, totalPages },
        } = await api.get(
          getURI(`scenarios/${params.scenario}/forecasts`, {
            ...getParams,
            order: params.order,
            orderBy: params.orderBy,
          })
        );
        if (params.scenario && params.targetYear) {
          localStorage.setItem('scenario', params.scenario);
          localStorage.setItem('targetYear', params.targetYear);
        }

        const dataContent = content?.length ? content : [];

        const newData = dataContent.map((item) => {
          const [months] = item.children;
          delete item.children;
          return { ...item, months };
        });
        setData(newData);

        if (scenariosOptions?.length && params?.scenario) {
          setSelectedScenario(scenariosOptions.find((i) => i.value === params?.scenario));
        }

        setPageControl({
          pageLabel: params.page + 1,
          totalPages,
          totalElements,
        });

        if (dataModal) {
          const {
            data: { content: scenarios },
          } = await api.get(`scenarios`);
          const scenario = scenarios.find((item) => item.id === params.scenario);
          if (scenario) {
            setTargetYear(scenario.targetYear);
            setIsBaseScenario(!scenario?.base?.id);

            if (scenariosOptions?.length && params?.scenario) {
              setSelectedScenario(scenariosOptions.find((i) => i.value === params?.scenario));
            }
          }
        }

        setIsLoading(false);
      } catch (error) {
        setData([]);
        setIsLoading(false);
      }
    }
  };

  const updateProject = (value) => {
    setParams({ ...params, project: value, page: 0 });
  };

  const updateProduct = (value) => {
    setParams({ ...params, product: value, page: 0 });
  };

  const changePageValue = (page, pageSize) => {
    setParams({ ...params, page: page - 1, limit: pageSize });
    setPageControl({ ...pageControl, pageLabel: page });
  };

  const handleChangeTable = (pagination, filters, sorter) => {
    if (data.length === 0) return;
    setParams({
      ...params,
      order: sorter.columnKey,
      orderBy: getSortOrder(sorter.order),
      page: 0,
    });
  };

  const edit = (record) => {
    form.setFieldsValue({ ...record.months });
    setEditingKey(record.id);
  };

  const handleAddItem = () => {
    const newItem = {
      id: data.length + 1,
      sku: '',
      modelName: '',
      project: '',
      months: [
        {
          id: `${data.length + 1}_ch`,
          january: { amount: '' },
          february: { amount: '' },
          march: { amount: '' },
          april: { amount: '' },
          may: { amount: '' },
          june: { amount: '' },
          july: { amount: '' },
          august: { amount: '' },
          september: { amount: '' },
          october: { amount: '' },
          november: { amount: '' },
          december: { amount: '' },
        },
      ],
    };

    setIsNewItem(true);
    form.resetFields();
    form.setFieldsValue({ ...newItem });
    setData((oldParams) => [newItem, ...oldParams]);
    setEditingKey(newItem.id);
  };

  const onValuesChange = (changedValues) => {
    if (isNewItem) return;

    const month = Object.keys(changedValues)[0];
    const currentData = [...data];
    const currentItem = currentData.find((item) => item.children[0].id === editingKey);
    const isEdited = currentItem[month]?.amount !== changedValues[month]?.amount;

    form.setFieldsValue({
      [month]: { amount: changedValues[month]?.amount, edited: isEdited },
    });
  };

  const isEditing = (record) => record.id === editingKey;

  const save = async (id) => {
    try {
      setIsLoading(true);
      await form
        .validateFields()
        .then(async (row) => {
          try {
            if (isNewItem) {
              row.project = params.project;
              const response = await api.post(
                getURI(`scenarios/${params.scenario}/forecasts/`),
                row
              );
              if (response.status !== 201) throw Error();
            } else {
              const parentId = id.replace('_ch', '');
              const response = await api.put(
                getURI(`scenarios/${params.scenario}/forecasts/${parentId}`),
                row
              );
              if (response.status !== 201) throw Error();
            }

            await fetchForecast();
            setEditingKey('');
            setIsNewItem(false);

            openAlert('success', t('toast.successOnSave'));
          } catch (error) {
            openAlert('error', getErrorMessage(error) || t('toast.errorOnSave'));
          }
        })
        .catch((error) => {
          console.log('Validate Failed: ', error);
        });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cancelNewItem = () => {
    const newData = data.filter((item) => item.id !== editingKey);
    setData(newData);
    setIsNewItem(false);
  };

  const cancel = () => {
    if (isNewItem) cancelNewItem();
    setEditingKey('');
  };

  const deleteItem = async (itemId) => {
    setIsLoading(true);
    try {
      const response = await api.delete(
        getURI(`/scenarios/${params.scenario}/forecasts/${itemId}`)
      );
      if (response.status !== 200) throw Error();

      const isLastItem = data.length === 1;

      if (isLastItem) {
        const newPage = params.page - 1;
        setParams({ ...params, page: newPage < 0 ? 0 : newPage });
      }

      await fetchForecast();
      openAlert('info', t('toast.successOnDelete'));
    } catch (error) {
      openAlert('error', t('toast.errorOnDelete'));
    }
    setIsLoading(false);
  };

  const deleteAll = async () => {
    setIsLoading(true);
    try {
      const response = await api.delete('forecasts');
      if (response.status !== 200) throw Error();

      await fetchForecast();
      openAlert('info', t('toast.successOnDelete'));
    } catch (error) {
      openAlert('error', t('toast.errorOnDelete'));
    }
    setIsLoading(false);
  };

  const searchFilter = async (newFilters, filterName = '') => {
    setCanUpdate(filterName === 'submit');

    const filterParams = {
      ...params,
      ...newFilters,
    };

    //Reset dependent filters
    if (filterName === 'targetYear') {
      filterParams.scenario = null;
    }

    setParams(filterParams);
  };

  const clearFilter = async () => {
    setCanUpdate(false);

    setParams({
      ...initialParams,
      targetYear: null,
      scenario: null,
      sku: null,
    });
  };

  const handleHeaderReset = (dataIndex) => {
    const filterParams = { ...params };
    delete filterParams[`header_${dataIndex}`];

    if (!hasPropertyWithHeader(filterParams)) {
      delete filterParams.tipoBusca;
    }

    setParams(filterParams);
  };

  const handleCloseFilteredTag = (field, idx) => {
    if (field?.includes('header_')) {
      const dataIndex = field.replace('header_', '');
      handleHeaderReset(dataIndex);
    } else {
      let newValue;

      if (Array.isArray(params[field])) {
        newValue = [...params[field]];
        newValue.splice(idx, 1);
      } else {
        newValue = '';
      }

      setParams({
        ...params,
        [field]: newValue,
        page: 0,
      });
    }
  };

  const handleHeaderSearch = (dataIndex, formValue) => {
    const value = formValue?.trim();
    if (value?.length) {
      const filterParams = {
        ...params,
        [`header_${dataIndex}`]: value,
        tipoBusca: 'dinamica',
      };

      setParams(filterParams);
    } else {
      handleHeaderReset(dataIndex);
    }
  };

  const renderCells = (record, key) => {
    if (key === 'totalMonth') return <S.CellDisabled>{record.months.totalMonth}</S.CellDisabled>;

    if (record.months[key]?.locked) {
      return <S.CellDisabled>{record.months[key]?.amount}</S.CellDisabled>;
    }

    if (!record.months[key]?.locked) {
      return <S.CellEdited>{record.months[key]?.amount}</S.CellEdited>;
    }
  };

  const columns = [
    {
      title: `${t('pages.demands.fields.sku')} - ${t('pages.demands.fields.model')}`,
      dataIndex: ['sku'],
      key: 'sku',
      editable: isNewItem,
      sorter: 'true',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: params.order === 'sku' && getSortOrderTable(params.orderBy),
      showSorterTooltip: { title: t('sort.orderBy') },
      width: '15rem',
      render: (value, row) => {
        const model = row?.modelName ? ` - ${row.modelName}` : '';
        return row.sku
          ? {
              props: { colSpan: 1 },
              children: `${value}${model}`,
            }
          : null;
      },
      ...getColumnSearchProps({
        dataIndex: 'sku',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        searchInput,
      }),
    },
    {
      title: t('pages.demands.fields.january'),
      dataIndex: ['january', 'amount'],
      key: 'january',
      editable: 'true',
      align: 'right',
      width: '4.5rem',
      render: (_, record) => renderCells(record, 'january'),
    },
    {
      title: t('pages.demands.fields.february'),
      dataIndex: ['february', 'amount'],
      key: 'february',
      editable: 'true',
      align: 'right',
      width: '4.5rem',
      render: (_, record) => renderCells(record, 'february'),
    },
    {
      title: t('pages.demands.fields.march'),
      dataIndex: ['march', 'amount'],
      key: 'march',
      editable: 'true',
      align: 'right',
      width: '4.5rem',
      render: (_, record) => renderCells(record, 'march'),
    },
    {
      title: t('pages.demands.fields.april'),
      dataIndex: ['april', 'amount'],
      key: 'april',
      editable: 'true',
      align: 'right',
      width: '4.5rem',
      render: (_, record) => renderCells(record, 'april'),
    },
    {
      title: t('pages.demands.fields.may'),
      dataIndex: ['may', 'amount'],
      key: 'may',
      editable: 'true',
      align: 'right',
      width: '4.5rem',
      render: (_, record) => renderCells(record, 'may'),
    },
    {
      title: t('pages.demands.fields.june'),
      dataIndex: ['june', 'amount'],
      key: 'june',
      editable: 'true',
      align: 'right',
      width: '4.5rem',
      render: (_, record) => renderCells(record, 'june'),
    },
    {
      title: t('pages.demands.fields.july'),
      dataIndex: ['july', 'amount'],
      key: 'july',
      editable: 'true',
      align: 'right',
      width: '4.5rem',
      render: (_, record) => renderCells(record, 'july'),
    },
    {
      title: t('pages.demands.fields.august'),
      dataIndex: ['august', 'amount'],
      key: 'august',
      editable: 'true',
      align: 'right',
      width: '4.5rem',
      render: (_, record) => renderCells(record, 'august'),
    },
    {
      title: t('pages.demands.fields.september'),
      dataIndex: ['september', 'amount'],
      key: 'september',
      editable: 'true',
      align: 'right',
      width: '4.5rem',
      render: (_, record) => renderCells(record, 'september'),
    },
    {
      title: t('pages.demands.fields.october'),
      dataIndex: ['october', 'amount'],
      key: 'october',
      editable: 'true',
      align: 'right',
      width: '4.5rem',
      render: (_, record) => renderCells(record, 'october'),
    },
    {
      title: t('pages.demands.fields.november'),
      dataIndex: ['november', 'amount'],
      key: 'november',
      editable: 'true',
      align: 'right',
      width: '4.5rem',
      render: (_, record) => renderCells(record, 'november'),
    },
    {
      title: t('pages.demands.fields.december'),
      dataIndex: ['december', 'amount'],
      key: 'december',
      editable: 'true',
      align: 'right',
      width: '4.5rem',
      render: (_, record) => renderCells(record, 'december'),
    },
    {
      title: t('pages.demands.fields.total'),
      dataIndex: ['totalMonth'],
      key: 'totalMonth',
      align: 'right',
      width: '5rem',
      render: (_, record) => renderCells(record, 'totalMonth'),
    },
  ];

  const csvHeaders = [
    { label: 'SKU', key: 'sku' },
    { label: t('pages.demands.fields.project'), key: 'project' },
    { label: t('pages.demands.fields.modelName'), key: 'modelName' },
    { label: t('pages.demands.fields.january'), key: 'january.amount' },
    { label: t('pages.demands.fields.february'), key: 'february.amount' },
    { label: t('pages.demands.fields.march'), key: 'march.amount' },
    { label: t('pages.demands.fields.april'), key: 'april.amount' },
    { label: t('pages.demands.fields.may'), key: 'may.amount' },
    { label: t('pages.demands.fields.june'), key: 'june.amount' },
    { label: t('pages.demands.fields.july'), key: 'july.amount' },
    { label: t('pages.demands.fields.august'), key: 'august.amount' },
    { label: t('pages.demands.fields.september'), key: 'september.amount' },
    { label: t('pages.demands.fields.october'), key: 'october.amount' },
    { label: t('pages.demands.fields.november'), key: 'november.amount' },
    { label: t('pages.demands.fields.december'), key: 'december.amount' },
    { label: t('pages.demands.fields.total'), key: 'totalMonth' },
  ];

  const columnsWithAction = [
    ...columns,
    {
      title: t('common.action'),
      key: 'action',
      align: 'center',
      width: '3rem',
      render: (_, record) => {
        const editable = isEditing(record);

        const actions = [
          {
            key: '1',
            label: (
              <EditButton
                type="link"
                disabled={
                  editingKey !== '' || isBaseScenario || isLoading || targetYear < dayjs().year()
                }
                onClick={() => edit(record, _)}
                data-cy="edit"
              >
                {t('common.edit')}
              </EditButton>
            ),
          },
          {
            key: '2',
            label: (
              <Popconfirm
                title={t('common.deleteMessage')}
                onConfirm={() => deleteItem(record.id)}
                placement="topLeft"
              >
                <Button
                  type="link"
                  danger
                  disabled={
                    editingKey !== '' || isBaseScenario || isLoading || targetYear < dayjs().year()
                  }
                  data-cy="delete"
                >
                  {t('common.delete')}
                </Button>
              </Popconfirm>
            ),
          },
        ];

        return editable ? (
          <Space direction="horizontal" size={12}>
            <Button type="primary" onClick={() => save(record.id)} data-cy="save">
              {t('common.save')}
            </Button>
            <Popconfirm title={t('common.cancelMessage')} onConfirm={cancel}>
              <Button shape="circle" default icon={<AiOutlineClose />} data-cy="cancel" />
            </Popconfirm>
          </Space>
        ) : (
          <TableAction dropdownItems={actions} />
        );
      },
    },
  ];

  const isEditable = (record, key) => {
    return !!(record.months?.[key] && !record.months?.[key].locked);
  };
  const mergedColumns = columnsWithAction.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: isNewItem ? true : isEditable(record, col.key),
        inputType: col.key === 'sku' ? 'text' : 'numberWithZero',
        required: col.key === 'sku',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return {
    data,
    params,
    pageControl,
    setParams,
    updateProject,
    updateProduct,
    fetchScenarios,
    scenariosOptions,
    isBaseScenario,
    canUpdate,
    selectedScenario,
    csvHeaders,
    mergedColumns,
    fetchForecast,
    changePageValue,
    handleChangeTable,
    isLoading,
    form,
    isNewItem,
    handleAddItem,
    onValuesChange,
    deleteAll,
    searchFilter,
    clearFilter,
    handleCloseFilteredTag,
  };
}
