import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button, Row } from 'antd';
import StyledButton from '../../components/Common/StyledButton';
import { StyledHeader } from '../../components/Common/StyledHeaderPage';
import { StyledLoading } from '../../components/Common/StyledLoading';
import StyledModal from '../../components/Common/StyledModal';
import { ComponentSpecifications } from '../../components/Qualification/ComponentSpecifications';
import { QualificationStatus } from '../../components/Qualification/QualificationStatus';
import { Datasheet } from './components/Datasheet';
import { CancelQualificationModal } from './components/Modal';
import { ODMDetails } from './components/ODMDetails';
import { QualificationRegisterInformations } from './components/QualificationRegisterInformations';
import { SampleTrackingNumber } from './components/SampleTrackingNumber';
import * as S from './styles';
import { useViewQualification } from './useViewQualification';

export function ViewQualification() {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    qualificationData,
    isLoading,
    isLoadingStatus,
    steps,
    form,
    openModal,
    setOpenModal,
    onModalClose,
    cancelQualification,
    verifyStatus,
  } = useViewQualification();

  return (
    <S.Container>
      <S.BackButton
        type="link"
        icon={<ArrowLeftOutlined />}
        onClick={() => history.push('/qualification')}
      >
        {t('pages.viewQualification.backButton')}
      </S.BackButton>
      <StyledHeader
        title={t('pages.viewQualification.title')}
        actions={
          verifyStatus() && (
            <StyledButton variant="primary" onClick={() => setOpenModal(true)}>
              <CloseOutlined />
              {t('pages.viewQualification.cancelQualification')}
            </StyledButton>
          )
        }
      />
      {isLoadingStatus ? <StyledLoading height={10} /> : <QualificationStatus data={steps} />}
      {isLoading ? (
        <StyledLoading height={10} />
      ) : (
        <>
          <StyledModal
            title="Cancel the Qualification"
            body={<CancelQualificationModal form={form} />}
            open={openModal}
            onClose={onModalClose}
            footer={
              <Row justify="space-between">
                <Button type="link" onClick={onModalClose}>
                  {t('common.cancel')}
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    form.validateFields().then(() => {
                      cancelQualification();
                      onModalClose();
                    });
                  }}
                >
                  {t('common.save')}
                </Button>
              </Row>
            }
            width="45rem"
            height="16rem"
          />
          <QualificationRegisterInformations
            {...qualificationData.qualificationRegisterInformation}
          />
          <ComponentSpecifications viewAcer {...qualificationData?.componentSpecification} />
          <Datasheet data={qualificationData.dataSheet || []} />
          <SampleTrackingNumber data={qualificationData.sampleTrackingNumbers || []} />
          <ODMDetails data={qualificationData?.odmDetails} />
        </>
      )}
    </S.Container>
  );
}
