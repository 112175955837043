import { Popconfirm } from 'antd';
import { ColumnType } from 'antd/lib/table';
import {
  Dispatch,
  Key,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FaTrashAlt } from 'react-icons/fa';
import useWebSocket from 'react-use-websocket';
import { SendJsonMessage, SendMessage } from 'react-use-websocket/dist/lib/types';
import { Button } from '../components/Button';
import { parseSKUNotification } from '../helpers/typed-utils';
import { dateFormat } from '../helpers/utils';
import { api } from '../services/api';
import { services } from '../services/services';
import { useAlert } from './AlertContext';
import {
  AlertNotification,
  JsonMessage,
  Notification,
  NotificationType,
  NotificationsParams,
  PageControl,
} from './types';

interface NotificationsContextData {
  notifications: Notification[];
  setNotifications: Dispatch<SetStateAction<Notification[]>>;
  fetchNotifications: () => Promise<void>;
  viewNotification: (id: string) => void;
  getNotificationDescription: (type: NotificationType, data: number) => string;
  deleteNotification: (id: string) => Promise<void>;
  deleteSomeNotifications: (ids: string[]) => Promise<void>;
  deleteAllNotifications: () => Promise<void>;
  isLoading: boolean;
  isOpen: boolean;
  handleOpen: () => void;
  onClose: () => void;
  params: NotificationsParams;
  pageControl: PageControl;
  changePageValue: (page: number, type: string) => void;
  columnsWithAction: ColumnType<Notification>[];
  selectedNotifications: Key[];
  handleSelection: (selectedKeys: Key[]) => void;
  toggleFullSelection: () => void;
  sendJsonMessage: SendJsonMessage;
  lastMessage: MessageEvent<any> | null;
  lastJsonMessage: JsonMessage;
  sendMessage: SendMessage;
  closeWebSocketConnection: () => void;
}

const NotificationsContext = createContext({} as NotificationsContextData);

const compareNotifications = (a: Notification, b: Notification): number => {
  if (a.status === b.status) {
    const dateA = new Date(a.date).getTime();
    const dateB = new Date(b.date).getTime();
    return dateB - dateA; // Sort in descending order
  }
  return a.status ? 1 : -1; // False (visualized) comes first
};

export const NotificationsProvider = ({ children }: { children: ReactNode }) => {
  const { openAlert } = useAlert();
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isOpen, setNotificationsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pageControl, setPageControl] = useState<PageControl>({
    page: 0,
    totalElements: 2,
    totalPages: 1,
    pageLabel: '1',
  });
  const [params, setParams] = useState<NotificationsParams>({
    page: 1,
    limit: 10,
  });
  const [selectedNotifications, setSelectedNotifications] = useState<React.Key[]>([]);

  const { sendJsonMessage, lastMessage, lastJsonMessage, sendMessage } = useWebSocket(
    `${import.meta.env.VITE_API_WEBSOCKET}?channel=Alerts`
  );

  const closeWebSocketConnection = () => {
    sendJsonMessage({ action: 'unsubscribe', channel: 'Alerts' });
  };

  const handleParseAlertNotificationToQualificationAlert = (
    alert: AlertNotification
  ): Notification => {
    const stamp = alert.creationDateTime
      ? new Date(alert.creationDateTime).toString()
      : new Date().toString();
    const dateTime = dateFormat(stamp);
    return {
      id: alert.id,
      category: alert.category,
      date: dateTime.date,
      time: dateTime.time,
      status: alert.visualized,
      description:
        alert.category === 'QD' ? t('notifications.qfDoneDesc') : t('notifications.qfFailDesc'),
    };
  };

  const fetchNotifications = async () => {
    try {
      const {
        data: { content },
      }: { data: { content: AlertNotification[] } } = await api.get(
        `${services.events}/list/alerts`
      );

      const parsedNotifications = content.map((noti) => {
        return noti.category === 'SKU'
          ? parseSKUNotification(noti)
          : handleParseAlertNotificationToQualificationAlert(noti);
      });

      const sortNoficationsByDate = parsedNotifications.sort(compareNotifications);

      setNotifications(sortNoficationsByDate);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (lastMessage !== null || lastJsonMessage !== null) {
      fetchNotifications();
    }
  }, [lastMessage, lastJsonMessage]);

  const handleOpen = () => {
    setNotificationsOpen(true);
  };

  const onClose = () => {
    setNotificationsOpen(false);
  };

  const handleSelection = (selectedRowKeys: React.Key[]) => {
    setSelectedNotifications(selectedRowKeys);
  };

  const toggleFullSelection = () => {
    if (selectedNotifications.length === notifications.length) {
      setSelectedNotifications([]);
    } else {
      setSelectedNotifications(notifications.map((n) => n.id));
    }
  };

  const getNotificationDescription = (type: NotificationType, data: number) => {
    const notificationsMap = {
      sku_no_motherboard: `${t('notifications.skuDesc', { data })}`,
    };
    return notificationsMap[type];
  };

  const getNotificationTitle = (type: NotificationType) => {
    const notificationsMap = {
      sku_no_motherboard: `${t('notifications.sku')}`,
    };
    return notificationsMap[type];
  };

  const viewNotification = async (alertId: string) => {
    await api
      .put(`${services.events}/alerts/vizualization/${alertId}`, {
        visualized: true,
      })
      .then(async (res) => {
        await fetchNotifications();
      })
      .catch((err) => console.error(err));
  };

  const deleteAllNotifications = async () => {
    await api
      .delete(`${services.events}/list/alerts`)
      .then(async (res) => {
        await fetchNotifications();
        openAlert('success', t('toast.successOnDelete'));
      })
      .catch((err) => {
        openAlert('error', t('toast.errorOnDelete'));
      });
  };

  const deleteSomeNotifications = async (ids: string[] | number[]) => {
    await api
      .delete(`${services.events}/alerts/lot`, {
        data: {
          ids: ids.join(),
        },
      })
      .then(async (res) => {
        await fetchNotifications();
        openAlert('success', t('toast.successOnDelete'));
      })
      .catch((err) => {
        openAlert('error', t('toast.errorOnDelete'));
      });
  };

  const deleteNotification = async (id: string) => {
    await api
      .delete(`${services.events}/list/alerts/${id}`)
      .then(async (res) => {
        await fetchNotifications();
        openAlert('success', t('toast.successOnDelete'));
      })
      .catch((err) => {
        openAlert('error', t('toast.errorOnDelete'));
      });
  };

  const changePageValue = (page: number, type: string) => {
    if (type === 'input' || type === 'navigation') {
      setParams({ ...params, page: page - 1 });
      setPageControl({ ...pageControl, pageLabel: `${page}` });
    } else {
      setPageControl({ ...pageControl, pageLabel: `${page}` });
    }
  };

  const columns: ColumnType<Notification>[] = [
    {
      title: t('pages.notifications.fields.alert'),
      dataIndex: 'type',
      key: 'type',
      width: 600,
      sorter: true,
      render: (_, record) => {
        return <span>{record.description}</span>;
      },
    },
    {
      title: t('pages.notifications.fields.dateTime'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      render: (_, record) => {
        return <span>{`${record.date} - ${record.time}`}</span>;
      },
    },
  ];

  const columnsWithAction: ColumnType<Notification>[] = [
    ...columns,
    {
      title: t('common.action'),
      key: 'actions',
      width: 100,
      align: 'center',
      render: (_, record: any) => {
        return (
          <Popconfirm
            onClick={(e) => e.stopPropagation()}
            title={t('common.deleteMessage')}
            onConfirm={() => deleteNotification(record.id)}
            placement="left"
            cancelText={t('common.cancel')}
          >
            <Button
              icon={null}
              iconEnd={null}
              buttonText={null}
              onClick={null}
              type="link"
              style={{ padding: 0, width: '100%' }}
            >
              <FaTrashAlt color="#FF4D4D" size={18} />
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  useEffect(() => {
    fetchNotifications();
  }, []);

  useLayoutEffect(() => {
    const handleBeforeUnload = () => {
      closeWebSocketConnection();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const data = useMemo(
    () => ({
      notifications,
      fetchNotifications,
      getNotificationDescription,
      deleteNotification,
      deleteAllNotifications,
      deleteSomeNotifications,
      isOpen,
      handleOpen,
      onClose,
      isLoading,
      pageControl,
      params,
      changePageValue,
      columnsWithAction,
      selectedNotifications,
      handleSelection,
      toggleFullSelection,
      sendJsonMessage,
      lastMessage,
      lastJsonMessage: lastJsonMessage as JsonMessage,
      sendMessage,
      setNotifications,
      viewNotification,
      closeWebSocketConnection,
    }),
    [
      notifications,
      isOpen,
      isLoading,
      params,
      lastMessage,
      lastJsonMessage,
      columnsWithAction,
      selectedNotifications,
      pageControl,
    ]
  );
  return <NotificationsContext.Provider value={data}>{children}</NotificationsContext.Provider>;
};

export const useNotifications = () => {
  return useContext(NotificationsContext);
};
