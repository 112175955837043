import styled from 'styled-components';

export const Container = styled.main<{ isWeekend: boolean; isActualDay: boolean }>`
  display: flex;
  flex-direction: column;
  min-width: 6rem;
  min-height: 6.18rem;
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem 0rem 0.5rem;
  border: 1px solid
    ${({ theme, isActualDay }) =>
      isActualDay ? theme.colorsDesignSystem.textEditable : theme.colorsDesignSystem.grayBorder};

  box-shadow: 0px 2px 0px 0px ${({ theme }) => theme.colorsDesignSystem.scheduleBoxShadow};
  background-color: ${({ theme, isWeekend, isActualDay }) =>
    isActualDay
      ? theme.colorsDesignSystem.scheduleDetails
      : isWeekend
      ? theme.colorsDesignSystem.scheduleDayWeekend
      : theme.colorsDesignSystem.white};
`;

export const LabelDay = styled.span`
  font-size: 10.875rem;
  font-style: normal;
  font-weight: 700;
`;

export const Point = styled.div<{ late?: boolean }>`
  width: 0.38rem;
  height: 0.38rem;
  border-radius: 50%;
  background-color: ${({ theme, late }) =>
    late ? theme.colorsDesignSystem.tagColorBlue : theme.colorsDesignSystem.red};
`;

export const TitleEventContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

export const TitleEvent = styled.span`
  font-size: 0.625rem;
  font-weight: 400;
`;

export const ContainerSmall = styled.div<{ isActualDay?: boolean; isDayOfCurrentMonth?: boolean }>`
  width: 1rem;
  height: 1.375rem;
  display: flex;
  padding: 0.65rem;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  color: ${({ theme, isActualDay }) => (isActualDay ? 'white' : theme.colorsDesignSystem.primary)};
  border: ${({ isActualDay }) => (isActualDay ? `1px solid white` : null)};
  opacity: ${({ isDayOfCurrentMonth }) => (isDayOfCurrentMonth ? `100%` : '35%')};
`;


