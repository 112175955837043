import { ArrowRightOutlined, TagOutlined } from '@ant-design/icons';
import { Divider, Flex } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TrackingTagEnum } from '../../../helpers/enums';
import { TrackingTag } from '../TrackingTag';
import { SampleStatus } from './SampleStatus';
import * as S from './styles';
import { ISampleTrackingNumberModal } from './types';
import { useSampleTrackingNumberModal } from './useSampleTrackingNumberModal';

export function SampleTrackingNumberModal({ trackingNumberData }: ISampleTrackingNumberModal) {
  const { t } = useTranslation();
  const { dhlData, fedexData, handleFetchData } = useSampleTrackingNumberModal();

  const isDHL = trackingNumberData.freightForwarder === 'DHL';

  useEffect(() => {
    handleFetchData(trackingNumberData);
  }, [trackingNumberData]);

  return (
    <S.Container gap={16}>
      <S.TrackingNumberInformation>
        <Flex justify="space-between" align="center">
          <Flex gap={16}>
            <S.Icon>
              <TagOutlined />
            </S.Icon>
            <Flex vertical gap={4}>
              <S.Label>{t('components.modals.sampleTrackingNumber.trackingNumber')}</S.Label>
              <S.Value size="large">{trackingNumberData.trackingNumber}</S.Value>
            </Flex>
          </Flex>
          <TrackingTag status={TrackingTagEnum.New} />
        </Flex>

        <Flex gap={24} align="center">
          <Flex vertical gap={4}>
            <S.Label>JAN 22, 2025</S.Label>
            <S.Value size="large">
              {isDHL ? dhlData?.shipments[0].origin.address.addressLocality : fedexData?.from}
            </S.Value>
          </Flex>
          <ArrowRightOutlined className="arrow" />
          <Flex vertical gap={4}>
            <S.Label>FEB 12, 2025</S.Label>
            <S.Value size="large">
              {isDHL ? dhlData?.shipments[0].destination.address.addressLocality : fedexData?.to}
            </S.Value>
          </Flex>
        </Flex>
        <S.Divider />

        <Flex vertical gap={4}>
          <S.Label>{t('components.modals.sampleTrackingNumber.freightForwarder')}</S.Label>
          <S.Value>{trackingNumberData.freightForwarder}</S.Value>
        </Flex>

        <Flex gap={34}>
          <Flex vertical gap={4}>
            <S.Label>{t('components.modals.sampleTrackingNumber.responsibleSupplier')}</S.Label>
            <S.Value>
              {trackingNumberData.resposibleSupplier} - <i>{trackingNumberData.supplier}</i>
            </S.Value>
          </Flex>
          <Flex vertical gap={4}>
            <S.Label>{t('components.modals.sampleTrackingNumber.dateInput')}</S.Label>
            <S.Value>{trackingNumberData.dateHour}</S.Value>
          </Flex>
        </Flex>

        <Flex vertical gap={4}>
          <S.Label>{t('components.modals.sampleTrackingNumber.odm')}</S.Label>
          <S.Value>{trackingNumberData.odmName}</S.Value>
        </Flex>

        <Flex gap={34}>
          <Flex vertical gap={4}>
            <S.Label>{t('components.modals.sampleTrackingNumber.responsibleODM')}</S.Label>
            <S.Value>
              Tina - <i>Compal</i>
            </S.Value>
          </Flex>
          <Flex vertical gap={4}>
            <S.Label>{t('components.modals.sampleTrackingNumber.deliveryForecast')}</S.Label>
            <S.Value>07.25.2024 - 01:54 pm</S.Value>
          </Flex>
        </Flex>
      </S.TrackingNumberInformation>
      <S.TrackingNumberStatus vertical>
        <S.StatusTitle>
          {t('components.modals.sampleTrackingNumber.statusTrackingNumber')}
        </S.StatusTitle>
        <Divider />
        <SampleStatus dhlData={dhlData} fedexData={fedexData} />
      </S.TrackingNumberStatus>
    </S.Container>
  );
}
