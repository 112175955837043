import { useTranslation } from 'react-i18next';

import * as S from './styles';
import CheckedEmail from '../../../assets/checked_maill.svg';
import { CheckedEmailProps } from './types';

export function ConfirmationSendMailModal({ subTitle, title }: CheckedEmailProps) {
  const { t } = useTranslation();
  return (
    <S.Container>
      <S.Image src={CheckedEmail} />
      <S.FirstText>{title}</S.FirstText>
      <S.SecondText>{subTitle}</S.SecondText>
    </S.Container>
  );
}
