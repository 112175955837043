import { Form, Input, Select } from 'antd';
import { t } from 'i18next';
import { useEffect } from 'react';
import * as S from './styles';
import { ModalTrackingProps } from './types';
import { useModalTrackingNumber } from './useModalTrackingNumber';

export default function ModalNewTrackingNumber({
  form,
  selectedQualification,
}: ModalTrackingProps) {
  const { forWarderOptions, handleGetOdmFilterData, odmOptions, isLoadingOptions } =
    useModalTrackingNumber();

  useEffect(() => {
    handleGetOdmFilterData(selectedQualification);
  }, [selectedQualification]);

  return (
    <S.Container>
      <Form form={form} layout="vertical">
        <S.StyledFlex>
          <S.StyledForItem
            name="odmName"
            label={
              <S.CustomLabel>
                {t('pages.qualificationSupplierView.modal.placeholders.odm')}
              </S.CustomLabel>
            }
          >
            <Select loading={isLoadingOptions} options={odmOptions} />
          </S.StyledForItem>
          <S.StyledForItem
            name="freightForwarder"
            label={
              <S.CustomLabel>
                {t('pages.qualificationSupplierView.modal.placeholders.forwarder')}
              </S.CustomLabel>
            }
          >
            <Select options={forWarderOptions} />
          </S.StyledForItem>
        </S.StyledFlex>
        <S.StyledForItem
          name="trackingNumber"
          label={
            <S.CustomLabel>
              {t('pages.qualificationSupplierView.modal.placeholders.number')}
            </S.CustomLabel>
          }
        >
          <Input />
        </S.StyledForItem>
      </Form>
    </S.Container>
  );
}
