import { useState } from 'react';
import { StyledTab } from '../../../../components/Common/StyledTabs/types';
import { OdmsAssocieted } from '../../types';

export function useODMDetails() {
  const [tabs, setTabs] = useState<StyledTab[]>([]);

  const handleSetTabs = (odms: Array<OdmsAssocieted>) => {
    setTabs(
      odms.map((odm, index) => {
        return {
          selected: index === 0,
          title: odm.odmName,
        };
      })
    );
  };

  const handleGetOdmSelected = (odms: Array<OdmsAssocieted>): OdmsAssocieted => {
    return odms[tabs.findIndex((tab) => tab.selected)];
  };

  return {
    tabs,
    setTabs,
    handleSetTabs,
    handleGetOdmSelected,
  };
}
