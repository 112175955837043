import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import StyledClearTag from '../../../components/Common/StyledFilterTag/StyledClearTag';
import { StyledSelectOptions } from '../../../components/Common/StyledSelect/types';
import { useAlert } from '../../../context/AlertContext';
import { formatDataToSelect } from '../../../helpers/nUtils';
import { getURI } from '../../../helpers/utils';
import { api } from '../../../services/api';
import { services } from '../../../services/services';
import { TagsContainer } from './styles';
import { IParams } from './types';

export function useManagementBid() {
  const { openAlert } = useAlert();
  const { id: idBid } = useParams<{ id: string }>();
  const initialParams: IParams = {
    category: null,
    altGroup: null,
    supplier: null,
    idBid,
  };
  const [isFirstRendering, setIsFirstRendering] = useState<boolean>(true);
  const [params, setParams] = useState<IParams>(initialParams);
  const [selectedParams, setSelectedParams] = useState<IParams>(initialParams);
  const [categories, setCategories] = useState<StyledSelectOptions[]>([]);
  const [altGroups, setAltGroups] = useState<StyledSelectOptions[]>([]);
  const [supplier, setSupplier] = useState<StyledSelectOptions[]>([]);
  const showTagsFilter = params.category || params.altGroup || params.supplier;

  const changeParam = (param: keyof IParams, value: string | null) => {
    setParams((prev) => {
      return { ...prev, [param]: value };
    });
  };

  const clearAllFilters = () => {
    setParams(initialParams);
    setSelectedParams(initialParams);
  };

  const fetchCategories = async () => {
    try {
      const { status, data } = await api.get(
        getURI(`${services.rfq}/bid/management/filters`, { idBid })
      );
      if (status === 200) {
        setCategories(formatDataToSelect(data.categories, true));
      } else setCategories([]);
    } catch (error: any) {
      openAlert('error', error.message);
    }
  };

  const fetchAltGroups = async () => {
    try {
      const { status, data } = await api.get(
        getURI(`${services.rfq}/bid/management/filters`, { idBid, category: params.category })
      );
      if (status === 200) {
        setAltGroups(formatDataToSelect(data.altGroups, true));
      } else setAltGroups([]);
    } catch (error: any) {
      openAlert('error', error.message);
    }
  };

  const fetchSupplier = async () => {
    try {
      const { status, data } = await api.get(
        getURI(`${services.rfq}/bid/management/filters`, {
          idBid,
          category: params.category,
          altGroup: params.altGroup,
        })
      );
      if (status === 200) {
        setSupplier(formatDataToSelect(data.suppliers, true));
      } else setSupplier([]);
    } catch (error: any) {
      openAlert('error', error.message);
    }
  };

  const handleParamsTags = () => {
    return (
      <TagsContainer>
        {showTagsFilter && <StyledClearTag onClick={clearAllFilters} />}
      </TagsContainer>
    );
  };

  const handleCategoryParamsChange = () => {
    if (!isFirstRendering) {
      setParams((prev) => {
        return { ...prev, altGroups: null, supplier: null };
      });
      Promise.all([fetchAltGroups(), fetchSupplier()]);
    }
  };

  const handleAltGroupsParamsChange = () => {
    if (!isFirstRendering) {
      setParams((prev) => {
        return { ...prev, supplier: null };
      });
      Promise.all([fetchSupplier()]);
    }
  };

  const fetchAllData = () => {
    if (idBid && isFirstRendering) {
      Promise.all([fetchCategories(), fetchAltGroups(), fetchSupplier()]).finally(() =>
        setIsFirstRendering(false)
      );
    }
  };

  useEffect(() => {
    handleCategoryParamsChange();
  }, [params.category]);

  useEffect(() => {
    handleAltGroupsParamsChange();
  }, [params.altGroup]);

  useEffect(() => {
    fetchAllData();
  }, []);

  return {
    params,
    categories,
    altGroups,
    supplier,
    changeParam,
    handleParamsTags,
    setSelectedParams,
    selectedParams,
  };
}
