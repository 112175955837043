import { Button } from 'antd';
import styled from 'styled-components';

export const Container = styled.main`
  background-attachment: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.background};
  min-height: 95vh;
  box-sizing: content-box;
  .pageTitle {
    margin-bottom: 1rem;
  }
`;

export const BackButton = styled(Button)`
  padding: 0;
`;
