import { Divider, Empty, Flex } from 'antd';
import * as S from './styles';
import { TermsAndConditonsProps } from './types';
import { useTermsConditionsModal } from './useTermsConditionsModal';

export function TermsAndConditions(data: TermsAndConditonsProps) {
  const { renderStatusCard } = useTermsConditionsModal();
  return data.bidName ? (
    <>
      <S.TermsConditionsContentTitle>{data.bidName}</S.TermsConditionsContentTitle>
      <Divider />
      <Flex>
        <div
          dangerouslySetInnerHTML={{
            __html: data.termsConditionsTemplate || '',
          }}
        />
      </Flex>
      <Divider />
      <Flex align="center" justify="space-between" style={{ overflow: 'auto' }}>
        <Flex align="center" gap={16}>
          {data.termsStatus && renderStatusCard(data.termsStatus)}
        </Flex>
      </Flex>
    </>
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  );
}
