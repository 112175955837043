import { ArrowLeftOutlined } from '@ant-design/icons';
import { Flex, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { StyledLoading } from '../../components/Common/StyledLoading';
import StyledSelect from '../../components/Common/StyledSelect';
import StyledTabs from '../../components/Common/StyledTabs';
import { StyledTab } from '../../components/Common/StyledTabs/types';
import { AwardListPage } from '../AwardSummary/AwardList';
import { QuotationFilesPages } from '../AwardSummary/QuotationFiles';
import * as S from './styles';
import { useAwardSummaryCMView } from './useAwardSummaryCMView';

export function AwardSummaryCMView() {
  const { t } = useTranslation();
  const history = useHistory();
  const [tabs, setTabs] = useState<StyledTab[]>([
    { selected: true, title: t('pages.awards.tabs.awardsList') },
    { selected: false, title: t('pages.awards.tabs.quotattionFiles') },
  ]);

  const { idBid, bidOptions, loading, getIdBid, setIdBid } = useAwardSummaryCMView();

  useEffect(() => {
    getIdBid();
  }, []);
  return (
    <S.Container>
      <Row>
        <S.BackButton
          type="link"
          icon={<ArrowLeftOutlined />}
          onClick={() => history.push(`/home`)}
        >
          {t('components.navigation.backToHome')}
        </S.BackButton>
      </Row>
      {loading ? (
        <StyledLoading height={10} />
      ) : (
        <>
          <Flex justify="space-between" align="center">
            <StyledTabs tabs={tabs} setSelectedTab={setTabs} />
            <StyledSelect
              label="BID"
              value={idBid || ''}
              options={bidOptions}
              onChange={(value: string) => setIdBid(value)}
              styles={{ width: '17.5rem' }}
            />
          </Flex>
          {tabs[0].selected && <AwardListPage IdBid={idBid} cmView />}
          {tabs[1].selected && <QuotationFilesPages IdBid={idBid} cmView />}
        </>
      )}
    </S.Container>
  );
}
