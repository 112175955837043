import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledSelectOptions } from '../../components/Common/StyledSelect/types';
import { oneAlert } from '../../helpers/nUtils';
import { getURI } from '../../helpers/utils';
import { api } from '../../services/api';
import { services } from '../../services/services';

export const useAwardSummaryCMView = () => {
  const [bidOptions, setBidOption] = useState<StyledSelectOptions[]>([]);
  const [idBid, setIdBid] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const formatIdOptions = (data: Array<any>) => {
    if (data.length > 0) {
      const formattedData: StyledSelectOptions[] = data.map((item) => {
        return {
          label: item.bidName,
          value: item.id,
        };
      });

      return formattedData;
    }
    return [];
  };
  const getIdBid = async () => {
    setLoading(true);
    try {
      const { data, status } = await api.get(getURI(`${services.rfq}/bid/filter`));
      if (status === 200) {
        setBidOption(formatIdOptions(data?.content));

        setIdBid(data?.content[0].id);
      }
      return;
    } catch (error) {
      oneAlert({
        type: 'error',
        message: t('toast.errorOnList'),
      });
    } finally {
      setLoading(false);
    }
  };
  return { idBid, bidOptions, loading, getIdBid, setIdBid };
};
