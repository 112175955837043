import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18next';
import { useHistory } from 'react-router-dom';
import { AssociatedOdmData } from './AssociatedOdms/types';
import { AssociatedOdms } from './AssociatedOdms';
import { oneAlert } from '../../../helpers/utils';
import { api } from '../../../services/api';
import { services } from '../../../services/services';
import { RoutesPath } from '../../../routes/routesPath';

export function useStepTwoAssociatedOdms() {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const initialState = {
    isEditing: true,
    odmName: '',
    taxId: '',
    sampleQuantity: '',
    sampleRequest: dayjs(),
    index: 0,
    contact: {
      person: '',
      email: '',
      mobileNumber: '',
      phoneNumber: '',
      zipCode: '',
      country: '',
      address: '',
      additionalInformation: '',
    },
  };

  const [components, setComponents] = useState<Array<AssociatedOdmData>>([initialState]);

  const handleDeleteComponent = (component: AssociatedOdmData) => {
    setComponents((prevState) => {
      return prevState.filter((comp) => comp.index !== component.index);
    });
  };

  const handleSetComponentIsEditing = (component: AssociatedOdmData) => {
    setComponents((prevState) => {
      return prevState.map((comp) =>
        comp.index === component.index ? { ...comp, isEditing: !component.isEditing } : comp
      );
    });
  };

  //máximo dois ODMs cadastrados
  const handleVerifyIfCanAddNewOdm = (): boolean => {
    return components.filter((component) => !component.isEditing).length === 2;
  };

  const handleVerifyIfExistsNewComponent = (): boolean => {
    return components.some((component) => component.isEditing);
  };

  const handleDisableButtonAddNewOdm = () => {
    return handleVerifyIfCanAddNewOdm() || handleVerifyIfExistsNewComponent();
  };

  const handleAddNewComponent = () => {
    setComponents((prevState) => {
      return [...prevState, { ...initialState, index: prevState.length + 1 }];
    });
  };

  const handleVerifyIsExistOtherOdmWhitTheSameName = (odmName: string): boolean => {
    return components.some((component) => component.odmName.trim() === odmName.trim());
  };

  const handleSaveODM = (component: AssociatedOdmData) => {
    if (!handleVerifyIsExistOtherOdmWhitTheSameName(component.odmName)) {
      setComponents((prevState) => {
        return prevState.map((comp) =>
          comp.index === component.index
            ? {
                ...comp,
                contact: component.contact,
                isEditing: false,
                odmName: component.odmName,
                sampleQuantity: component.sampleQuantity,
                sampleRequest: component.sampleRequest,
              }
            : comp
        );
      });
    } else {
      oneAlert('success', t('pages.requesRegistration.components.stepTwo.errorMessages.sameOdm'));
    }
  };

  const handleRenderAssociatedComponents = () => {
    return components.map((component) => {
      return (
        <AssociatedOdms
          deleteItem={handleDeleteComponent}
          editItem={handleSetComponentIsEditing}
          saveItem={handleSaveODM}
          key={component.index}
          item={component}
        />
      );
    });
  };

  const handleSendOdms = async (
    registerComponentId: string,
    setNextStep: React.Dispatch<React.SetStateAction<number>>,
    setIdStepTwo: React.Dispatch<React.SetStateAction<string>>
  ) => {
    try {
      setIsLoading(true);
      const dataToSend = components.map((component) => {
        return {
          additionalInfo: component.contact.additionalInformation,
          address: component.contact.address,
          companyContactPerson: component.contact.person,
          country: component.contact.country,
          email: component.contact.email,
          mobileNumber: component.contact.mobileNumber,
          odmName: component.odmName,
          phoneNumber: component.contact.phoneNumber,
          sampleQuantity: component.sampleQuantity,
          sampleRequest: component.sampleRequest,
          taxId: component.taxId,
          zipCode: component.contact.zipCode,
          status: 'ASSOCIATED ODMS',
        };
      });
      const { status, data } = await api.post(`${services.qualification}/associated/odm/add`, {
        registerComponentId,
        odmsAssocieted: dataToSend,
      });
      if (status === 201 || status === 200) {
        setIdStepTwo(data.id);
        setNextStep(2);
        localStorage.setItem('associetedOdm', JSON.stringify(dataToSend));
        oneAlert('success', t('toast.successOnSave'));
      }
    } catch (error) {
      console.log(error);
      oneAlert('error', t('toast.errorOnSave'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateOdms = async (
    registerComponentId: string,
    associetedOdmId: string,
    setNextStep: React.Dispatch<React.SetStateAction<number>>,
    setIdStepTwo: React.Dispatch<React.SetStateAction<string>>
  ) => {
    try {
      setIsLoading(true);
      const dataToSend = components.map((component) => {
        return {
          additionalInfo: component.contact.additionalInformation,
          address: component.contact.address,
          companyContactPerson: component.contact.person,
          country: component.contact.country,
          email: component.contact.email,
          mobileNumber: component.contact.mobileNumber,
          odmName: component.odmName,
          phoneNumber: component.contact.phoneNumber,
          sampleQuantity: component.sampleQuantity,
          sampleRequest: component.sampleRequest,
          taxId: component.taxId,
          zipCode: component.contact.zipCode,
          status: 'ASSOCIATED ODMS',
        };
      });
      const { status, data } = await api.put(
        `${services.qualification}/associated/odm/${associetedOdmId}`,
        {
          registerComponentId,
          odmsAssocieted: dataToSend,
        }
      );
      if (status === 201 || status === 200) {
        setIdStepTwo(data.id);
        setNextStep(2);
        localStorage.setItem('associetedOdm', JSON.stringify(dataToSend));
        oneAlert('success', t('toast.successOnSave'));
      }
    } catch (error) {
      console.log(error);
      oneAlert('error', t('toast.errorOnSave'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyIfCanSendOdms = (): boolean => {
    return components.filter((component) => !component.isEditing).length === 0;
  };

  const handleGetDataFromStorage = () => {
    const storedData = localStorage.getItem('associetedOdm');
    return storedData ? JSON.parse(storedData) : null;
  };

  const handleLoadPreviousInfo = () => {
    const data = handleGetDataFromStorage();
    setComponents(
      data.map((item: any, index: number) => {
        return {
          isEditing: false,
          index,
          odmName: item.odmName,
          taxId: item.taxId,
          sampleQuantity: item.sampleQuantity,
          sampleRequest: item.sampleRequest,
          contact: {
            person: item.companyContactPerson,
            email: item.email,
            mobileNumber: item.mobileNumber,
            phoneNumber: item.phoneNumber,
            zipCode: item.zipCode,
            country: item.country,
            address: item.address,
            additionalInformation: item.additionalInfo,
          },
        };
      })
    );
  };

  const handleCancel = () => {
    history.push(RoutesPath.qualification);
  };

  return {
    isLoading,
    components,
    handleRenderAssociatedComponents,
    handleVerifyIfExistsNewComponent,
    handleAddNewComponent,
    handleSaveODM,
    handleDisableButtonAddNewOdm,
    handleSendOdms,
    handleVerifyIfCanSendOdms,
    handleLoadPreviousInfo,
    handleUpdateOdms,
    handleCancel,
  };
}
