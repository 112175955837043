import { PaperClipOutlined, UserOutlined } from '@ant-design/icons';
import { TableColumnsType } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { HttpStatusCode } from 'axios';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAlert } from '../../../context/AlertContext';
import { getSortOrder, getURI } from '../../../helpers/utils';
import { api } from '../../../services/api';
import { services } from '../../../services/services';
import * as S from './styles';
import { QuotationFilesData } from './types';

export function useQuotationFiles(idBid?: string, cmView?: boolean) {
  const { openAlert } = useAlert();
  const { t } = useTranslation();
  const [quotationFilesData, setQuotationFilesData] = useState<QuotationFilesData[]>([]);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({ sortDirection: 'asc' });
  const { id: bidId } = useParams<{ id: string }>();
  const fetchQuotationFilesData = async () => {
    try {
      setLoading(true);
      const { data, status }: { data: QuotationFilesData[]; status: HttpStatusCode } =
        await api.get(
          getURI(`${services.rfq}/filedownload/bid/${bidId || idBid}/contextUpload/ViewUserAcer`, {
            ...params,
            cmView,
          })
        );
      if (status === 200) {
        setQuotationFilesData(data);
      }
      if (status === 204) {
        setQuotationFilesData([]);
      }
    } catch (error: any) {
      console.log(error);
      openAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setLoading(false);
    }
  };
  const handleChangeTable = (sorter: SorterResult<QuotationFilesData>) => {
    setParams({
      sortDirection: getSortOrder(sorter.order).toLowerCase(),
    });
  };

  const columns: TableColumnsType<QuotationFilesData> = [
    {
      title: t('pages.awards.quotationFiles.columnsTitle.uploadFiles'),
      dataIndex: 'fileName',
      key: 'fileName',
      align: 'left',
      width: '25%',

      render: (text: string, record: QuotationFilesData) => {
        return (
          <a href={record.urlDownloaded} rel="noreferrer">
            <S.LinkTable>
              <PaperClipOutlined />
              <span>{text}</span>
            </S.LinkTable>
          </a>
        );
      },
    },
    {
      title: t('pages.awards.quotationFiles.columnsTitle.cm'),
      dataIndex: 'cm',
      key: 'cm',
      align: 'left',
      width: '10%',
      //coluna oculta pois ainda não temos vinculo de cm nessa tela
      hidden: true,
    },
    {
      title: t('pages.awards.quotationFiles.columnsTitle.supplier'),
      dataIndex: 'supplier',
      key: 'supplier',
      align: 'left',
      width: '15%',
    },
    {
      title: t('pages.awards.quotationFiles.columnsTitle.dateHour'),
      dataIndex: 'sendDate',
      key: 'sendDate',
      align: 'left',
      width: '20%',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text: string, record: QuotationFilesData) => {
        return dayjs(record.sendDate).format('DD/MM/YYYY - hh:mm a');
      },
    },
    {
      title: t('pages.awards.quotationFiles.columnsTitle.responsible'),
      dataIndex: 'userUploaded',
      key: 'userUploaded',
      align: 'left',
      width: '19%',
      render: (text: string) => {
        return (
          <S.UserContainer>
            <UserOutlined />
            <span>{text}</span>
          </S.UserContainer>
        );
      },
    },
  ];

  return {
    columns,
    quotationFilesData,
    loading,
    fetchQuotationFilesData,
    handleChangeTable,
  };
}
