import { AuditOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import styled from 'styled-components';
import StyledButton from '../../../components/Common/StyledButton';

export const SummaryContent = styled.div`
  padding: 1.875rem 3rem;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

export const SummaryCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1.5rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.header};
  border-radius: 0.375rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.backgroundSecondaryColor};

  b {
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.375rem;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }
`;

export const StepsContent = styled(Row)`
  justify-content: center;
  padding: 1.5rem 0;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  background: ${({ theme }) => theme.colorsDesignSystem.secondaryGrayBackground};

  .ant-steps {
    max-width: 65rem;
  }

  .ant-steps .ant-steps-item-title::after {
    background-color: ${({ theme }) => theme.colorsDesignSystem.blue};
  }

  .ant-steps
    .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: ${({ theme }) => theme.colorsDesignSystem.blue};
  }

  .ant-steps
    .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: ${({ theme }) => theme.colorsDesignSystem.grayBorderTooltip};
  }

  .ant-steps
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: ${({ theme }) => theme.colorsDesignSystem.blue};
  }

  .ant-steps
    .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    font-weight: 700;
    color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
  }

  .ant-steps .ant-steps-item-process.ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
    color: ${({ theme }) => theme.colorsDesignSystemblue};
  }

  .ant-steps .ant-steps-item-finish.ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
    color: ${({ theme }) => theme.colorsDesignSystem.blue};
  }

  .ant-steps
    .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: ${({ theme }) => theme.colorsDesignSystem.grayText};
    font-size: 0.625rem;
    line-height: 0.75rem;
  }

  .ant-steps
    .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: ${({ theme }) => theme.colorsDesignSystem.grayBorderTooltip};
    font-weight: 600;
    font-size: 1rem;
  }

  .ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: ${({ theme }) => theme.colors.sec};
  }

  .ant-steps
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: ${({ theme }) => theme.colorsDesignSystem.iconGrey};
    font-size: 0.625rem;
    line-height: 0.75rem;
  }

  .ant-steps
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: ${({ theme }) => theme.colorsDesignSystem.iconGrey};
    font-size: 0.625rem;
    line-height: 0.75rem;
  }
`;

export const AlertCancelLabel = styled.span`
  font-size: 1rem;
  font-weight: 600;
`;

export const AlertCancelMessage = styled.span`
  font-size: 1rem;
  font-weight: 400;
`;

export const TermsConditionsButton = styled(StyledButton)`
  font-size: 0.875rem;
  width: 85%;
  font-weight: 700;
  color: ${({ theme }) => theme.colorsDesignSystem.tabBarInactiveColor};
`;

export const AuditIcon = styled(AuditOutlined)`
  font-size: 1rem;
  color: ${({ theme }) => theme.colorsDesignSystem.iconGrey};
`;
