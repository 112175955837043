import dayjs from 'dayjs';
import * as S from './styles';
import { PartNumbersEvent } from './types';

export function useScheduleDay() {
  const handleVerifyIfIsWeekend = (currentDay: dayjs.Dayjs): boolean => {
    return currentDay.day() === 0 || currentDay.day() === 6;
  };

  const handleRenderEvents = (partNumbers: Array<PartNumbersEvent>) => {
    return partNumbers.map((partNumber) => {
      return (
        <S.TitleEventContainer key={partNumber.partNumber}>
          <S.Point late={partNumber.isDelayed} />
          <S.TitleEvent>{partNumber.partNumber}</S.TitleEvent>
        </S.TitleEventContainer>
      );
    });
  };

  const handleVerifyIfIsSelected = (day: string): boolean => {
    return day === dayjs().date().toString();
  };

  const handleVerifyIfDayIfCurrentMonth = (
    currentDate: dayjs.Dayjs,
    selectedDay: dayjs.Dayjs
  ): boolean => {
    return currentDate.month() === selectedDay.month();
  };

  return {
    handleVerifyIfIsWeekend,
    handleRenderEvents,
    handleVerifyIfIsSelected,
    handleVerifyIfDayIfCurrentMonth,
  };
}
