import ExclamationCircle from '../../../assets/exclamation-circle.svg';
import * as S from './styles';
import { CardStatusProps } from './types';

export function StyledCardAccepted({ status, time, user }: CardStatusProps) {
  return (
    <S.Card>
      <S.CardTitle>
        {status === 'accepted' ? (
          <>
            <S.AcceptedIcon src={ExclamationCircle} />
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </>
        ) : (
          <>
            <S.DeniedIcon />
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </>
        )}
      </S.CardTitle>
      <S.Time>{time}</S.Time>
      <S.User>{user}</S.User>
    </S.Card>
  );
}
