import { ArrowLeftOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { BiDownload } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import { CsvExport } from '../../components/Common/CsvExport';
import StyledButton from '../../components/Common/StyledButton';
import { StyledHeader } from '../../components/Common/StyledHeaderPage';
import StyledTabs from '../../components/Common/StyledTabs';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { AwardSimulation } from '../AwardSimulation';
import { AwardListPage } from './AwardList';
import { useAwardList } from './AwardList/useAwardList';
import { BidAllocationPage } from './BidAllocation';
import { ManagementBID } from './ManagementBid';
import { QuotationFilesPages } from './QuotationFiles';
import * as S from './styles';
import { useAwardSummary } from './useAwardSummary';

export function AwardsPage() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.awardSummary.title'));
  const { setTabs, tabs, bidData, bidId } = useAwardSummary();
  const { headerCsv, params, awardListData, transformData } = useAwardList();
  const history = useHistory();

  return (
    <S.Container>
      <Row>
        <S.BackButton
          type="link"
          icon={<ArrowLeftOutlined />}
          onClick={() => history.push(`/view-rfq?bidId=${bidId}`)}
        >
          {t('pages.awardSummary.buttons.back')}
        </S.BackButton>
      </Row>
      <StyledHeader
        title={t('pages.awardSummary.title')}
        subtitle={bidData?.name}
        actions={
          tabs[2].selected ? (
            <StyledButton variant="primary" key={2} icon={<BiDownload />}>
              <CsvExport
                filename={[`Award List ${bidData?.name} `, new Date().toDateString()]}
                limit={params.totalElements}
                headers={headerCsv}
                transformData={transformData}
                content={awardListData as any}
              />
            </StyledButton>
          ) : null
        }
      />
      <StyledTabs tabs={tabs} setSelectedTab={setTabs} />
      {tabs[0].selected && <AwardSimulation hasChild={bidData?.hasChild || false} />}
      {tabs[1].selected && <BidAllocationPage />}
      {tabs[2].selected && <AwardListPage />}
      {tabs[3].selected && <ManagementBID />}
      {tabs[4].selected && <QuotationFilesPages />}
    </S.Container>
  );
}
