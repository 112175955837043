import { Steps } from 'antd';
import * as S from './styles';
import { useRequestRegistration } from './useRequesRegistration';

export default function RequestRegistration() {
  const { currentStep, steps } = useRequestRegistration();

  return (
    <S.Container>
      <S.HeaderContainer>
        <S.Title>{steps[currentStep].title}</S.Title>
      </S.HeaderContainer>
      <S.Content>
        <S.StepBox>
          <Steps
            direction="vertical"
            current={currentStep}
            items={steps}
            onChange={() => {
              return false;
            }}
          />
        </S.StepBox>
        <S.ContentSteps>
          <S.StepTitle>{steps[currentStep].title}</S.StepTitle>
          {steps[currentStep].content}
        </S.ContentSteps>
      </S.Content>
    </S.Container>
  );
}
