import { Flex, Form } from 'antd';
import { t } from 'i18next';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { RegisterNewComponentProps } from './types';
import StyledButton from '../../../../components/Common/StyledButton';
import StyledInput from '../../../../components/Common/StyledInput';
import * as S from './styles';
import { useRegisterNewComponent } from './useRegisterNewComponent';
import { StyledLoading } from '../../../../components/Common/StyledLoading';

export function RegisterNewComponent({
  item,
  deleteItem,
  editItem,
  saveItem,
  openModalAddNewItem,
}: RegisterNewComponentProps) {
  const {
    handleGetComponentByPartNumber,
    setVisibleNewComponent,
    form,
    visibleNewComponent,
    isLoadingForm,
  } = useRegisterNewComponent();

  useEffect(() => {
    if (item) {
      form.setFieldsValue(item);
    }
  }, [item, form]);
  return (
    <Form form={form} initialValues={item}>
      <Flex vertical>
        <S.ContainerHeader>
          <>
            <Flex justify="center" align="center" gap={16}>
              {item.isEditing ? (
                <S.ArrowUpStyled
                  onClick={() => {
                    item.specification && editItem(item);
                  }}
                />
              ) : (
                <S.ArrowDownStyled
                  onClick={() => {
                    editItem(item);
                  }}
                />
              )}
              <Flex justify="center" align="center" gap={16}>
                <S.LabelBlue>{String(item.index).padStart(2, '0')}</S.LabelBlue>
              </Flex>
              {!item.isEditing && (
                <>
                  <S.LabelPartNumber>{item.partNumber || ''}</S.LabelPartNumber>
                  <S.LabelPartNumber className="clear">|</S.LabelPartNumber>
                  <S.LabelPartNumber className="clear">{item.supplier || ''}</S.LabelPartNumber>
                </>
              )}
            </Flex>
            {item.isEditing ? (
              <StyledButton
                variant="slim"
                onClick={() => {
                  deleteItem(item);
                }}
              >
                <DeleteOutlined />
                {t('common.delete')}
              </StyledButton>
            ) : (
              <StyledButton
                variant="slim"
                onClick={() => {
                  editItem(item);
                }}
              >
                <EditOutlined />
                {t('common.edit')}
              </StyledButton>
            )}
          </>
        </S.ContainerHeader>
        {item.isEditing && (
          <S.ContentContainerEditing>
            {isLoadingForm ? (
              <StyledLoading height={5} />
            ) : (
              <>
                <S.FlexHalfContent>
                  <Form.Item
                    style={{ width: '18rem' }}
                    noStyle
                    name="partNumber"
                    rules={[
                      {
                        required: true,
                        message: `${t(
                          'pages.requesRegistration.components.stepOne.labels.partNumber'
                        )} ${t(`common.validate.required`)}`,
                      },
                    ]}
                  >
                    <StyledInput
                      suffix={<S.StyledIconSearch />}
                      placeholder={t(
                        'pages.requesRegistration.components.stepTwo.placeholders.addNumber'
                      )}
                      label={t('pages.requesRegistration.components.stepOne.labels.partNumber')}
                      onKeyDown={({ key }) => {
                        key.toUpperCase() === 'ENTER' &&
                          handleGetComponentByPartNumber(form.getFieldValue('partNumber'));
                      }}
                    />
                  </Form.Item>
                  <S.LabelNewComponent
                    visible={visibleNewComponent}
                    onClick={() => {
                      openModalAddNewItem(true);
                      setVisibleNewComponent(false);
                    }}
                  >
                    {t('pages.requesRegistration.components.stepOne.labels.addNewComponent')}
                  </S.LabelNewComponent>
                </S.FlexHalfContent>
                <Flex gap={16}>
                  <Form.Item
                    noStyle
                    name="specification"
                    rules={[
                      {
                        required: true,
                        message: `${t(
                          'pages.requesRegistration.components.stepOne.labels.specification'
                        )} ${t(`common.validate.required`)}`,
                      },
                    ]}
                  >
                    <StyledInput
                      disabled
                      suffix={<S.StyledIconSearch />}
                      label={t('pages.requesRegistration.components.stepOne.labels.specification')}
                      placeholder={t(
                        'pages.requesRegistration.components.stepTwo.placeholders.taxId'
                      )}
                    />
                  </Form.Item>
                  <Form.Item
                    noStyle
                    name="supplier"
                    rules={[
                      {
                        required: true,
                        message: `${t(
                          'pages.requesRegistration.components.stepOne.labels.supplier'
                        )} ${t(`common.validate.required`)}`,
                      },
                    ]}
                  >
                    <StyledInput
                      disabled
                      placeholder={t('pages.requesRegistration.components.stepOne.labels.select')}
                      suffix={<S.StyledIconSearch />}
                      label={t('pages.requesRegistration.components.stepOne.labels.supplier')}
                    />
                  </Form.Item>
                </Flex>
                <Form.Item
                  noStyle
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: `${t(
                        'pages.requesRegistration.components.stepOne.labels.description'
                      )} ${t(`common.validate.required`)}`,
                    },
                  ]}
                >
                  <StyledInput
                    disabled
                    placeholder={t(
                      'pages.requesRegistration.components.stepOne.placeHolders.description'
                    )}
                    label={t('pages.requesRegistration.components.stepOne.labels.description')}
                  />
                </Form.Item>
              </>
            )}
          </S.ContentContainerEditing>
        )}
        {!item.isEditing && (
          <S.ContentContainerNoEditing>
            <S.FlexFortyPercent vertical>
              <S.LabelContent>
                {t('pages.requesRegistration.components.stepOne.labels.specification')}
              </S.LabelContent>
              <S.LabelContent className="strong">{item.specification}</S.LabelContent>
            </S.FlexFortyPercent>
            <S.FlexSixtyPercent vertical>
              <S.LabelContent>
                {t('pages.requesRegistration.components.stepOne.labels.description')}
              </S.LabelContent>
              <S.LabelContent className="strong">{item.description}</S.LabelContent>
            </S.FlexSixtyPercent>
          </S.ContentContainerNoEditing>
        )}

        {item.isEditing && (
          <S.ContainerFooter>
            <StyledButton
              onClick={() => {
                form.validateFields().then((values) => {
                  saveItem({
                    description: values.description,
                    isEditing: false,
                    partNumber: values.partNumber,
                    specification: values.specification,
                    supplier: values.supplier,
                    index: item.index,
                  });
                });
              }}
              variant="primary"
            >
              {t('common.save')}
            </StyledButton>
          </S.ContainerFooter>
        )}
      </Flex>
    </Form>
  );
}
