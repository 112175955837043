import styled from 'styled-components';
import theme from '../../../../styles/theme';

interface LegendIndicatorColor {
  color: string;
}

export const TooltipContainer = styled.div`
  max-height: 20rem;
  overflow-y: auto;
  min-width: 11.52rem;
  border: 0.063rem solid ${theme.colorsDesignSystem.border};
  border-radius: 0.313rem;
  background-color: ${theme.colorsDesignSystem.backgroundTooltipChart};
  box-shadow: 0 0.25rem 0.625rem ${theme.colorsDesignSystem.boxShadowCard};
  :hover {
    background-color: none;
  }
`;
export const TooltipBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 1rem 1rem 1rem;
`;

export const ProjectTooltip = styled.div`
  padding: 0;
  margin-left: 0.3rem;
  color: ${theme.colorsDesignSystem.text};
  font-size: 0.75rem;
  font-weight: 700;
`;

export const SpecTooltip = styled.div`
  border-top: 0.031rem solid ${theme.colorsDesignSystem.grayBorderTooltip};
  padding-top: 0.5rem;
  color: ${theme.colorsDesignSystem.blue};
  font-size: 0.625rem;
  font-weight: 700;
  margin: 0.5rem 0 0.25rem 0.25rem;
`;

export const PartNumberTooltipContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const PartNumberTooltip = styled.p`
  color: ${theme.colorsDesignSystem.grayAcer};
  font-size: 0.625rem;
  font-weight: 400;
  margin: 0 0 0 0.25rem;
`;
export const PartNumberValue = styled.p`
  color: ${theme.colorsDesignSystem.grayAcer};
  font-size: 0.75rem;
  font-weight: 700;
  margin: 0;
`;

export const SupllierTooltip = styled.p`
  color: ${theme.colorsDesignSystem.grayAcer};
  font-size: 0.625rem;
  font-weight: 700;
  margin: 0 0 0.75rem 0.25rem;
  &:last-child {
    margin: 0 0 0 0.25rem;
  }
`;

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
  justify-content: end;
  margin: 2.813rem 1.875rem 0 0;
`;

export const IndicatorLegend = styled.div<LegendIndicatorColor>`
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

export const TextLegend = styled.span`
  color: ${theme.colorsDesignSystem.grayAcer};
`;
