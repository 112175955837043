import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { BiDownload, BiPlus } from 'react-icons/bi';
import StyledModal from '../../components/Common/StyledModal';
import { NewComponent } from '../../components/Modal/NewComponent';

import * as S from './styles';
import { useComponents } from './useComponents';

import { ActionButton } from '../../components/ActionButton';
import { CsvExport } from '../../components/Common/CsvExport';
import StyledButton from '../../components/Common/StyledButton';
import { StyledHeader } from '../../components/Common/StyledHeaderPage';
import StyledMultipleSelect from '../../components/Common/StyledMultipleSelect';
import { StyledPagination } from '../../components/Common/StyledPagination';
import StyledSearchInput from '../../components/Common/StyledSearchInput';
import StyledSelect from '../../components/Common/StyledSelect';
import { UpdateDate } from '../../components/Common/UpdateDate';
import { EditableCell } from '../../components/EditableCell';
import { Loading } from '../../components/Loading';
import { ResultNoScenarios } from '../../components/ResultNoScenarios';
import SimpleTable from '../../components/Tables/SimpleTable';
import { ValidateMessages } from '../../helpers/ValidateMessages';
import useDocumentTitle from '../../hooks/useDocumentTitle';

export function ComponentsPage({ view = null, onClose = null, dataModal = null }) {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.components.title'));

  const validateMessages = ValidateMessages();
  const {
    data,
    params,
    specification,
    setSpecification,
    pageControl,
    isLoading,
    form,
    isNewItem,
    setIsNewItem,
    mergedColumns,
    exportColumns,
    expandedRowKeys,
    categoryOptions,
    partnumberOptions,
    descriptionOptions,
    cmOptions,
    productOptions,
    sourceOptions,
    CMs,
    fetchComponents,
    changePage,
    handleChangeTable,
    handleAddItem,
    transformData,
    getQueryParams,
    setExpandedRowKeys,
    HandleRenderFiltersTags,
    handleRenderClearAllFilters,
    handleUpdateFilters,
    rangeDays,
    isModalOpen,
    setIsModalOpen,
    save,
    editingKey,
    cancel,
  } = useComponents();

  const modifiedDate = (dataModal && dataModal.modified) || data[0]?.lastUpdateDateFile;

  const showTagsFilter =
    params.partType?.length ||
    params.spec?.length ||
    params.partNumber?.length ||
    params.description?.length ||
    params.local?.length;

  const expandRowIds = (expanded, record) => {
    const keys = [];

    if (record.partNumber) return; // if is last level, do nothing

    if (record.partType && expanded) {
      keys.push(record.id);
    }

    if (record.spec) {
      const splitId = record.id.split('$');
      const partTypeId = `${splitId[0]}$${splitId[1]}`;

      if (expanded) {
        keys.push(partTypeId, record.id);
      } else {
        keys.push(partTypeId);
      }
    }

    setExpandedRowKeys(keys);
  };

  const actions = [
    {
      key: '1',
      label: (
        <ActionButton
          buttonText={`${t('common.add')} ${t('common.item')}`}
          icon={<BiPlus />}
          key={1}
          onClick={handleAddItem}
          disabled={isNewItem}
        />
      ),
    },
    {
      key: '2',
      label: (
        <ActionButton key={2} className="btn-csv" icon={<BiDownload />}>
          <CsvExport
            url={`components/tree?${getQueryParams()}`}
            filename={['components']}
            limit={pageControl.totalElements}
            headers={exportColumns}
            transformData={transformData}
          />
        </ActionButton>
      ),
    },
  ];

  return (
    <main>
      <Row>
        {isLoading.content ? (
          <Loading />
        ) : (
          <>
            <Col span={24} className="filter-page">
              <Row justify="center">
                <Col span={24}>
                  <StyledHeader
                    title={t('pages.components.title')}
                    items={pageControl.totalElements > 0 && pageControl.totalElements}
                    actions={
                      <Dropdown key={0} menu={{ items: actions }} placement="bottomRight">
                        <StyledButton variant="primary">
                          {t('common.action')}
                          <DownOutlined />
                        </StyledButton>
                      </Dropdown>
                    }
                  />
                </Col>
              </Row>

              <Row justify="center">
                <Col span={24}>
                  <S.ContainerFilters>
                    <StyledSelect
                      options={CMs}
                      label={t('pages.components.labels.cm')}
                      placeholder={t('pages.components.placeHolders.cm')}
                      onChange={(value) => handleUpdateFilters('cm', value)}
                      styles={{ width: '6.5rem' }}
                      value={params.cm || 'Show All'}
                    />
                    <StyledSelect
                      styles={{ width: '8rem' }}
                      options={productOptions}
                      label={t('pages.components.labels.product')}
                      placeholder={t('pages.components.placeHolders.product')}
                      onChange={(value) => handleUpdateFilters('product', value)}
                      value={params.product || null}
                    />
                    <StyledSelect
                      styles={{ width: '8rem' }}
                      options={categoryOptions}
                      label={t('pages.components.labels.category')}
                      placeholder={t('pages.components.placeHolders.category')}
                      onChange={(value) => handleUpdateFilters('partType', value)}
                      value={params.partType || null}
                      loading={isLoading.category}
                    />
                    <StyledSearchInput
                      label={t('pages.components.labels.specification')}
                      placeholder={t('pages.components.placeHolders.specification')}
                      value={specification}
                      onChange={(event) => {
                        setSpecification(event.target.value);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          handleUpdateFilters('spec', specification);
                          setSpecification('');
                        }
                      }}
                      suffix={
                        <S.SearchButton
                          onClick={() => {
                            handleUpdateFilters('spec', specification);
                            setSpecification('');
                          }}
                        >
                          <SearchOutlined className="icon" />
                        </S.SearchButton>
                      }
                    />
                    <StyledSelect
                      styles={{ width: '8rem' }}
                      options={partnumberOptions}
                      label={t('pages.components.labels.partNumber')}
                      placeholder={t('pages.components.placeHolders.partNumber')}
                      suffixIcon={
                        <S.SearchButton>
                          <SearchOutlined className="icon" />
                        </S.SearchButton>
                      }
                      showSearch
                      onChange={(value) => handleUpdateFilters('partNumber', value)}
                      value={params.partNumber || null}
                      loading={isLoading.partNumber}
                    />
                    <StyledSelect
                      styles={{ width: '10.5rem' }}
                      options={descriptionOptions}
                      label={t('pages.components.labels.description')}
                      placeholder={t('pages.components.placeHolders.description')}
                      suffixIcon={
                        <S.SearchButton>
                          <SearchOutlined className="icon" />
                        </S.SearchButton>
                      }
                      showSearch
                      onChange={(value) => handleUpdateFilters('description', value)}
                      value={params.description || null}
                      loading={isLoading.description}
                    />
                    <StyledSelect
                      styles={{ width: '8rem' }}
                      options={sourceOptions}
                      label={t('pages.components.labels.source')}
                      placeholder={t('pages.components.placeHolders.source')}
                      onChange={(value) => handleUpdateFilters('local', value)}
                      value={params.local || null}
                    />
                    <StyledMultipleSelect
                      options={rangeDays}
                      label={t('pages.components.labels.leadTime')}
                      placeholder={t('pages.components.placeHolders.leadTime')}
                      styles={{ width: '8rem' }}
                      onChange={(value) => handleUpdateFilters('leadTime', value)}
                      allowClear
                      value={params.leadTime || null}
                    />
                    <StyledButton
                      small
                      onClick={() => fetchComponents()}
                      variant="primary"
                      text={t('pages.componentsPrices.titleButtons.apply')}
                    />
                  </S.ContainerFilters>
                  <S.FiltersTagContainer>
                    {HandleRenderFiltersTags()}
                    {handleRenderClearAllFilters()}
                  </S.FiltersTagContainer>
                </Col>
              </Row>

              <Row justify="center">
                <Col span={24}>
                  <UpdateDate date={modifiedDate} />
                  <Form form={form} component={false} validateMessages={validateMessages}>
                    <SimpleTable
                      rowKey="id"
                      data-cy="table"
                      striped
                      dataSource={data}
                      columns={mergedColumns}
                      onChange={handleChangeTable}
                      components={{
                        body: {
                          cell: EditableCell,
                        },
                      }}
                      scroll={{ x: 'max-content', y: 'calc(100vh  - 22rem)' }}
                      expandRowByClick
                      defaultExpandedRowKeys={showTagsFilter ? [data[0]?.id] : null}
                      expandable={{ expandedRowKeys, indentSize: 0 }}
                      onExpand={expandRowIds}
                      pagination={false}
                      locale={{
                        emptyText: (
                          <ResultNoScenarios
                            title={t('common.results.noDataTitle')}
                            subTitle={t('common.results.noDataSubtitle')}
                          />
                        ),
                      }}
                    />
                  </Form>

                  {pageControl.totalPages > 1 && (
                    <StyledPagination
                      onChange={changePage}
                      pageNumber={params.page + 1}
                      size={params.limit}
                      totalElements={pageControl.totalElements}
                    />
                  )}
                </Col>
              </Row>
            </Col>
            <StyledModal
              key="modal-newcomponent"
              title={t('pages.avlc.fields.modalName')}
              open={isModalOpen}
              onClose={() => {
                setIsModalOpen(false);
                setIsNewItem(false);
              }}
              footer={
                <Row justify="space-between">
                  <Button
                    type="link"
                    onClick={() => {
                      setIsModalOpen(false);
                      cancel();
                    }}
                  >
                    {t('common.cancel')}
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      form.validateFields().then(() => {
                        save();
                      });
                    }}
                  >
                    {t('common.save')}
                  </Button>
                </Row>
              }
              body={
                <NewComponent
                  form={form}
                  cmOptions={cmOptions}
                  productOptions={productOptions}
                  categoryOptions={categoryOptions}
                  disabledFields={editingKey && ['cm', 'specification', 'leadTime']}
                />
              }
              width="64rem"
            />
          </>
        )}
      </Row>
    </main>
  );
}
