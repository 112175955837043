import { Form, UploadFile, UploadProps } from 'antd';
import { UploadFileStatus } from 'antd/es/upload/interface';
import { useState } from 'react';
import { UploadedDatasheet } from '../../types';
import { TrackingNumberData } from '../ModalNewTrackingNumber/types';

export function useSubmitUpdateInfo() {
  const [visibleModal, setVisibleModal] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);
  const [isUploadedFilesModalOpen, setIsUploadedFilesModalOpen] = useState<boolean>(false);
  const [isTrackingNumberModalOpen, setIsTrackingNumberModalOpen] = useState<boolean>(false);
  const [trackingSelected, setTrackingSelected] = useState<string>('');
  const [isLoadingFiles, setIsLoadingFiles] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [tempUploadedFileList, setTempUploadedFileList] = useState<UploadedDatasheet[]>([]);

  const [form] = Form.useForm<TrackingNumberData>();

  const validateFormat = (fileType: string | undefined) => {
    if (!fileType) return;

    const validTypes = [
      'text/csv',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.oasis.opendocument.spreadsheet',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'text/plain',
      'application/pdf',
    ];

    return validTypes.includes(fileType);
  };

  const validateSize = (size: number) => {
    if (size / 1024 / 1024 > 10) {
      return false;
    }
    return true;
  };

  const validateRestrictions = (file: UploadFile) => {
    const isValidFormat = validateFormat(file.type);
    const validSize = validateSize(file.size || 0);

    if (isValidFormat && validSize) {
      return 'uploading';
    }
    if (!isValidFormat && !validSize) {
      return 'formatErrorSizeError';
    }
    if (!isValidFormat) {
      return 'formatError';
    }
    if (!validSize) {
      return 'sizeError';
    }
  };

  const handleUploadFile: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    const listWithResponse = newFileList.map((item) => {
      return {
        ...item,
        response: validateRestrictions(item),
      };
    });
    setFileList(listWithResponse);
  };

  const removeFile = (fileId: string, status: UploadFileStatus) => {
    const filteredFiles = fileList?.filter((file) => file.uid !== fileId);
    setFileList(filteredFiles);
  };

  const clearFiles = () => {
    setFileList([]);
  };

  const addProcessingError = () => {
    const copyFiles = fileList;
    copyFiles[fileList.length].response === 'processingError';

    setFileList(copyFiles);
  };

  const updateFilesState = (data: UploadedDatasheet[]) => {
    setFileList([]);
    setTempUploadedFileList(data);
  };

  return {
    visibleModal,
    setVisibleModal,
    form,
    isUploadModalOpen,
    setIsUploadModalOpen,
    updateFilesState,
    clearFiles,
    removeFile,
    handleUploadFile,
    isLoadingFiles,
    setIsLoadingFiles,
    fileList,
    setIsUploadedFilesModalOpen,
    isUploadedFilesModalOpen,
    addProcessingError,
    setIsTrackingNumberModalOpen,
    isTrackingNumberModalOpen,
    tempUploadedFileList,
    trackingSelected,
    setTrackingSelected,
  };
}
