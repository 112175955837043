import { t } from 'i18next';
import { Flex } from 'antd';
import { useEffect } from 'react';
import StyledButton from '../../../components/Common/StyledButton';
import * as S from './styles';
import { useStepTwoAssociatedOdms } from './useStepTwoAssociatedOdms';
import { StyledLoading } from '../../../components/Common/StyledLoading';
import { StepTwoProps } from './types';

export function StepTwoAssociatedOdms({
  registerComponentId,
  associetedOdmId,
  setStep,
  setAssocietedOdmId,
}: StepTwoProps) {
  const {
    isLoading,
    handleRenderAssociatedComponents,
    handleAddNewComponent,
    handleDisableButtonAddNewOdm,
    handleSendOdms,
    handleVerifyIfCanSendOdms,
    handleLoadPreviousInfo,
    handleUpdateOdms,
    handleCancel,
  } = useStepTwoAssociatedOdms();

  useEffect(() => {
    associetedOdmId && handleLoadPreviousInfo();
  }, []);

  return (
    <div>
      {isLoading ? (
        <StyledLoading height={30} />
      ) : (
        <>
          <S.ContentForm>{handleRenderAssociatedComponents()}</S.ContentForm>
          <S.ContainerButtonAddNewOdm>
            <StyledButton
              onClick={handleAddNewComponent}
              variant="dashed"
              disabled={handleDisableButtonAddNewOdm()}
            >
              + {t('pages.newRfq.steps.registerComponents.addComponent')}
            </StyledButton>
          </S.ContainerButtonAddNewOdm>
          <S.StepFooter>
            <StyledButton variant="slim" onClick={handleCancel}>
              {t('common.cancel')}
            </StyledButton>
            <Flex gap={18}>
              <StyledButton
                variant="secondary"
                onClick={() => {
                  setStep(0);
                }}
                htmlType="submit"
                disabled={false}
              >
                {t('common.previous')}
              </StyledButton>
              <StyledButton
                variant="primary"
                onClick={() => {
                  if (associetedOdmId) {
                    handleUpdateOdms(
                      registerComponentId,
                      associetedOdmId,
                      setStep,
                      setAssocietedOdmId
                    );
                  } else {
                    handleSendOdms(registerComponentId, setStep, setAssocietedOdmId);
                  }
                }}
                htmlType="submit"
                disabled={handleVerifyIfCanSendOdms()}
              >
                {t('common.next')}
              </StyledButton>
            </Flex>
          </S.StepFooter>
        </>
      )}
    </div>
  );
}
