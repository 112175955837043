import { useState } from 'react';
import { t } from 'i18next';
import { useHistory } from 'react-router-dom';
import { CardSupplierSendEmail } from './CardSupplierSendEmail';
import { CardSupplier, PayloadAfterSaveContacts } from './CardSupplierSendEmail/types';
import { api } from '../../../services/api';
import { services } from '../../../services/services';
import { oneAlert } from '../../../helpers/nUtils';
import { ContactUsers } from '../../../services/types';
import { RoutesPath } from '../../../routes/routesPath';

export function useStepThreeSendEmail() {
  const [registerComponentId, setRegisterComponentId] = useState('');
  const [receivedAssocietedOdmId, setReceivedAssocietedOdmId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [visiblePopConfirm, setVisiblePopConfirm] = useState(false);
  const [cardSuppliers, setCardSuppliers] = useState<Array<CardSupplier>>([]);
  const [visibleModalSendMail, setVisibleModalSendMail] = useState(false);
  const history = useHistory();

  const handleSetCardEditing = (item: CardSupplier) => {
    setCardSuppliers((prevState) => {
      return prevState.map((card) => {
        return card.index === item.index ? { ...card, isOpen: !item.isOpen } : card;
      });
    });
  };

  const handleAddContact = (item: CardSupplier, contact: ContactUsers) => {
    setCardSuppliers((prevState) => {
      return prevState.map((oldItem) => {
        return {
          ...oldItem,
          contacts:
            item.index === oldItem.index ? [...oldItem.contacts, contact] : oldItem.contacts,
        };
      });
    });
  };

  const handleRemoveContact = (item: CardSupplier, contact: ContactUsers) => {
    setCardSuppliers((prevState) => {
      return prevState.map((oldItem) => {
        return {
          ...oldItem,
          contacts:
            item.index === oldItem.index
              ? oldItem.contacts.filter((oldContact) => oldContact.id !== contact.id)
              : oldItem.contacts,
        };
      });
    });
  };

  const handleUpdateCardAfterSave = (card: CardSupplier) => {
    setCardSuppliers((prevState) => {
      return prevState.map((oldCard) => {
        return oldCard.partNumber === card.partNumber
          ? {
              ...oldCard,
              id: card.id,
              isOpen: false,
              status: card.status,
              contacts: card.contacts || [],
              message: card.message,
            }
          : oldCard;
      });
    });
  };

  const handleGetSuppliersData = async () => {
    try {
      setIsLoading(true);
      const { data, status } = await api.get<Array<CardSupplier>>(
        `${services.qualification}/sendsupplier/${receivedAssocietedOdmId}/filter`
      );
      if (status === 200) {
        setCardSuppliers(
          data.map((card, index) => {
            return {
              ...card,
              index: index + 1,
              contacts: card.contacts || [],
            };
          })
        );
      }
    } catch (error) {
      oneAlert({
        type: 'error',
        message: t('toast.errorOnList'),
      });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetMessagesAfterSend = async () => {
    try {
      setIsLoading(true);
      const { data, status } = await api.get<Array<CardSupplier>>(
        `${services.qualification}/sendsupplier/${registerComponentId}`
      );
      if (status === 200) {
        setCardSuppliers(
          data.map((card) => {
            return {
              ...card,
              contacts: card.contacts || [],
            };
          })
        );
      }
    } catch (error) {
      oneAlert({
        type: 'error',
        message: t('toast.errorOnList'),
      });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearStorage = () => {
    localStorage.removeItem('registerComponent');
    localStorage.removeItem('associetedOdm');
  };

  const handleUpdateData = async (item: CardSupplier) => {
    try {
      setIsLoading(true);
      const { status, data } = await api.put<PayloadAfterSaveContacts>(
        `${services.qualification}/sendsupplier/${item.id}/message`,
        {
          associetedOdmId: receivedAssocietedOdmId,
          description: item.description,
          emailMessage: item.message,
          invitedContactQualification: item.contacts.map((contact) => {
            return {
              contactName: contact.name,
              email: contact.email,
            };
          }),
          partNumber: item.partNumber,
          registerComponentId,
          specification: item.specification,
          supplier: item.supplier,
        }
      );
      if (status === 200 || status === 201) {
        handleUpdateCardAfterSave({
          id: data.id,
          description: data.description,
          message: data.emailMessage,
          contacts: data.invitedContactQualification.map((contact) => {
            return {
              id: contact.id,
              name: contact.contactName,
              email: contact.email,
            };
          }),
          partNumber: data.partNumber,
          specification: data.specification,
          supplier: data.supplier,
          isOpen: false,
          status: data.status,
        });
      }
    } catch (error) {
      console.log(error);
      oneAlert({ type: 'error', message: t('toast.errorOnSave') });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendData = async (item: CardSupplier) => {
    try {
      setIsLoading(true);
      const { status, data } = await api.post<PayloadAfterSaveContacts>(
        `${services.qualification}/sendsupplier/message`,
        {
          receivedAssocietedOdmId,
          registerComponentId,
          emailMessage: item.message,
          partNumber: item.partNumber,
          supplier: item.supplier,
          specification: item.specification,
          description: item.description,
          invitedContactQualification: item.contacts.map((contact) => {
            return {
              contactName: contact.name,
              email: contact.email,
            };
          }),
        }
      );
      if (status === 201) {
        handleUpdateCardAfterSave({
          id: data.id,
          description: data.description,
          message: data.emailMessage,
          contacts: data.invitedContactQualification.map((contact) => {
            return {
              id: contact.id,
              name: contact.contactName,
              email: contact.email,
            };
          }),
          partNumber: data.partNumber,
          specification: data.specification,
          supplier: data.supplier,
          isOpen: false,
          status: data.status,
        });
      }
    } catch (error) {
      console.log(error);
      oneAlert({ type: 'error', message: t('toast.errorOnSave') });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveOrUpdateData = (item: CardSupplier) => {
    item.id ? handleUpdateData(item) : handleSendData(item);
  };

  const handleRenderCards = () => {
    return cardSuppliers.map((card, index) => {
      return (
        <CardSupplierSendEmail
          key={card.partNumber}
          item={{ ...card, index: index + 1 }}
          onEdit={handleSetCardEditing}
          addContact={handleAddContact}
          removeContact={handleRemoveContact}
          sendContacts={handleSaveOrUpdateData}
        />
      );
    });
  };

  const handleVerifyIfAllCardsIsSended = (): boolean => {
    return cardSuppliers.some((card) => card.status !== 'WAIT_SEND_EMAIL' || !card.id);
  };

  const handleTriggerEmail = async () => {
    try {
      setIsLoading(true);
      const { status } = await api.post(
        `${services.qualification}/sendsupplier/sendEmail`,
        cardSuppliers.map((card) => card.id)
      );
      if (status === 200 || status === 201) {
        setVisibleModalSendMail(true);
        //limpando os códigos dos steps 1 e 2 após finalizar o fluxo
        handleClearStorage();
      }
    } catch (error) {
      console.log(error);
      oneAlert({ type: 'error', message: t('toast.errorOnSave') });
    } finally {
      setIsLoading(false);
    }
  };

  const handleNavigateQualification = () => {
    history.push(RoutesPath.qualification);
  };

  return {
    handleRenderCards,
    handleGetSuppliersData,
    handleSetCardEditing,
    setRegisterComponentId,
    setVisiblePopConfirm,
    setReceivedAssocietedOdmId,
    handleVerifyIfAllCardsIsSended,
    handleTriggerEmail,
    handleNavigateQualification,
    visibleModalSendMail,
    receivedAssocietedOdmId,
    registerComponentId,
    visiblePopConfirm,
    isLoading,
  };
}
