import { Form } from 'antd';
import { HttpStatusCode } from 'axios';
import { t } from 'i18next';
import { useState } from 'react';
import { StyledSelectOptions } from '../../../../components/Common/StyledSelect/types';
import { oneAlert } from '../../../../helpers/nUtils';
import { api } from '../../../../services/api';
import { services } from '../../../../services/services';
import { ContactUsers } from '../../../../services/types';
import { CardSupplier } from './types';

export function useSupplierSendEmail() {
  const [form] = Form.useForm();
  const [loadingCard, setLoadingCard] = useState(false);
  const [loadingContact, setLoadingContact] = useState(false);
  const [contactUsers, setContactUsers] = useState<Array<ContactUsers>>([]);
  const [contactSelectedToInvite, setContactSelectedToInvite] = useState<ContactUsers | null>();

  const fetchContactList = async (supplier: string) => {
    setLoadingCard(true);
    try {
      setLoadingCard(true);
      const { data, status }: { data: Array<ContactUsers>; status: HttpStatusCode } = await api.get(
        `${import.meta.env.VITE_API_LOGIN_UNICO}${
          services.singleLogin
        }/user-consult?empresa=${supplier}`
      );
      if (status === 200) {
        return setContactUsers(data);
      }
      setContactUsers([]);
    } catch (err) {
      oneAlert({
        type: 'error',
        message: t('toast.errorOnList'),
      });
    } finally {
      setLoadingCard(false);
    }
  };

  const handleSaveContactUserById = (id: string, item: CardSupplier) => {
    try {
      const findContact = contactUsers.find((contact) => contact.id === id);
      const contactAddBefore = item.contacts.some((contact) => contact.id === id);
      if (contactAddBefore) {
        oneAlert({
          type: 'info',
          message: t('pages.newRfq.steps.send.messageContactInvited'),
        });
      } else {
        setContactSelectedToInvite(findContact);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleConvertContactListToStyledSelectOptions = (): Array<StyledSelectOptions> => {
    try {
      return contactUsers.map((contact) => {
        return {
          label: contact.name,
          value: contact.id,
        };
      });
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  return {
    fetchContactList,
    handleConvertContactListToStyledSelectOptions,
    setContactSelectedToInvite,
    handleSaveContactUserById,
    contactSelectedToInvite,
    loadingCard,
    loadingContact,
    form,
  };
}
