import { Alert, Col, Flex } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { TagStatusIndication } from '../../../../components/Qualification/TagStatusIndication';
import { QualificationTagStatus } from '../../../../helpers/enums';
import { formatNumberToOrdinal } from '../../../../helpers/FormatValues';
import { IQualificationRegisterInformation } from '../../types';
import * as S from './styles';

export function QualificationRegisterInformations(data?: IQualificationRegisterInformation) {
  const { t } = useTranslation();
  return (
    <S.Container>
      <S.Header>
        <S.Padding>{t('pages.viewQualification.qualificationRegisterInformation.title')}</S.Padding>
      </S.Header>
      <S.Content>
        <S.AlertContainer>
          {data?.status === QualificationTagStatus['Sent Supplier'] ? (
            <Alert
              message={t(
                'pages.viewQualification.qualificationRegisterInformation.waitingSupplier'
              )}
              type="info"
              showIcon
            />
          ) : data?.reasonCancelation ? (
            <Alert
              message={
                <S.MessageCancel>
                  <span>
                    {t(
                      'pages.viewQualification.qualificationRegisterInformation.reasonCancellation'
                    )}
                  </span>
                  <span className="message">{data.reasonCancelation}</span>
                </S.MessageCancel>
              }
              type="error"
              showIcon
            />
          ) : null}
        </S.AlertContainer>

        <Flex gap={16} style={{ width: '100%' }}>
          <S.Card style={{ width: '19rem' }}>
            <span>{t('pages.viewQualification.qualificationRegisterInformation.processType')}</span>
            <span className="value">{data?.processType || '-'}</span>
          </S.Card>
          <S.Card style={{ width: '19rem' }}>
            <span>{t('pages.viewQualification.qualificationRegisterInformation.supplier')}</span>
            <span className="value">{data?.supplier || '-'}</span>
          </S.Card>
          <S.Card style={{ width: '19rem' }}>
            <span>
              {t(
                'pages.viewQualification.qualificationRegisterInformation.submissionRankingPosition'
              )}
            </span>
            <span className="value">
              {data?.submissionRankingPosition
                ? formatNumberToOrdinal(data?.submissionRankingPosition)
                : '-'}
            </span>
          </S.Card>
          <S.Card style={{ width: '19rem' }}>
            <span>
              {t('pages.viewQualification.qualificationRegisterInformation.sampleQtyTotal')}
            </span>
            <span className="value">{data?.sampleQtyTotal || '-'}</span>
          </S.Card>
        </Flex>
        <Col span={24}>
          <S.InformationContent gutter={16}>
            <Col span={6}>
              <Flex vertical gap={12}>
                <S.Label>
                  {t('pages.viewQualification.qualificationRegisterInformation.createdBy')}
                </S.Label>
                <S.Value isBlue>{data?.createBy}</S.Value>
              </Flex>
            </Col>
            <Col span={6}>
              <Flex vertical gap={12}>
                <S.Label>
                  {t('pages.viewQualification.qualificationRegisterInformation.createdOn')}
                </S.Label>
                <S.Value>{dayjs(data?.createdOn).format('MM/DD/YYYY HH:mm')}</S.Value>
              </Flex>
            </Col>
            <Col span={6}>
              <Flex vertical gap={12}>
                <S.Label>
                  {t('pages.viewQualification.qualificationRegisterInformation.idQualification')}
                </S.Label>
                <S.Value>{data?.idQualification || '-'}</S.Value>
              </Flex>
            </Col>
            <Col span={6}>
              <Flex vertical gap={12}>
                <S.Label>
                  {t('pages.viewQualification.qualificationRegisterInformation.status')}
                </S.Label>
                {data?.status ? <TagStatusIndication status={data.status} /> : '-'}
              </Flex>
            </Col>
          </S.InformationContent>

          <S.InformationContent gutter={16}>
            <Col span={6}>
              <Flex vertical gap={12}>
                <S.Label>
                  {t('pages.viewQualification.qualificationRegisterInformation.sampleRequest')}
                </S.Label>
                <S.Value>13/12/2024</S.Value>
              </Flex>
            </Col>
            <Col span={6}>
              <Flex vertical gap={12}>
                <S.Label>
                  {t('pages.viewQualification.qualificationRegisterInformation.odm1')}
                </S.Label>
                <S.Value>{data?.odm1 ? data.odm1 : '-'}</S.Value>
              </Flex>
            </Col>
            <Col span={6}>
              <Flex vertical gap={12}>
                <S.Label>
                  {t('pages.viewQualification.qualificationRegisterInformation.odm2')}
                </S.Label>
                <S.Value>{data?.odm2 ? data.odm2 : '-'}</S.Value>
              </Flex>
            </Col>
            <Col span={6}>
              <Flex vertical gap={12}>
                <S.Label>
                  {t('pages.viewQualification.qualificationRegisterInformation.cm')}
                </S.Label>
                <S.Value>{data?.cm || '-'}</S.Value>
              </Flex>
            </Col>
          </S.InformationContent>
        </Col>
      </S.Content>
    </S.Container>
  );
}
