import { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { fileName, keyDownA11yEnter } from '../../../helpers/utils';
import { api } from '../../../services/api';

export function CsvExport({
  url = '',
  limit,
  filename,
  headers,
  transformData,
  withContent = true,
  content = [],
}) {
  const { t } = useTranslation();
  const [csv, setCsv] = useState(false);
  const [dataExport, setDataExport] = useState([]);
  const [loading, setLoading] = useState(false);
  const csvInstance = useRef();

  useEffect(() => {
    if (csv && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsv(false);
      });
    }
  }, [csv]);

  const exportWithContent = () => {
    setDataExport(content);
    setCsv(true);
  };

  const fetchExport = () => {
    const newLimit = limit > 0 ? limit : 25;
    setLoading(true);

    let fetchUrl = url;

    if (!fetchUrl.includes('limit=')) {
      // ver se não tem limit
      if (!fetchUrl.includes('?')) {
        // ver se não tem params
        fetchUrl = `${fetchUrl}?limit=${newLimit}`;
      } else {
        fetchUrl = fetchUrl.replace(/\?$/, ''); // remove params vazios
        fetchUrl = `${fetchUrl}&limit=${newLimit}`; // usar newLimit
      }
    } else {
      fetchUrl = fetchUrl.replace(/limit=\d+/g, `limit=${newLimit}`); // troca limit vindo da url pelo newLimit
      fetchUrl = fetchUrl.replace(/page=\d+/g, ''); // remove page
    }

    api
      .get(fetchUrl)
      .then((response) => {
        let data = withContent ? response.data.content : response.data;
        if (typeof transformData === 'function') {
          data = transformData(withContent ? response.data.content : response.data);
        }
        data = data?.length ? data : [];
        setDataExport(data);
      })
      .catch((error) => {
        console.log('Error on the fetch Components', error);
      })
      .finally(() => {
        setCsv(true);
        setLoading(false);
      });
  };

  return (
    <>
      <span
        tabIndex={0}
        role="button"
        onClick={content.length > 0 ? exportWithContent : fetchExport}
        onKeyDown={keyDownA11yEnter(content.length > 0 ? exportWithContent : fetchExport)}
      >
        {loading ? <>{t('common.loading')}...</> : <>{t('common.export')}</>}
      </span>
      {csv && (
        <CSVLink
          data={dataExport}
          headers={headers}
          filename={fileName(filename[0], filename[1])}
          ref={csvInstance}
          separator=";"
        />
      )}
    </>
  );
}
