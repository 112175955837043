import styled from 'styled-components';

export const Content = styled.div`
  .ant-transfer-customize-list .ant-transfer-list {
    background-color: ${({ theme }) => theme.colorsDesignSystem.white};
    border-radius: 0.5rem;
    max-width: 30rem;
  }

  .ant-transfer .ant-transfer-list-header {
    flex-direction: row-reverse;
  }

  .ant-transfer .ant-transfer-list-header-title {
    text-align: start;
    width: auto;
    font-weight: 600;
  }

  .ant-dropdown-trigger {
    margin-left: 0.25rem;
  }

  .ant-table-wrapper .ant-table-container {
    max-height: 15rem;
  }

  .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
    width: 3rem;
  }

  .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
    border-radius: 0;
  }

  .ant-table-wrapper .ant-table-container table > thead > tr:last-child > *:last-child {
    border-radius: 0;
  }

  .ant-table-wrapper .ant-table-container table > thead > tr > th {
    border-top: 1px solid ${({ theme }) => theme.colorsDesignSystem.backgroundSecondaryColor};
    border-bottom: 1px solid ${({ theme }) => theme.colorsDesignSystem.backgroundSecondaryColor};
    font-weight: 600;
  }

  .ant-table-wrapper {
    max-height: 26rem;
    min-height: max-content;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 1.125rem;
    }
    ::-webkit-scrollbar-track-piece {
      background-color: transparent;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0.625rem 0.625rem ${({ theme }) => theme.colorsDesignSystem.grayBorder};
      border: solid 0.375rem transparent;
      border-radius: 624rem;
    }
    ::-webkit-scrollbar-thumb {
      background: transparent;
      box-shadow: inset 0 0 0.625rem 0.625rem ${({ theme }) => theme.colorsDesignSystem.placeHolder};
      border: solid 0.375rem transparent;
      -webkit-border-radius: 624rem;
      border-radius: 624rem;
    }
  }
`;
