/* eslint-disable no-undefined */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAlert } from '../../../../context/AlertContext';
import { getURI } from '../../../../helpers/utils';
import { api } from '../../../../services/api';
import { services } from '../../../../services/services';
import theme from '../../../../styles/theme';
import { IParams } from '../types';
import { IDemandsWin } from './types';

export function useDemandsWin() {
  const { openAlert } = useAlert();
  const { t } = useTranslation();
  const { id: bidId } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [graphData, setGraphData] = useState<IDemandsWin>();

  const COLORS = [
    theme.colorsDesignSystem.chartColors.secondaryBlue,
    theme.colorsDesignSystem.chartColors.yellow,
    theme.colorsDesignSystem.chartColors.tertiaryBlue,
    theme.colorsDesignSystem.chartColors.purple,
    theme.colorsDesignSystem.chartColors.orange,
  ];

  const formatGraphData = (data: IDemandsWin) => {
    const formatted = data.demandsWin.map((item, index) => {
      return {
        supplier: item.supplier,
        demand: item.demand,
        percentage: item.percentage,
        fill: COLORS[index % COLORS.length],
      };
    });

    return {
      totaldemand: data.totaldemand,
      demandsWin: formatted,
    };
  };

  const fetchGraphData = async (params: IParams) => {
    try {
      setIsLoading(true);
      const { status, data } = await api.get(
        getURI(`${services.rfq}/bid/management/demandsWin`, { bidId, ...params })
      );
      if (status === 200) {
        setGraphData(formatGraphData(data[0]));
      } else setGraphData(undefined);
    } catch (error: any) {
      openAlert('error', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    graphData,
    fetchGraphData,
  };
}
