import { useHistory } from 'react-router-dom';
import { RoutesPath } from '../../routes/routesPath';
import { api } from '../../services/api';
import { handleVerifyUser, saveUserId, setAccessTokens } from '../../services/auth';
import { services } from '../../services/services';
import { TokenPayload } from '../../services/types';

export function useAwaitAuth() {
  const history = useHistory();

  const handleSaveLoggerLoginUser = async (data: TokenPayload) => {
    api.post(`userlogs/login`, null, {
      headers: {
        Authorization: `Bearer ${data.idToken}`,
        Permissions: data.permissions,
      },
    });
  };

  const handleNavigateHome = () => {
    handleVerifyUser() ? history.push(RoutesPath.home) : history.push(RoutesPath.scenarios);
  };

  const handleGetDecodedToken = async (refreshToken: string, userId: string) => {
    try {
      const { data, status } = await api.post(
        `${import.meta.env.VITE_API_LOGIN_UNICO}${services.singleLogin}/refresh-token`,
        {
          refresh_token: refreshToken,
          user_id: userId,
        }
      );

      if (status === 200 && data?.accessToken) {
        saveUserId(userId);
        setAccessTokens(data);
        handleSaveLoggerLoginUser(data);
        handleNavigateHome();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    handleGetDecodedToken,
    handleSaveLoggerLoginUser,
  };
}
