import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

export const MonthItem = styled.div<{ isCurrent?: boolean }>`
  width: 3.46rem;
  height: 2rem;
  border-radius: 0.5rem;
  border: ${({ isCurrent }) => (isCurrent ? '1px solid white' : null)};
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  span {
    font-size: 1.125rem;
    font-weight: 600;
    color: ${({ isCurrent, theme }) => (isCurrent ? 'white' : theme.colorsDesignSystem.primary)};
  }
`;
