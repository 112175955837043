import { DeleteOutlined, FileTextOutlined } from '@ant-design/icons';
import { Flex } from 'antd';
import dayjs from 'dayjs';
import ExclamationCircle from '../../../assets/exclamation-circle.svg';
import { getSupplierUserName } from '../../../helpers/nUtils';
import StyledButton from '../../Common/StyledButton';
import * as S from './styles';
import { IUploadedFileCard } from './types';

export function UploadedFileCard({
  fileName,
  status,
  onRemoveFile,
  fileId,
  supplier,
  sendDate,
  urlDownloaded,
  userUploaded,
  fileIdDelete,
}: IUploadedFileCard) {
  const handleRemoveFile = () => {
    if (onRemoveFile && fileId) {
      onRemoveFile(fileId);
    }
  };

  const handleDownload = () => {
    if (urlDownloaded && status === 'done') {
      window.open(urlDownloaded, '_blank');
    }
  };

  const handleRenderDeleteButton = () => {
    return (
      (userUploaded === getSupplierUserName()?.user.trim() ||
        status === 'uploading' ||
        status === 'error') &&
      onRemoveFile
    );
  };

  return (
    <S.Container status={status} onClick={handleDownload}>
      <S.ContentIcon>
        <FileTextOutlined />
      </S.ContentIcon>
      <Flex vertical justify="center" style={{ width: '100%' }}>
        <Flex align="center" justify="space-between">
          <Flex gap={8} style={{ maxWidth: '18rem' }}>
            {status === 'done' && <img src={ExclamationCircle} alt="Exclamation Circle" />}
            <S.FileName status={status}>{fileName}</S.FileName>
          </Flex>
          {handleRenderDeleteButton() && (
            <StyledButton
              onClick={(e) => {
                handleRemoveFile();
                e.stopPropagation();
              }}
              loading={fileIdDelete === fileId}
              variant="slim"
              icon={<DeleteOutlined />}
            />
          )}
        </Flex>
        {status === 'done' && (
          <Flex vertical>
            <S.FileDescription>{dayjs(sendDate).format('MM.DD.YYYY - hh:mma')}</S.FileDescription>
            <S.FileDescription>
              <i>
                <b>{userUploaded}</b> - {supplier}
              </i>
            </S.FileDescription>
          </Flex>
        )}
      </Flex>
    </S.Container>
  );
}
