import { t } from 'i18next';
import { useState } from 'react';
import { StepOneComponentRegister } from './StepOneComponentRegister';
import { StepTwoAssociatedOdms } from './StepTwoAssociatedOdms';
import { StepThreeSendEmail } from './StepThreeSendEmail';

export function useRequestRegistration() {
  const [registerComponentId, setRegisterComponentId] = useState('');
  const [associetedOdmId, setAssocietedOdmId] = useState('');
  const [currentStep, setCurrentStep] = useState<number>(0);

  const steps = [
    {
      title: t('pages.requesRegistration.steps.stepOne'),
      content: (
        <StepOneComponentRegister
          setRegisterComponentId={setRegisterComponentId}
          setStep={setCurrentStep}
          registerComponentId={registerComponentId}
        />
      ),
    },
    {
      title: t('pages.requesRegistration.steps.stepTwo'),
      content: (
        <StepTwoAssociatedOdms
          registerComponentId={registerComponentId}
          setStep={setCurrentStep}
          setAssocietedOdmId={setAssocietedOdmId}
          associetedOdmId={associetedOdmId}
        />
      ),
    },
    {
      title: t('pages.requesRegistration.steps.stepThree'),
      content: (
        <StepThreeSendEmail
          associetedOdmId={associetedOdmId}
          setStep={setCurrentStep}
          registerComponentId={registerComponentId}
        />
      ),
    },
  ];

  return {
    registerComponentId,
    associetedOdmId,
    steps,
    currentStep,
    setRegisterComponentId,
    setAssocietedOdmId,
  };
}
