import { PaperClipOutlined, SyncOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { TableAction } from '../../../../components/Tables/TableDropdownAction';

import { IDatasheet } from '../../types';
import * as S from './styles';

export function useDatasheet() {
  const { t } = useTranslation();

  const columns: ColumnsType<IDatasheet> = [
    {
      title: t('pages.viewQualification.datasheet.columnsTable.uploadFiles'),
      dataIndex: 'fileName',
      key: 'fileName',
      align: 'left',
      width: '30rem',
      render: (_, record) => {
        return (
          <S.FileName onClick={() => window.open(record.url, '_blank')}>
            <PaperClipOutlined />
            <span>{record.fileName}</span>
          </S.FileName>
        );
      },
    },
    {
      title: t('pages.viewQualification.datasheet.columnsTable.partNumber'),
      dataIndex: 'partNumber',
      key: 'partNumber',
      align: 'left',
      width: '10rem',
    },

    {
      title: t('pages.viewQualification.datasheet.columnsTable.supplier'),
      dataIndex: 'supplier',
      key: 'supplier',
      align: 'left',
      width: '11.5rem',
    },
    {
      title: t('pages.viewQualification.datasheet.columnsTable.dateHour'),
      dataIndex: 'dateHour',
      key: 'dateHour',
      align: 'left',
      width: '13rem',
      sorter: true,
    },
    {
      title: t('pages.viewQualification.datasheet.columnsTable.responsibleSupplier'),
      dataIndex: 'resposibleSupplier',
      key: 'resposibleSupplier',
      align: 'left',
      width: '12rem',
    },
    {
      title: t('pages.viewQualification.datasheet.columnsTable.actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: '5rem',
      render: (_, record) => {
        const actions = [
          {
            key: '1',
            label: (
              <Button type="link" disabled>
                <SyncOutlined />
                {t('pages.viewQualification.datasheet.columnsTable.requestUpdate')}
              </Button>
            ),
          },
        ];
        return <TableAction dropdownItems={actions} placement="bottom" />;
      },
    },
  ];

  return {
    columns,
  };
}
