/* eslint-disable no-undefined */
import { UserOutlined } from '@ant-design/icons';
import { Col, Divider, Flex, Row } from 'antd';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { TermsConditionsContentTitle } from './Components/TermsAndConditions/styles';
import * as S from './styles';
import { useBidSupplierView } from './useBidSupplierView';

import { Confetti } from '../../components/Common/Confetti';
import StyledButton from '../../components/Common/StyledButton';
import { StyledCardAccepted } from '../../components/Common/StyledCardAccepted';
import { StyledLoading } from '../../components/Common/StyledLoading';
import StyledModal from '../../components/Common/StyledModal';
import StyledSelect from '../../components/Common/StyledSelect';
import { UploadFilesModal } from '../../components/UploadModal/UploadFilesModal';
import { UploadedFilesModal } from '../../components/UploadModal/UploadedFilesModal';
import useDocumentTitle from '../../hooks/useDocumentTitle';

export function BidSupplierView() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.rfq.title'));
  const {
    steps,
    bidData,
    bidOptions,
    idBid,
    loading,
    termsConditon,
    termsConditionData,
    openModal,
    firstAccess,
    termsConditionDetail,
    acceptedTerms,
    acceptTerms,
    denyTerms,
    getSupplierUserName,
    fetchStatus,
    getBidId,
    fetchData,
    setIdBid,
    setOpenModal,
    Review,
    openUploadModal,
    openUploadedFilesModal,
    setOpenUploadModal,
    setOpenUploadedFilesModal,
    fileList,
    handleUploadFile,
    removeFile,
    clearFiles,
    sendFiles,
    uploadedFileList,
    uploadProgress,
    loadingUpdate,
    fetchUploadedFiles,
    fileIdDelete,
    isAwardModalOpen,
    setIsAwardModalOpen,
    awardData,
    fecthAwards,
    acceptAwardMessage,
    isLoadingMessage,
    setAceptedTerms,
    setTermsConditon,
    setAwardData,
    handleRenderContent,
  } = useBidSupplierView();
  const history = useHistory();

  useEffect(() => {
    getBidId();
  }, []);

  useEffect(() => {
    fetchData();
    fetchStatus();
  }, [idBid]);

  useEffect(() => {
    return () => {
      window.history.replaceState({}, '');
    };
  }, []);

  return (
    <>
      {isAwardModalOpen && <Confetti />}
      <StyledModal
        key="modal-awardMessage"
        title={t('pages.bidSupplierRFQ.result.modal.title')}
        open={isAwardModalOpen}
        body={
          <Flex style={{ paddingBottom: '1.5rem' }} vertical>
            <Divider orientation="left" orientationMargin={0}>
              <b>{t('pages.bidSupplierRFQ.result.modal.description')}</b>
            </Divider>
            {awardData?.message}
          </Flex>
        }
        width="30rem"
        centered
        footer={
          <Flex justify="flex-end">
            <StyledButton variant="primary" onClick={acceptAwardMessage} loading={isLoadingMessage}>
              {t('pages.bidSupplierRFQ.result.modal.gotIt')}
            </StyledButton>
          </Flex>
        }
      />
      <StyledModal
        key="modal-newcomponent"
        title="Terms Condition"
        open={openModal}
        onClose={() => setOpenModal(false)}
        footer={
          <StyledButton variant="secondary" onClick={() => setOpenModal(false)}>
            {t('common.close')}
          </StyledButton>
        }
        body={
          <>
            <Flex align="center" justify="space-between">
              <TermsConditionsContentTitle>{bidData.rfq}</TermsConditionsContentTitle>
              <Flex align="center" gap={16}>
                {termsConditionDetail?.acceptDate && (
                  <StyledCardAccepted
                    status="accepted"
                    time={dayjs(termsConditionDetail.acceptDate).format('MM.DD.YYYY - hh:mma')}
                    user={`${termsConditionDetail?.userAccept} - ${termsConditionDetail?.supplier}`}
                  />
                )}
                {termsConditionDetail?.rejectDate && (
                  <StyledCardAccepted
                    status="denied"
                    time={dayjs(termsConditionDetail.rejectDate).format('MM.DD.YYYY - hh:mma')}
                    user={`${termsConditionDetail.userReject} - ${termsConditionDetail.supplier}`}
                  />
                )}
              </Flex>
            </Flex>
            <S.ModalDivider />
            <div
              dangerouslySetInnerHTML={{
                __html: termsConditionData,
              }}
            />
          </>
        }
        width="64rem"
      />
      <StyledModal
        key="uploadQuotationFile"
        body={
          <UploadFilesModal
            title={t('pages.bidSupplierRFQ.quotation.modal.quotationFileSubmission')}
            loading={loadingUpdate}
            uploadProgress={uploadProgress}
            handleChange={handleUploadFile}
            removeFile={removeFile}
            fileList={fileList}
            fileIdDelete={fileIdDelete}
            uploadedFiles={uploadedFileList}
          />
        }
        footer={
          <Flex justify="space-between">
            <StyledButton
              variant="slim"
              disabled={loadingUpdate}
              onClick={() => {
                clearFiles();
                setOpenUploadModal(false);
              }}
            >
              {t('common.cancel')}
            </StyledButton>
            <StyledButton variant="primary" onClick={() => sendFiles()} disabled={loadingUpdate}>
              {t('common.send')}
            </StyledButton>
          </Flex>
        }
        onClose={() => {
          clearFiles();
          setOpenUploadModal(false);
        }}
        title={t('pages.bidSupplierRFQ.quotation.modal.title')}
        centered
        open={openUploadModal}
        width="34rem"
      />
      <StyledModal
        key="uploadedQuotation"
        body={
          <UploadedFilesModal
            title="Quotation"
            uploadedFiles={uploadedFileList}
            removeFile={removeFile}
            fileIdDelete={fileIdDelete}
          />
        }
        footer={null}
        onClose={() => {
          setOpenUploadedFilesModal(false);
        }}
        title={t('pages.bidSupplierRFQ.quotation.modal.title')}
        centered
        open={openUploadedFilesModal}
        width="34rem"
      />
      <Flex justify="space-between" align="center">
        <Col>
          <Row align="middle" style={{ gap: 4 }}>
            <S.SpanTile>{t('pages.bidSupplierRFQ.Title')}</S.SpanTile>
            <S.BidComponents>| {bidData.rfq}</S.BidComponents>
          </Row>
        </Col>
        <Col>
          <Row align="middle" style={{ gap: 4 }}>
            <S.SupplierSpan>{getSupplierUserName()?.supplier || '-'}</S.SupplierSpan>
            <S.UserContainer>
              <UserOutlined />
              <span>{getSupplierUserName()?.user || '-'}</span>
            </S.UserContainer>
          </Row>
        </Col>
      </Flex>
      <Flex justify="flex-end" align="center">
        <StyledSelect
          label="BID"
          options={bidOptions}
          value={idBid || ''}
          onChange={(value: string) => {
            setIdBid(value);
            setAceptedTerms(null);
            setTermsConditon(undefined);
            setAwardData(undefined);
          }}
          styles={{ width: '17.5rem' }}
        />
      </Flex>
      {loading ? (
        <StyledLoading height={10} />
      ) : (
        <S.StepsContainer noBorder>
          <S.StepsComponent items={steps} />
        </S.StepsContainer>
      )}
      {loading ? <StyledLoading height={10} /> : handleRenderContent()}
    </>
  );
}
