import * as S from './styles';
import { HeaderCardProps } from './types';

export default function HeaderCard({
  title,
  componentName,
  forMonth,
  year,
  FilterValue,
  description,
  filters,
}: HeaderCardProps) {
  return (
    <S.ContainerWithFilter>
      <S.Container>
        <S.CbomLabel>{`${title}`}</S.CbomLabel>
        <S.Divider>|</S.Divider>
        {year && (
          <>
            <S.FilterValueContainer>{FilterValue}</S.FilterValueContainer>
            <S.YearContainer>{year}</S.YearContainer>
            <S.Divider>|</S.Divider>
          </>
        )}
        {forMonth && (
          <>
            <S.ForMonth>{`${forMonth}`}</S.ForMonth>
            <S.Divider>|</S.Divider>
          </>
        )}
        <S.ComponentName>{componentName}</S.ComponentName>
        {description && (
          <>
            <S.Divider>|</S.Divider>
            <S.ForMonth>{`${description}`}</S.ForMonth>
          </>
        )}
      </S.Container>
      {filters}
    </S.ContainerWithFilter>
  );
}
